import React, { useEffect, useState } from 'react'

import { Button, Checkbox, FormControlLabel, Grid, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import _ from 'lodash'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { ERROR_ICON } from '../../../assets'
import ROUTE from '../../../route'
import { setActiveStep } from '../../../store/common.slice'
import {
  getAgbConsent,
  getBankData,
  getContactPersonData,
  getFurtherInformation,
  getInvoiceData,
  getInvoiceIsDeliveryAddress,
  getIsBecomePermanentCustomerSelected,
  getIsContactPersonOnSite,
  getSelectedApproachTime,
  getSelectedContainer,
  getSelectedPaymentMethod,
  getSelectedPickupDate,
  getSepaConsent,
  setAgbConsent,
  setBankDataBankBic,
  setBankDataBankIban,
  setBankDataBankName,
  setBankDataCity,
  setBankDataGivenname,
  setBankDataStreet,
  setBankDataStreetNumber,
  setBankDataSurname,
  setBankDataZip,
  setContactPersonDataEmail,
  setContactPersonDataGivenname,
  setContactPersonDataPhone,
  setContactPersonDataSurname,
  setFurtherInformation,
  setInvoiceCityData,
  setInvoiceEmailData,
  setInvoiceNameData,
  setInvoiceStreetData,
  setInvoiceStreetNumberData,
  setInvoiceZipData,
} from '../../../store/order.slice'
import { IBankData, IContactPersonData, IInvoiceData, PaymentMethod } from '../../../types'
import {
  ApproachTimeSelection,
  ContactPersonSection,
  ContainerSelection,
  FurtherInformationSection,
  InvoiceAddressSpecification,
  PaymentSection,
  PermanentCustomerSection,
  PickupTimeSelection,
} from '../components'

export interface IFormikValues {
  invoice: IInvoiceData
  contactPerson: IContactPersonData
  bank: IBankData
  furtherInformation: string
}

export const MetaInformation = () => {
  const [hasContainerPriceApiError, setHasContainerPriceApiError] = useState(false)
  const [hasApproachTimeError, setHasApproachTimeError] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedContainer = useSelector(getSelectedContainer)
  const selectedPickupDate = useSelector(getSelectedPickupDate)
  const { start: selectedStartTime, end: selectedEndtime } = useSelector(getSelectedApproachTime)
  const { name, street, streetNumber, zip, city, email } = useSelector(getInvoiceData)
  const { givenname, surname, email: contactPersonEmail, phone } = useSelector(getContactPersonData)
  const {
    givenname: bankGivenname,
    surname: bankSurname,
    street: bankStreet,
    streetNumber: bankStreetNumber,
    zip: bankZip,
    city: bankCity,
    bankName,
    bankBic,
    bankIban,
  } = useSelector(getBankData)
  const invoiceIsDeliveryAddress = useSelector(getInvoiceIsDeliveryAddress)
  const isContactPersonOnSite = useSelector(getIsContactPersonOnSite)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const sepaConsent = useSelector(getSepaConsent)
  const isBecomePermanentCustomerSelected = useSelector(getIsBecomePermanentCustomerSelected)
  const agbConsent = useSelector(getAgbConsent)
  const furtherInformation = useSelector(getFurtherInformation)

  useEffect(() => {
    dispatch(setActiveStep(3))
  }, [dispatch])

  const formik = useFormik<IFormikValues>({
    initialValues: {
      invoice: { name, street, streetNumber, zip, city, email },
      contactPerson: { givenname, surname, email: contactPersonEmail, phone },
      bank: {
        givenname: bankGivenname,
        surname: bankSurname,
        street: bankStreet,
        streetNumber: bankStreetNumber,
        zip: bankZip,
        city: bankCity,
        bankName,
        bankIban,
        bankBic,
      },
      furtherInformation,
    },
    validationSchema: yup.object({
      invoice: yup.object({
        name: yup
          .string()
          .required(t('order.meta_information.invoice_inputs.name_error'))
          .min(2, t('order.meta_information.invoice_inputs.name_error')),
        street: yup
          .string()
          .required(t('order.meta_information.invoice_inputs.street_error'))
          .min(5, t('order.meta_information.invoice_inputs.street_error')),
        streetNumber: yup
          .string()
          .matches(/\S+/, t('order.meta_information.invoice_inputs.street_number_error'))
          .required(t('order.meta_information.invoice_inputs.street_number_error')),
        zip: yup
          .string()
          .matches(/\b\d{5}\b/g, t('order.meta_information.invoice_inputs.zip_error'))
          .required(t('order.meta_information.invoice_inputs.zip_error')),
        city: yup.string().required(t('order.meta_information.invoice_inputs.city_error')),
        email: yup
          .string()
          .matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            t('order.meta_information.invoice_inputs.email_error'),
          )
          .optional(),
      }),
      contactPerson: yup.object({
        givenname: yup
          .string()
          .required(t('order.meta_information.contact_data_inputs.givenname_error'))
          .min(2, t('order.meta_information.contact_data_inputs.givenname_error')),
        surname: yup
          .string()
          .required(t('order.meta_information.contact_data_inputs.surname_error'))
          .min(2, t('order.meta_information.contact_data_inputs.surname_error')),
        email: yup
          .string()
          .matches(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            t('order.meta_information.contact_data_inputs.email_error'),
          )
          .optional(),
        phone: yup
          .string()
          .required(t('order.meta_information.contact_data_inputs.phone_error'))
          .min(10, t('order.meta_information.contact_data_inputs.phone_error')),
      }),
      bank: yup.object({
        givenname: yup
          .string()
          .required(t('order.meta_information.payment_information.inputs.givenname_error'))
          .min(2, t('order.meta_information.payment_information.inputs.givenname_error')),
        surname: yup
          .string()
          .required(t('order.meta_information.payment_information.inputs.surname_error'))
          .min(2, t('order.meta_information.payment_information.inputs.surname_error')),
        street: yup
          .string()
          .required(t('order.meta_information.payment_information.inputs.street_error'))
          .min(5, t('order.meta_information.payment_information.inputs.street_error')),
        streetNumber: yup
          .string()
          .matches(/\S+/, t('order.meta_information.payment_information.inputs.street_number_error'))
          .required(t('order.meta_information.payment_information.inputs.street_number_error')),
        zip: yup
          .string()
          .matches(/\b\d{5}\b/g, t('order.meta_information.payment_information.inputs.zip_error'))
          .required(t('order.meta_information.payment_information.inputs.zip_error')),
        city: yup.string().required(t('order.meta_information.payment_information.inputs.city_error')),
        bankName: yup
          .string()
          .required(t('order.meta_information.payment_information.inputs.bank_error'))
          .min(5, t('order.meta_information.payment_information.inputs.bank_error'))
          .max(256, t('order.meta_information.payment_information.inputs.bank_error')),
        bankIban: yup
          .string()
          .matches(/^DE\d{20}$/, t('order.meta_information.payment_information.inputs.iban_error'))
          .required(t('order.meta_information.payment_information.inputs.iban_error')),
        bankBic: yup
          .string()
          .required(t('order.meta_information.payment_information.inputs.bic_error'))
          .length(11, t('order.meta_information.payment_information.inputs.bic_error')),
      }),
      furtherInformation: yup.string(),
    }),
    onSubmit: () => undefined,
  })

  useEffect(() => {
    const main = async (): Promise<void> => {
      await formik.validateForm()
    }
    void main()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const persistUserInput = () => {
    if (!invoiceIsDeliveryAddress) {
      dispatch(setInvoiceNameData(formik.values.invoice.name))
      dispatch(setInvoiceStreetData(formik.values.invoice.street))
      dispatch(setInvoiceStreetNumberData(formik.values.invoice.streetNumber))
      dispatch(setInvoiceEmailData(formik.values.invoice.email))
      dispatch(setInvoiceZipData(formik.values.invoice.zip))
      dispatch(setInvoiceCityData(formik.values.invoice.city))
    }
    if (!isContactPersonOnSite) {
      dispatch(setContactPersonDataGivenname(formik.values.contactPerson.givenname))
      dispatch(setContactPersonDataSurname(formik.values.contactPerson.surname))
      dispatch(setContactPersonDataEmail(formik.values.contactPerson.email))
      dispatch(setContactPersonDataPhone(formik.values.contactPerson.phone))
    }
    dispatch(setBankDataGivenname(formik.values.bank.givenname))
    dispatch(setBankDataSurname(formik.values.bank.surname))
    dispatch(setBankDataStreet(formik.values.bank.street))
    dispatch(setBankDataStreetNumber(formik.values.bank.streetNumber))
    dispatch(setBankDataZip(formik.values.bank.zip))
    dispatch(setBankDataCity(formik.values.bank.city))
    dispatch(setBankDataBankName(formik.values.bank.bankName))
    dispatch(setBankDataBankBic(formik.values.bank.bankBic))
    dispatch(setBankDataBankIban(formik.values.bank.bankIban))
    dispatch(setFurtherInformation(formik.values.furtherInformation))
  }

  const handleOnPressNext = () => {
    persistUserInput()
    navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.SUMMARY}`)
    window.scrollTo(0, 0)
  }

  const handleOnPressBack = () => {
    persistUserInput()
    navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.CONTACT_DETAILS}`)
    window.scrollTo(0, 0)
  }

  const getIsNextButtonDisabled = () => {
    const missingSepaAgreement = selectedPaymentMethod === PaymentMethod.SEPA && !sepaConsent
    const hasInvoiceErrors = !invoiceIsDeliveryAddress ? !_.isEmpty(formik.errors.invoice) : false
    const hasContactPersonErrors = !isContactPersonOnSite ? !_.isEmpty(formik.errors.contactPerson) : false
    const hasBankErrors = selectedPaymentMethod === PaymentMethod.SEPA ? !_.isEmpty(formik.errors.bank) : false
    return (
      _.isUndefined(selectedContainer) ||
      _.isEmpty(selectedPickupDate) ||
      _.isEmpty(selectedStartTime) ||
      _.isEmpty(selectedEndtime) ||
      hasApproachTimeError ||
      hasInvoiceErrors ||
      hasContactPersonErrors ||
      _.isUndefined(selectedPaymentMethod) ||
      hasBankErrors ||
      missingSepaAgreement ||
      _.isUndefined(isBecomePermanentCustomerSelected) ||
      !agbConsent
    )
  }

  const handleOnPressHome = async () => {
    navigate(ROUTE.HOME)
  }

  const handleAgbConsent = async (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    dispatch(setAgbConsent(checked))
  }

  const renderErrorScreen = () => (
    <React.Fragment>
      <ErrorContentGrid>
        <IconGrid>
          <StyledIcon src={ERROR_ICON} />
        </IconGrid>
        <TextGrid>
          <StyledTitle data-testid="inquiry.sent_result.error.title" variant="hintTitle">
            {t('order.meta_information.pick_up_error_title')}
          </StyledTitle>
          <Typography>{t('order.meta_information.container_prices_api_error_description')}</Typography>
        </TextGrid>
      </ErrorContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressHome} data-testid="inquiry.back_home_button" variant="contained">
          {t('buttons.back_home')}
        </StyledButton>
      </ButtonGrid>
    </React.Fragment>
  )

  const renderMetaInformationScreen = () => (
    <React.Fragment>
      <ContentGrid data-testid="meta_information_screen">
        <ContainerSelection onApiError={() => setHasContainerPriceApiError(true)} />
        <PickupTimeSelection />
        <ApproachTimeSelection onError={(hasError) => setHasApproachTimeError(hasError)} />
        <InvoiceAddressSpecification formik={formik} />
        <ContactPersonSection formik={formik} />
        <PaymentSection formik={formik} />
        <PermanentCustomerSection />
        <FurtherInformationSection formik={formik} />
        <CheckboxGrid>
          <FormControlLabel
            data-testid="order.meta_information.agb_checkbox"
            onChange={handleAgbConsent}
            control={<Checkbox checked={agbConsent} />}
            label={
              <Typography>
                <Trans
                  i18nKey="order.meta_information.agb_consent_label"
                  components={{
                    a: <Link href="https://documentus-bayern.de/agbs" target="_blank" />,
                  }}
                />
              </Typography>
            }
          />
        </CheckboxGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="order.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        <StyledButton
          onClick={handleOnPressNext}
          data-testid="order.next_button"
          disabled={getIsNextButtonDisabled()}
          variant="contained">
          {t('buttons.next')}
        </StyledButton>
      </ButtonGrid>
    </React.Fragment>
  )

  return hasContainerPriceApiError ? renderErrorScreen() : renderMetaInformationScreen()
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingLeft: theme.layout.spacing._80,
  paddingTop: theme.layout.spacing._110,
  paddingRight: theme.layout.spacing._80,
  [theme.breakpoints.down('mobile')]: {
    paddingLeft: theme.layout.spacing._20,
    paddingTop: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._20,
  },
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._110,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._60,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))

const ErrorContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.layout.spacing._110,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const IconGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const StyledIcon = styled('img')(({ theme }) => ({
  width: theme.layout.size._350,
  [theme.breakpoints.down('mobile')]: {
    width: theme.layout.size._200,
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('tablet')]: {
    marginLeft: theme.layout.spacing._110,
    marginRight: theme.layout.spacing._110,
  },
  [theme.breakpoints.down('tablet')]: {
    marginLeft: theme.layout.spacing._60,
    marginRight: theme.layout.spacing._60,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const CheckboxGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size._50,
}))
