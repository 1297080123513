import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearIsBecomePermanentCustomerSelected,
  getIsBecomePermanentCustomerSelected,
  setIsBecomePermanentCustomerSelected,
} from '../../../store/order.slice'
import { BecomePermanentCustomer } from '../../../types'
import { Card } from './Card'

export const PermanentCustomerSection = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isBecomePermanentCustomerSelected = useSelector(getIsBecomePermanentCustomerSelected)

  const handlePermanentCustomerSelection = (becomePermanentCustomer: BecomePermanentCustomer, isSelected: boolean) => {
    dispatch(clearIsBecomePermanentCustomerSelected())
    if (isSelected && isBecomePermanentCustomerSelected !== becomePermanentCustomer) {
      dispatch(setIsBecomePermanentCustomerSelected(becomePermanentCustomer))
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">
        {t('order.meta_information.permanent_customer_information.title')}
      </Typography>
      <TextGrid>
        <Typography variant="tileText">
          {t('order.meta_information.permanent_customer_information.description')}
        </Typography>
      </TextGrid>
      <CardGrid>
        <Card
          testId="permanent_customer_information.cards.yes"
          title={t('order.meta_information.permanent_customer_information.cards.yes')}
          onSelect={async (selected) => handlePermanentCustomerSelection(BecomePermanentCustomer.YES, selected)}
          isSelected={isBecomePermanentCustomerSelected === BecomePermanentCustomer.YES}
        />
        <Card
          testId="permanent_customer_information.cards.no"
          title={t('order.meta_information.permanent_customer_information.cards.no')}
          onSelect={async (selected) => handlePermanentCustomerSelection(BecomePermanentCustomer.NO, selected)}
          isSelected={isBecomePermanentCustomerSelected === BecomePermanentCustomer.NO}
        />
      </CardGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._30,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [theme.breakpoints.up('desktop')]: {
    marginBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._10,
  },
}))
