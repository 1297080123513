// @typescript-eslint/no-floating-promises
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import deDE from './assets/i18n/de-DE/web_app.json'

const resources = {
  'de-DE': { web_app: deDE },
}

i18n
  .use(initReactI18next)
  .init({
    ns: ['web_app'],
    defaultNS: 'web_app',

    resources,
    fallbackLng: 'de-DE',

    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    missingKeyHandler: (lng, ns, key) => console.error(lng, ns, key),
    missingInterpolationHandler: (text, value) => console.error(text, value),
    returnEmptyString: false,
  })
  .catch((err) => console.log(err))

export default i18n
