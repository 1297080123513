import { UseQueryOptions, useQuery } from 'react-query'

import { getContainerPrices } from '../../api'
import { IContainerPricesRequest, IContainerPricesResponse } from '../../types'

export const useGetContainerPrices = ({
  params,
  options,
}: {
  params: IContainerPricesRequest
  options?: UseQueryOptions<IContainerPricesResponse[], Error>
}) => {
  const { data, error, isLoading, isError } = useQuery<IContainerPricesResponse[], Error>(
    ['container-prices', params],
    getContainerPrices,
    {
      staleTime: Infinity,
      refetchOnMount: false,
      ...options,
    },
  )

  return { data, error, isLoading, isError }
}
