import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import ROUTE from '../route'
import { HomeView, InquiryView } from '../routes'
import {
  AmountSelection,
  CompanyContactDetails,
  FurtherInformation,
  InquirySent,
  Summary as InquirySummary,
} from '../routes/inquiry/pages'
import { OrderView } from '../routes/order'
import { MetaInformation, OrderInformation, OrderSent, Summary as OrderSummary } from '../routes/order/pages'
import { getRequestType } from '../store/common.slice'
import { RequestType } from '../types'

export const AppRoot = () => {
  const requestType = useSelector(getRequestType)

  return (
    <Router>
      <Routes>
        <Route path={ROUTE.NOT_FOUND} element={<HomeView />} />
        <Route path={ROUTE.HOME} element={<HomeView />} />
        <Route path={ROUTE.ORDER.MAIN} element={<OrderView />}>
          <Route index element={<OrderInformation />} />
          <Route path={ROUTE.ORDER.CONTACT_DETAILS} element={<CompanyContactDetails />} />
          <Route path={ROUTE.ORDER.FURTHER_INFORMATION} element={<FurtherInformation />} />
          <Route path={ROUTE.ORDER.META_INFORMATION} element={<MetaInformation />} />
          <Route
            path={ROUTE.ORDER.SUMMARY}
            element={requestType === RequestType.ORDER ? <OrderSummary /> : <InquirySummary />}
          />
          <Route path={ROUTE.ORDER.SENT} element={<OrderSent />} />
        </Route>
        <Route path={ROUTE.INQUIRY.MAIN} element={<InquiryView />}>
          <Route index element={<AmountSelection />} />
          <Route path={ROUTE.INQUIRY.CONTACT_DETAILS} element={<CompanyContactDetails />} />
          <Route path={ROUTE.INQUIRY.FURTHER_INFORMATION} element={<FurtherInformation />} />
          <Route path={ROUTE.INQUIRY.SUMMARY} element={<InquirySummary />} />
          <Route path={ROUTE.INQUIRY.SENT} element={<InquirySent />} />
        </Route>
      </Routes>
    </Router>
  )
}
