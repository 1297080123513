import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { clearDestructionOrderType, getDestructionOrderType, setDestructionOrderType } from '../../../store/order.slice'
import { DestructionOrderType } from '../../../types'
import { Card } from './Card'

export const OrderTypeOfDestruction = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionOrderType = useSelector(getDestructionOrderType)

  const handleOrderTypeSelection = (selectedOrderType: DestructionOrderType, isSelected: boolean) => {
    dispatch(clearDestructionOrderType())
    if (isSelected && destructionOrderType !== selectedOrderType) {
      dispatch(setDestructionOrderType(selectedOrderType))
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">
        {t('order.order_informations.order_type_of_destruction.title')}
      </Typography>
      <CardGrid>
        <Card
          testId="order_type_of_destruction.permanent_card"
          title={
            <Trans
              i18nKey="order.order_informations.order_type_of_destruction.permanent_title"
              components={{
                span: <StyledSpan />,
              }}
            />
          }
          subTitle={t('order.order_informations.order_type_of_destruction.permanent_subtitle')}
          description={t('order.order_informations.order_type_of_destruction.permanent_description')}
          onSelect={(selected) => handleOrderTypeSelection(DestructionOrderType.PERMANENT, selected)}
          isSelected={destructionOrderType === DestructionOrderType.PERMANENT}
        />
        <Card
          testId="order_type_of_destruction.short_term_card"
          title={
            <Trans
              i18nKey="order.order_informations.order_type_of_destruction.short_term_title"
              components={{
                span: <StyledSpan />,
              }}
            />
          }
          subTitle={t('order.order_informations.order_type_of_destruction.short_term_subtitle')}
          description={t('order.order_informations.order_type_of_destruction.short_term_description')}
          onSelect={(selected) => handleOrderTypeSelection(DestructionOrderType.SHORT_TERM, selected)}
          isSelected={destructionOrderType === DestructionOrderType.SHORT_TERM}
        />
        <Card
          testId="order_type_of_destruction.at_once_card"
          title={
            <Trans
              i18nKey="order.order_informations.order_type_of_destruction.at_once_title"
              components={{
                span: <StyledSpan />,
              }}
            />
          }
          subTitle={t('order.order_informations.order_type_of_destruction.at_once_subtitle')}
          description={t('order.order_informations.order_type_of_destruction.at_once_description')}
          onSelect={(selected) => handleOrderTypeSelection(DestructionOrderType.AT_ONCE, selected)}
          isSelected={destructionOrderType === DestructionOrderType.AT_ONCE}
        />
      </CardGrid>
    </SectionGrid>
  )
}

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.color.tertiary.green,
}))

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}))
