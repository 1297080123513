import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getContactData } from '../../../store/inquiry.slice'
import { getContactPersonData } from '../../../store/order.slice'

export const ContactDataSummary = () => {
  const { t } = useTranslation()
  const { givenname, surname } = useSelector(getContactData)
  const {
    givenname: contactPersonGivenname,
    surname: contactPersonSurname,
    email,
    phone,
  } = useSelector(getContactPersonData)

  return (
    <SectionGrid>
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.contact_data">
          <TopGrid>
            <StyledContactIcon />
            <StyledTextGrid>
              <Typography variant="tileText">
                {t('order.summary.specified_data.contact_data_card.title_order_creator')}
              </Typography>
              <StyledTitle data-testid="order.summary.contact_data.title" variant="tileTitle">
                {`${givenname} ${surname}`}
              </StyledTitle>
            </StyledTextGrid>
          </TopGrid>
          <BottomGrid>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.contact_data_card.title_contact_person_on_site')}
              </Typography>
              <Typography variant="pageTitle">{`${contactPersonGivenname} ${contactPersonSurname}`}</Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.contact_data_card.email_label')}
              </Typography>
              <Typography variant="pageTitle">{email}</Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.contact_data_card.phone_label')}
              </Typography>
              <Typography variant="pageTitle">{phone}</Typography>
            </Section>
          </BottomGrid>
        </StyledCardContent>
      </StyledCard>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._40,
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))

const StyledContactIcon = styled(SupervisedUserCircleIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._50,
  },
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const BottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.layout.spacing._20,
    marginLeft: theme.layout.spacing._80,
  },
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._20,
}))
