import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { ContactDataSummary } from './ContactDataSummary'
import { DeliveryInvoiceSummary } from './DeliveryInvoiceSummary'
import { FurtherInformationSummary } from './FurtherInformationSummary'
import { PaymentDataSummary } from './PaymentDataSummary'

export const SpecifiedDataSummarySection = () => {
  const { t } = useTranslation()

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.summary.specified_data.title')}</Typography>
      <ContentGrid>
        <DeliveryInvoiceSummary />
        <ContactDataSummary />
        <PaymentDataSummary />
        <FurtherInformationSummary />
      </ContentGrid>
    </SectionGrid>
  )
}
const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const ContentGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}))
