import React, { useEffect } from 'react'

import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ROUTE from '../../../route'
import { getRequestType, getType, setActiveStep } from '../../../store/common.slice'
import {
  getCompanyDeliveryAddress,
  getContactData,
  setCompanyCity,
  setCompanyName,
  setCompanyStreet,
  setCompanyStreetNumber,
  setCompanyZip,
  setContactEmail,
  setContactGivenname,
  setContactPhone,
  setContactSurname,
  setIsPrivatePerson,
} from '../../../store/inquiry.slice'
import { JobType, RequestType } from '../../../types'

export const CompanyContactDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { isPrivatePerson, name, street, number, zip, city } = useSelector(getCompanyDeliveryAddress)
  const { givenname, surname, email, phone } = useSelector(getContactData)
  const requestType = useSelector(getRequestType)
  const type = useSelector(getType)

  useEffect(() => {
    dispatch(setActiveStep(2))
  }, [dispatch])

  const initialValues = {
    companyName: name,
    companyStreet: street,
    companyStreetNumber: number,
    companyZip: zip,
    companyCity: city,
    givenname,
    surname,
    email,
    phone,
  }

  const formik = useFormik({
    initialValues: process.env.REACT_APP_PREFILL_CONTACT
      ? JSON.parse(process.env.REACT_APP_PREFILL_CONTACT)
      : initialValues,
    validationSchema: yup.object({
      companyName: isPrivatePerson
        ? yup.string().min(5, t('inquiry.contact_details.company_delivery.input.error_name'))
        : yup
            .string()
            .required(t('inquiry.contact_details.company_delivery.input.error_name'))
            .matches(/(?=\S)/, t('inquiry.contact_details.company_delivery.input.error_street'))
            .min(5, t('inquiry.contact_details.company_delivery.input.error_name')),
      companyStreet: yup
        .string()
        .required(t('inquiry.contact_details.company_delivery.input.error_street'))
        .matches(/(?=\S)/, t('inquiry.contact_details.company_delivery.input.error_street'))
        .min(5, t('inquiry.contact_details.company_delivery.input.error_street')),
      companyStreetNumber: yup
        .string()
        .required(t('inquiry.contact_details.company_delivery.input.error_number'))
        .matches(/\S+/, t('inquiry.contact_details.company_delivery.input.error_number')),
      companyZip: yup
        .string()
        .matches(
          /^(63|72|80|81|83|84|85|86|87|88|89|90|91|92|93|94|95|96|97)/g,
          t('inquiry.contact_details.company_delivery.input.error_zip'),
        )
        .matches(/\b\d{5}\b/g, t('inquiry.contact_details.company_delivery.input.error_zip'))
        .required(t('inquiry.contact_details.company_delivery.input.error_zip')),
      companyCity: yup
        .string()
        .required(t('inquiry.contact_details.company_delivery.input.error_city'))
        .matches(/(?=\S)/, t('inquiry.contact_details.company_delivery.input.error_city'))
        .min(2, t('inquiry.contact_details.company_delivery.input.error_city')),
      givenname: yup
        .string()
        .required(t('inquiry.contact_details.contact_data.input.error_givenname'))
        .matches(/(?=\S)/, t('inquiry.contact_details.contact_data.input.error_givenname'))
        .min(2, t('inquiry.contact_details.contact_data.input.error_givenname')),
      surname: yup
        .string()
        .required(t('inquiry.contact_details.contact_data.input.error_surname'))
        .matches(/(?=\S)/, t('inquiry.contact_details.contact_data.input.error_surname'))
        .min(2, t('inquiry.contact_details.contact_data.input.error_surname')),
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          t('inquiry.contact_details.contact_data.input.error_email'),
        )
        .required(t('inquiry.contact_details.contact_data.input.error_email')),
      phone: yup
        .string()
        .required(t('inquiry.contact_details.contact_data.input.error_phone'))
        .matches(/(?=\S)/, t('inquiry.contact_details.contact_data.input.error_phone'))
        .matches(/^[-+()/ , 0-9]*$/, t('inquiry.contact_details.contact_data.input.error_phone'))
        .min(10, t('inquiry.contact_details.contact_data.input.error_phone')),
    }),
    validateOnMount: true,
    onSubmit: () => undefined,
  })

  const handleIsPrivatePerson = async (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    checked ? delete formik.errors.companyName : await formik.validateField('companyName')
    dispatch(setIsPrivatePerson(checked))
  }

  const persistUserInput = () => {
    dispatch(setCompanyName(formik.values.companyName))
    dispatch(setCompanyStreet(formik.values.companyStreet.trim()))
    dispatch(setCompanyStreetNumber(formik.values.companyStreetNumber))
    dispatch(setCompanyZip(formik.values.companyZip))
    dispatch(setCompanyCity(formik.values.companyCity))
    dispatch(setContactGivenname(formik.values.givenname))
    dispatch(setContactSurname(formik.values.surname))
    dispatch(setContactEmail(formik.values.email))
    dispatch(setContactPhone(formik.values.phone))
  }

  const handleOnPressBack = () => {
    persistUserInput()
    if (type === JobType.DESTRUCT) {
      navigate(ROUTE.ORDER.MAIN)
    } else {
      navigate(ROUTE.INQUIRY.MAIN)
    }
    window.scrollTo(0, 0)
  }

  const handleOnPressNext = () => {
    persistUserInput()
    if (requestType === RequestType.INQUIRY && type === JobType.DESTRUCT) {
      navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.FURTHER_INFORMATION}`)
    } else if (requestType === RequestType.ORDER && type === JobType.DESTRUCT) {
      navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.META_INFORMATION}`)
    } else {
      navigate(`${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.FURTHER_INFORMATION}`)
    }
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <ContentGrid data-testid="company_contact_details_screen">
          <CompanyDeliveryGrid>
            <CompanyTitleGrid>
              <Typography variant="underlinedTileTitle">
                {t('inquiry.contact_details.company_delivery.title')}
              </Typography>
            </CompanyTitleGrid>
            <InputGrid>
              <CheckboxGrid>
                <FormControlLabel
                  onChange={handleIsPrivatePerson}
                  control={<Checkbox checked={isPrivatePerson} />}
                  label={<Typography>{t('inquiry.contact_details.company_delivery.checkbox_title')}</Typography>}
                />
              </CheckboxGrid>
              {!isPrivatePerson && (
                <StyledTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.company_input.name' }}
                  placeholder={t('inquiry.contact_details.company_delivery.input.name').toUpperCase()}
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                  helperText={(formik.touched.companyName && formik.errors.companyName) ?? ' '}
                  type="text"
                  margin="dense"
                />
              )}
              <HorizontalInputGrid>
                <StreetTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.company_input.street' }}
                  placeholder={t('inquiry.contact_details.company_delivery.input.street').toUpperCase()}
                  id="companyStreet"
                  name="companyStreet"
                  value={formik.values.companyStreet}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.companyStreet && Boolean(formik.errors.companyStreet)}
                  helperText={(formik.touched.companyStreet && formik.errors.companyStreet) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <StreetNumberTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.company_input.number' }}
                  placeholder={t('inquiry.contact_details.company_delivery.input.number').toUpperCase()}
                  id="companyStreetNumber"
                  name="companyStreetNumber"
                  value={formik.values.companyStreetNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.companyStreetNumber && Boolean(formik.errors.companyStreetNumber)}
                  helperText={(formik.touched.companyStreetNumber && formik.errors.companyStreetNumber) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
              <HorizontalInputGrid>
                <ZipTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.company_input.zip' }}
                  placeholder={t('inquiry.contact_details.company_delivery.input.zip').toUpperCase()}
                  id="companyZip"
                  name="companyZip"
                  value={formik.values.companyZip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.companyZip && Boolean(formik.errors.companyZip)}
                  helperText={(formik.touched.companyZip && formik.errors.companyZip) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <CityTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.company_input.city' }}
                  placeholder={t('inquiry.contact_details.company_delivery.input.city').toUpperCase()}
                  id="companyCity"
                  name="companyCity"
                  value={formik.values.companyCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.companyCity && Boolean(formik.errors.companyCity)}
                  helperText={(formik.touched.companyCity && formik.errors.companyCity) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
            </InputGrid>
          </CompanyDeliveryGrid>
          <ContactDataGrid>
            <ContactTitleGrid>
              <Typography variant="underlinedTileTitle">{t('inquiry.contact_details.contact_data.title')}</Typography>
            </ContactTitleGrid>
            <InputGrid>
              <HorizontalInputGrid>
                <GivennameTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.contact_input.givenname' }}
                  placeholder={t('inquiry.contact_details.contact_data.input.givenname').toUpperCase()}
                  id="givenname"
                  name="givenname"
                  value={formik.values.givenname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.givenname && Boolean(formik.errors.givenname)}
                  helperText={(formik.touched.givenname && formik.errors.givenname) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <SurnameTextField
                  inputProps={{ 'data-testid': 'inquiry.company_contact_details.contact_input.surname' }}
                  placeholder={t('inquiry.contact_details.contact_data.input.surname').toUpperCase()}
                  id="surname"
                  name="surname"
                  value={formik.values.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.surname && Boolean(formik.errors.surname)}
                  helperText={(formik.touched.surname && formik.errors.surname) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
              <StyledTextField
                inputProps={{ 'data-testid': 'inquiry.company_contact_details.contact_input.email' }}
                placeholder={t('inquiry.contact_details.contact_data.input.email').toUpperCase()}
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={(formik.touched.email && formik.errors.email) ?? ' '}
                type="email"
                margin="dense"
              />
              <StyledTextField
                inputProps={{ 'data-testid': 'inquiry.company_contact_details.contact_input.phone' }}
                placeholder={t('inquiry.contact_details.contact_data.input.phone').toUpperCase()}
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={(formik.touched.phone && formik.errors.phone) ?? ' '}
                type="tel"
                margin="dense"
              />
            </InputGrid>
          </ContactDataGrid>
        </ContentGrid>
        <ButtonGrid>
          <StyledButton onClick={handleOnPressBack} data-testid="inquiry.back_button" variant="outlined">
            {t('buttons.back')}
          </StyledButton>
          <StyledButton
            onClick={handleOnPressNext}
            data-testid="inquiry.next_button"
            disabled={!_.isEmpty(formik.errors)}
            variant="contained">
            {t('buttons.next')}
          </StyledButton>
        </ButtonGrid>
      </form>
    </React.Fragment>
  )
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('laptop')]: {
    flexDirection: 'row',
    paddingRight: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._110,
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
    paddingRight: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._110,
  },
  [theme.breakpoints.up('tablet')]: {
    paddingRight: theme.layout.spacing._60,
    paddingLeft: theme.layout.spacing._60,
  },
  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
    paddingRight: theme.layout.spacing._20,
    paddingLeft: theme.layout.spacing._20,
  },
}))

const CompanyDeliveryGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: theme.layout.spacing._180,
  marginRight: theme.layout.spacing._20,
  marginLeft: theme.layout.spacing._20,
  [theme.breakpoints.down('laptop')]: {
    marginTop: theme.layout.spacing._60,
  },
}))

const CheckboxGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size._50,
}))

const ContactDataGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginTop: theme.layout.spacing._180,
  marginRight: theme.layout.spacing._20,
  marginLeft: theme.layout.spacing._20,
  [theme.breakpoints.down('laptop')]: {
    marginTop: theme.layout.spacing._60,
  },
}))

const CompanyTitleGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const ContactTitleGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.size._70,
}))

const HorizontalInputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const GivennameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginRight: theme.layout.spacing._10,
}))

const SurnameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginLeft: theme.layout.spacing._10,
}))

const StreetTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.6,
  marginRight: theme.layout.spacing._10,
}))

const StreetNumberTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.4,
  marginLeft: theme.layout.spacing._10,
}))

const ZipTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.3,
  marginRight: theme.layout.spacing._10,
}))

const CityTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.7,
  marginLeft: theme.layout.spacing._10,
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._290,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
