import React from 'react'

import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'

interface IProps {
  activeStep: number
}

const steps = ['1', '2', '3', '4']

export const CustomStepper = ({ activeStep }: IProps) => {
  return (
    <StyledBox>
      {steps.map((step, index) => {
        return (
          <React.Fragment key={step}>
            <StyledStep active={activeStep === index + 1} done={activeStep > index + 1}>
              <StyledText variant="tileTitle" done={activeStep > index + 1}>
                {step}
              </StyledText>
            </StyledStep>
            {index < steps.length - 1 && <Connector active={index < activeStep - 1} />}
          </React.Fragment>
        )
      })}
    </StyledBox>
  )
}

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})

const StyledText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'done' })<{ done: boolean }>(
  ({ theme, done }) => ({
    color: done ? theme.color.primary.blue : theme.color.white,
  }),
)

const StyledStep = styled(Grid, { shouldForwardProp: (prop) => prop !== 'active' && prop !== 'done' })<{
  active: boolean
  done: boolean
}>(({ theme, active, done }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: done || active ? `2px solid ${theme.color.primary.blue}` : `2px solid ${theme.color.secondary.lightGray}`,
  backgroundColor: active ? theme.color.primary.blue : done ? theme.color.white : theme.color.secondary.lightGray,
  [theme.breakpoints.up('tablet')]: {
    height: theme.layout.size._48,
    width: theme.layout.size._48,
  },
  [theme.breakpoints.between('mobile', 'tablet')]: {
    marginRight: theme.layout.spacing._10,
    marginLeft: theme.layout.spacing._10,
    height: theme.layout.size._48,
    maxWidth: theme.layout.size._48,
    flex: 1,
    alignItems: 'center',
  },
  [theme.breakpoints.down('mobile')]: {
    marginRight: theme.layout.spacing._10,
    marginLeft: theme.layout.spacing._10,
    height: theme.layout.size._48,
    maxWidth: theme.layout.size._48,
    flex: 1,
    alignItems: 'center',
  },
}))

const Connector = styled(Grid, { shouldForwardProp: (prop) => prop !== 'active' })<{ active: boolean }>(
  ({ theme, active }) => ({
    height: theme.layout.size._2,
    width: theme.layout.size._30,
    backgroundColor: active ? theme.color.primary.blue : theme.color.secondary.lightGray,
    [theme.breakpoints.up('tablet')]: {
      margin: theme.layout.spacing._12,
    },
    [theme.breakpoints.down('mobile')]: {
      width: theme.layout.size._20,
    },
  }),
)
