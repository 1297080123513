import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { ICompanyDeliveryAddress, IContactData, InquiryAmountUnit } from '../types'

interface IInquiryState {
  amount: string
  unit: InquiryAmountUnit | string
  companyDeliveryAddress: ICompanyDeliveryAddress
  contactData: IContactData
  furtherInformation: string
}

const initialState: IInquiryState = {
  amount: '',
  unit: '',
  companyDeliveryAddress: {
    name: '',
    street: '',
    number: '',
    zip: '',
    city: '',
    isPrivatePerson: false,
  },
  contactData: {
    givenname: '',
    surname: '',
    email: '',
    phone: '',
  },
  furtherInformation: '',
}

const slice = createSlice({
  name: 'inquiry',
  initialState,
  reducers: {
    setAmount: (state, { payload: amount }: PayloadAction<string>) => {
      state.amount = amount
    },
    setUnit: (state, { payload: unit }: PayloadAction<InquiryAmountUnit>) => {
      state.unit = unit
    },
    setIsPrivatePerson: (state, { payload: isPrivatePerson }: PayloadAction<boolean>) => {
      state.companyDeliveryAddress.isPrivatePerson = isPrivatePerson
    },
    setCompanyName: (state, { payload: name }: PayloadAction<ICompanyDeliveryAddress['name']>) => {
      state.companyDeliveryAddress.name = name
    },
    setCompanyStreet: (state, { payload: street }: PayloadAction<ICompanyDeliveryAddress['street']>) => {
      state.companyDeliveryAddress.street = street
    },
    setCompanyStreetNumber: (state, { payload: number }: PayloadAction<ICompanyDeliveryAddress['number']>) => {
      state.companyDeliveryAddress.number = number
    },
    setCompanyZip: (state, { payload: zip }: PayloadAction<ICompanyDeliveryAddress['zip']>) => {
      state.companyDeliveryAddress.zip = zip
    },
    setCompanyCity: (state, { payload: city }: PayloadAction<ICompanyDeliveryAddress['city']>) => {
      state.companyDeliveryAddress.city = city
    },
    setContactGivenname: (state, { payload: givenname }: PayloadAction<IContactData['givenname']>) => {
      state.contactData.givenname = givenname
    },
    setContactSurname: (state, { payload: surname }: PayloadAction<IContactData['surname']>) => {
      state.contactData.surname = surname
    },
    setContactEmail: (state, { payload: email }: PayloadAction<IContactData['email']>) => {
      state.contactData.email = email
    },
    setContactPhone: (state, { payload: phone }: PayloadAction<IContactData['phone']>) => {
      state.contactData.phone = phone
    },
    setFurtherInformation: (state, { payload: furtherInformation }: PayloadAction<string>) => {
      state.furtherInformation = furtherInformation
    },
    reset: () => initialState,
  },
})

export const {
  setIsPrivatePerson,
  setAmount,
  setUnit,
  setCompanyName,
  setCompanyStreet,
  setCompanyStreetNumber,
  setCompanyZip,
  setCompanyCity,
  setContactGivenname,
  setContactSurname,
  setContactEmail,
  setContactPhone,
  setFurtherInformation,
  reset,
} = slice.actions

export const getAmount = (state: { [slice.name]: IInquiryState }) => state[slice.name].amount
export const getUnit = (state: { [slice.name]: IInquiryState }) => state[slice.name].unit
export const getCompanyDeliveryAddress = (state: { [slice.name]: IInquiryState }) =>
  state[slice.name].companyDeliveryAddress
export const getContactData = (state: { [slice.name]: IInquiryState }) => state[slice.name].contactData
export const getFurtherInformation = (state: { [slice.name]: IInquiryState }) => state[slice.name].furtherInformation

export default slice.reducer
