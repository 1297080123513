import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'

import ROUTE from '../route'
import { Footer } from './Footer'
import { Header } from './Header'

interface IProps {
  children: any
}

export const AppContainer = ({ children }: IProps) => {
  const location = useLocation()

  return (
    <AppBox container>
      <Grid container>
        <Header showStepper={location.pathname !== ROUTE.HOME} />
        <BodyGrid container item>
          <Grid container item>
            {children}
          </Grid>
        </BodyGrid>
        <Footer />
      </Grid>
    </AppBox>
  )
}

const AppBox = styled(Grid)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.color.primary.blue,
}))

const BodyGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.color.white,
  minHeight: '82vh',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
    marginRight: theme.layout.spacing._80,
    marginTop: theme.layout.size.header + theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    marginLeft: theme.layout.spacing._10,
    marginRight: theme.layout.spacing._10,
    marginTop: theme.layout.size.header_mobile + theme.layout.spacing._30,
  },
}))
