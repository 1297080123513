import { useMutation } from 'react-query'

import { postOrderRequest } from '../../api'
import { IOrderRequestApi } from '../../types'

export const usePostOrderRequest = () => {
  const { mutateAsync, isLoading } = useMutation<Response, Error, IOrderRequestApi>(postOrderRequest)

  return { mutateAsync, isLoading }
}
