import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { clearDestructionPlace, getDestructionPlace, setDestructionPlace } from '../../../store/order.slice'
import { DestructionPlace } from '../../../types'
import { Card } from './Card'

export const PlaceOfDestruction = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionPlace = useSelector(getDestructionPlace)

  const handlePlaceSelection = (selectedPlace: DestructionPlace, isSelected: boolean) => {
    dispatch(clearDestructionPlace())
    if (isSelected && destructionPlace !== selectedPlace) {
      dispatch(setDestructionPlace(selectedPlace))
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.order_informations.place_of_destruction.title')}</Typography>
      <CardGrid>
        <Card
          testId="place_of_destruction.eg_bf_card"
          title={t('order.order_informations.place_of_destruction.eg_bf_title')}
          onSelect={(selected) => handlePlaceSelection(DestructionPlace.EG_BF, selected)}
          isSelected={destructionPlace === DestructionPlace.EG_BF}
        />
        <Card
          testId="place_of_destruction.ug_lift_card"
          title={t('order.order_informations.place_of_destruction.ug_lift_title')}
          onSelect={(selected) => handlePlaceSelection(DestructionPlace.UG_LIFT, selected)}
          isSelected={destructionPlace === DestructionPlace.UG_LIFT}
        />
        <Card
          testId="place_of_destruction.og_lift_card"
          title={t('order.order_informations.place_of_destruction.og_lift_title')}
          onSelect={(selected) => handlePlaceSelection(DestructionPlace.OG_LIFT, selected)}
          isSelected={destructionPlace === DestructionPlace.OG_LIFT}
        />
        <Card
          testId="place_of_destruction.ug_no_lift_card"
          title={t('order.order_informations.place_of_destruction.ug_no_lift_title')}
          onSelect={(selected) => handlePlaceSelection(DestructionPlace.UG_NO_LIFT, selected)}
          isSelected={destructionPlace === DestructionPlace.UG_NO_LIFT}
        />
        <Card
          testId="place_of_destruction.og_no_lift_card"
          title={t('order.order_informations.place_of_destruction.og_no_lift_title')}
          onSelect={(selected) => handlePlaceSelection(DestructionPlace.OG_NO_LIFT, selected)}
          isSelected={destructionPlace === DestructionPlace.OG_NO_LIFT}
        />
      </CardGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}))
