import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ICommonState {
  hasApiError?: boolean
  statusCode?: number
  statusText?: string
}

const initialState: ICommonState = {}

const slice = createSlice({
  name: 'api',
  initialState,
  reducers: {
    setHasApiError: (state, { payload: hasApiError }: PayloadAction<boolean>) => {
      state.hasApiError = hasApiError
    },
    setResponseStatus: (state, { payload: statusCode }: PayloadAction<number>) => {
      state.statusCode = statusCode
    },
    setStatusText: (state, { payload: statusText }: PayloadAction<string>) => {
      state.statusText = statusText
    },
    reset: () => initialState,
  },
})

export const { setHasApiError, setResponseStatus, setStatusText, reset } = slice.actions

export const getHasApiError = (state: { [slice.name]: ICommonState }) => state[slice.name].hasApiError
export const getApiStatusCode = (state: { [slice.name]: ICommonState }) => state[slice.name].statusCode
export const getStatusText = (state: { [slice.name]: ICommonState }) => state[slice.name].statusText

export default slice.reducer
