import React, { useEffect } from 'react'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { usePostOrderRequest } from '../../../hooks/api/usePostOrderRequest'
import ROUTE from '../../../route'
import { setHasApiError, setResponseStatus, setStatusText } from '../../../store/api.slice'
import { getType, setActiveStep } from '../../../store/common.slice'
import { getCompanyDeliveryAddress, getContactData } from '../../../store/inquiry.slice'
import {
  getAgbConsent,
  getBankData,
  getContactPersonData,
  getDestructionOrderType,
  getDestructionProtection,
  getDestructionSubject,
  getFurtherInformation,
  getInvoiceData,
  getIsBecomePermanentCustomerSelected,
  getIsContactPersonOnSite,
  getSelectedApproachTime,
  getSelectedContainer,
  getSelectedPaymentMethod,
  getSelectedPickupDate,
  getSepaConsent,
} from '../../../store/order.slice'
import { IOrderRequestApi, PaymentMethod } from '../../../types'
import { JobTypeCard, PriceSummarySection, SpecifiedDataSummarySection } from '../components'

export const Summary = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const jobType = useSelector(getType)
  const {
    name: deliveryName,
    street: deliveryStreetName,
    number: deliveryStreetNumber,
    zip: deliveryZip,
    city: deliveryCity,
    isPrivatePerson,
  } = useSelector(getCompanyDeliveryAddress)
  const { givenname, surname, email, phone } = useSelector(getContactData)
  const oderComment = useSelector(getFurtherInformation)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionProtectionClass = useSelector(getDestructionProtection)
  const oderType = useSelector(getDestructionOrderType)
  const selectedContainerType = useSelector(getSelectedContainer)
  const deliveryDate = useSelector(getSelectedPickupDate)
  const { start, end } = useSelector(getSelectedApproachTime)
  const isContactPersonOnSite = useSelector(getIsContactPersonOnSite)
  const {
    givenname: onSiteContactPersonGivenname,
    surname: onSiteContactPersonSurname,
    email: onSiteContactPersonEmail,
    phone: onSiteContactPersonPhone,
  } = useSelector(getContactPersonData)
  const {
    name: invoiceName,
    street: invoiceStreetName,
    streetNumber: invoiceStreetNumber,
    zip: invoiceZip,
    city: invoiceCity,
    email: invoiceMail,
  } = useSelector(getInvoiceData)
  const paymentType = useSelector(getSelectedPaymentMethod)
  const {
    givenname: bankGivenname,
    surname: bankSurname,
    street: bankStreet,
    streetNumber: bankStreetNumber,
    zip: bankZip,
    city: bankCity,
    bankName,
    bankBic,
    bankIban,
  } = useSelector(getBankData)
  const bankConsent = useSelector(getSepaConsent)
  const isBecomePermanentCustomerSelected = useSelector(getIsBecomePermanentCustomerSelected)
  const agbConsent = useSelector(getAgbConsent)

  const { mutateAsync: postOrderRequest, isLoading: isSendingOrderRequest } = usePostOrderRequest()

  useEffect(() => {
    dispatch(setActiveStep(4))
  }, [dispatch])

  const handleOnPressSendOrderRequest = async () => {
    if (destructionSubject && destructionProtectionClass && oderType && selectedContainerType && paymentType) {
      const requestData: IOrderRequestApi = {
        deliveryAddress: {
          name: isPrivatePerson ? `${givenname} ${surname}` : deliveryName,
          street: deliveryStreetName,
          streetNumber: deliveryStreetNumber,
          postCode: deliveryZip,
          city: deliveryCity,
        },
        requestContact: {
          firstname: givenname,
          lastname: surname,
          email,
          phone,
        },
        comment: oderComment,
        subject: destructionSubject,
        protectionClass: destructionProtectionClass,
        orderType: oderType,
        containerType: selectedContainerType.containerType,
        count: selectedContainerType.amount,
        deliveryDate: dayjs(deliveryDate, 'dddd, DD.MM.YYYY').format('DD.MM.YYYY'),
        deliveryStartingTime: start,
        deliveryFinishingTime: end,
        deliveryContact: isContactPersonOnSite
          ? undefined
          : {
              firstname: onSiteContactPersonGivenname,
              lastname: onSiteContactPersonSurname,
              email: onSiteContactPersonEmail,
              phone: onSiteContactPersonPhone,
            },
        invoiceAddress: {
          name: invoiceName,
          street: invoiceStreetName,
          streetNumber: invoiceStreetNumber,
          postCode: invoiceZip,
          city: invoiceCity,
          invoiceEmail: invoiceMail,
        },
        paymentType,
        debitNoteInfo:
          paymentType === PaymentMethod.INVOICE
            ? undefined
            : {
                accountOwner: {
                  name: `${bankGivenname} ${bankSurname}`,
                  street: bankStreet,
                  streetNumber: bankStreetNumber,
                  city: bankCity,
                  postCode: bankZip,
                },
                bankName,
                bankBIC: bankBic,
                iban: bankIban,
                consent: bankConsent,
              },
        permanentCustomer: !!isBecomePermanentCustomerSelected,
        agbConsent,
      }
      try {
        await postOrderRequest(requestData, {
          onSettled: (data, error) => {
            dispatch(setHasApiError(!data?.ok ?? true))
            dispatch(setResponseStatus(data?.status ?? 400))
            dispatch(setStatusText(data?.statusText ?? error?.message ?? ''))
          },
        })
      } catch {
      } finally {
        navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.SENT}`)
        window.scrollTo(0, 0)
      }
    }
  }

  const handleOnPressBack = () => {
    navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.META_INFORMATION}`)
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <TitleGrid>
          <Typography variant="underlinedTileTitle">{t('order.summary.title_job_type')}</Typography>
        </TitleGrid>
        <DataCardGrid>
          {jobType && <JobTypeCard type={jobType} testId="order.summary.job_type_card" />}
          <PriceSummarySection />
          <SpecifiedDataSummarySection />
        </DataCardGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="order.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        {isSendingOrderRequest ? (
          <StyledButton disabled data-testid="order.summary.button_sending" variant="contained">
            <StyledProgress size={16} />
            {t('order.summary.button_send_loading')}
          </StyledButton>
        ) : (
          <StyledButton
            onClick={handleOnPressSendOrderRequest}
            data-testid="order.summary.button_send"
            variant="contained">
            {t('order.summary.button_send')}
          </StyledButton>
        )}
      </ButtonGrid>
    </React.Fragment>
  )
}

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: 10,
}))

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginRight: theme.layout.spacing._110,
    marginLeft: theme.layout.spacing._110,
  },
  [theme.breakpoints.down('laptop')]: {
    marginRight: theme.layout.spacing._50,
    marginLeft: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('tablet')]: {
    marginRight: theme.layout.spacing._30,
    marginLeft: theme.layout.spacing._30,
  },
}))

const TitleGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.layout.spacing._80,
  marginBottom: theme.layout.spacing._50,
}))

const DataCardGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.spacing._30,
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._70,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._70,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
