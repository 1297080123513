import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearSelectedContainer,
  getContainerPrices,
  getDestructionOrderType,
  getSelectedContainer,
  getSuggestedContainers,
  setContainerPrices,
  setSelectedContainer,
} from '../../../store/order.slice'
import { ContainerPriceCode, DestructionOrderType, IContainerInfos, IContainerPricesResponse } from '../../../types'
import { currencyNumber } from '../../../utils'
import { ContainerCard } from './ContainerCard'

interface IProps {
  onApiError: () => void
}

export const ContainerSelection = ({ onApiError }: IProps) => {
  const dispatch = useDispatch()
  const suggestedContainers = useSelector(getSuggestedContainers)
  const selectedContainer = useSelector(getSelectedContainer)
  const containerPrices = useSelector(getContainerPrices)
  const orderType = useSelector(getDestructionOrderType)
  const { t } = useTranslation()

  const handleSelectedContainer = (
    selectedType: IContainerInfos,
    isSelected: boolean,
    amount: number,
    containerPrices?: IContainerPricesResponse[],
  ) => {
    dispatch(clearSelectedContainer())
    if (isSelected && selectedContainer?.containerType !== selectedType.containerType) {
      dispatch(setSelectedContainer({ containerType: selectedType.containerType, amount }))
      containerPrices && dispatch(setContainerPrices(containerPrices))
    }
  }

  const getSubTotalPrice = () => {
    if (containerPrices && selectedContainer) {
      const rentalPrice =
        orderType === DestructionOrderType.PERMANENT
          ? containerPrices.find((d) => d.code === ContainerPriceCode.EXCHANGE_PRICE)?.price ?? 0
          : containerPrices.find((d) => d.code === ContainerPriceCode.PICK_UP_PRICE)?.price ?? 0
      const amount = selectedContainer.amount
      return currencyNumber(amount * rentalPrice)
    } else if (containerPrices && !selectedContainer) {
      const rentalPrice =
        orderType === DestructionOrderType.PERMANENT
          ? containerPrices.find((d) => d.code === ContainerPriceCode.EXCHANGE_PRICE)?.price ?? 0
          : containerPrices.find((d) => d.code === ContainerPriceCode.PICK_UP_PRICE)?.price ?? 0
      const amount = 0
      return currencyNumber(amount * rentalPrice)
    } else {
      return currencyNumber(undefined)
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.meta_information.type_of_container.title')}</Typography>
      <CardGrid>
        {suggestedContainers?.map((container, index) => (
          <ContainerCard
            key={`${container.containerType}-${index}`}
            testId="type_of_container_card"
            type={container.containerType}
            onSelect={(selected, amount, containerPrices) =>
              handleSelectedContainer(container, selected, amount, containerPrices)
            }
            isSelected={selectedContainer?.containerType === container.containerType}
            suggestedAmount={container.amount}
            onApiError={onApiError}
          />
        ))}
      </CardGrid>
      <TextGrid>
        <Typography>{t('order.meta_information.sub_total_label')}</Typography>
        <SubTotalText data-testid="order.meta_information.sub_total" variant="tileTitle">
          {getSubTotalPrice()}
        </SubTotalText>
        <Typography variant="tileTitle">{t('order.meta_information.hint_label')}</Typography>
        <Typography variant="tileText">{t('order.meta_information.hint_description')}</Typography>
      </TextGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._30,
}))

const SubTotalText = styled(Typography)(({ theme }) => ({
  marginTop: theme.layout.spacing._12,
  marginBottom: theme.layout.spacing._20,
  fontSize: theme.layout.fontSize._24,
}))
