import React, { useEffect } from 'react'

import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ROUTE from '../../../route'
import { getType, setActiveStep, setRequestType } from '../../../store/common.slice'
import { getAmount, getUnit, setAmount, setUnit } from '../../../store/inquiry.slice'
import { CustomMenuProps } from '../../../theme'
import { InquiryAmountUnit, JobType, RequestType } from '../../../types'
import { containsEmptyString } from '../../../utils'

export const AmountSelection = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const amount = useSelector(getAmount)
  const unit = useSelector(getUnit)
  const jobType = useSelector(getType)

  useEffect(() => {
    dispatch(setActiveStep(1))
    dispatch(setRequestType(RequestType.INQUIRY))
  }, [dispatch])

  const formik = useFormik({
    initialValues: { amount: amount ?? '' },
    validationSchema: yup.object({
      amount: yup
        .string()
        .min(1, t('inquiry.amount_selection.input_error'))
        .required(t('inquiry.amount_selection.input_error')),
    }),
    onSubmit: () => undefined,
  })

  const persistUserInput = () => {
    dispatch(setAmount(formik.values.amount))
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    dispatch(setUnit(e.target.value as InquiryAmountUnit))
  }

  const handleOnPressNext = () => {
    persistUserInput()
    navigate(ROUTE.INQUIRY.CONTACT_DETAILS)
    window.scrollTo(0, 0)
  }

  const handleOnPressBack = () => {
    persistUserInput()
    navigate(ROUTE.HOME)
    window.scrollTo(0, 0)
  }

  const getIsNextButtonDisabled = () => {
    return containsEmptyString([unit, formik.values.amount])
  }

  const getSelectedValueText = (unit: string) => {
    switch (unit) {
      case InquiryAmountUnit.FOLDER:
        return t('inquiry.amount_selection.dropdown.folders').toUpperCase()

      case InquiryAmountUnit.PORTFOLIO:
        return t('inquiry.amount_selection.dropdown.portfolio').toUpperCase()

      case InquiryAmountUnit.PAGE:
        return t('inquiry.amount_selection.dropdown.pages').toUpperCase()
    }
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <TextGrid>
          <TitleGrid>
            <Typography variant="underlinedTileTitle">
              {jobType === JobType.ARCHIVE
                ? t('inquiry.amount_selection.title_archive')
                : t('inquiry.amount_selection.title_digitize')}
            </Typography>
          </TitleGrid>
          <DescriptionGrid>
            <Typography>{t('inquiry.amount_selection.description')}</Typography>
          </DescriptionGrid>
        </TextGrid>
        <InputGrid>
          <StyledTextField
            placeholder={t('inquiry.amount_selection.input_placeholder').toUpperCase()}
            inputProps={{ 'data-testid': 'inquiry.amount_selection.input' }}
            id="amount"
            name="amount"
            value={formik.values.amount}
            required
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={(formik.touched.amount && formik.errors.amount) ?? ' '}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
          />
          <StyledFormControl>
            <Select
              value={unit}
              displayEmpty
              onChange={handleSelectChange}
              renderValue={(selected) =>
                selected === ''
                  ? t('inquiry.amount_selection.dropdown.placeholder').toUpperCase()
                  : getSelectedValueText(unit.toUpperCase())
              }
              MenuProps={CustomMenuProps}
              inputProps={{ 'data-testid': 'inquiry.amount_selection.dropdown' }}>
              <MenuItem disabled value={t('inquiry.amount_selection.dropdown.placeholder')}>
                <em>{t('inquiry.amount_selection.dropdown.placeholder').toUpperCase()}</em>
              </MenuItem>
              <MenuItem value={InquiryAmountUnit.FOLDER}>
                {t('inquiry.amount_selection.dropdown.folders').toUpperCase()}
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem value={InquiryAmountUnit.PORTFOLIO}>
                {t('inquiry.amount_selection.dropdown.portfolio').toUpperCase()}
              </MenuItem>
              <Divider variant="middle" />
              <MenuItem value={InquiryAmountUnit.PAGE}>
                {t('inquiry.amount_selection.dropdown.pages').toUpperCase()}
              </MenuItem>
            </Select>
          </StyledFormControl>
        </InputGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="inquiry.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        <StyledButton
          onClick={handleOnPressNext}
          data-testid="inquiry.next_button"
          disabled={getIsNextButtonDisabled()}
          variant="contained">
          {t('buttons.next')}
        </StyledButton>
      </ButtonGrid>
    </React.Fragment>
  )
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  flex: 0.5,
  alignItems: 'center',
  [theme.breakpoints.up('desktop')]: {
    paddingLeft: theme.layout.spacing._110,
    paddingTop: theme.layout.spacing._290,
    paddingRight: theme.layout.spacing._70,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingLeft: theme.layout.spacing._70,
    paddingTop: theme.layout.spacing._70,
  },
  [theme.breakpoints.down('tablet')]: {
    paddingLeft: theme.layout.spacing._30,
    paddingTop: theme.layout.spacing._30,
  },
}))

const TitleGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.spacing._40,
  maxWidth: theme.layout.size._350,
}))

const DescriptionGrid = styled(Grid)(({ theme }) => ({
  maxWidth: theme.layout.size._450,
  [theme.breakpoints.down('tablet')]: {
    paddingRight: theme.layout.spacing._30,
  },
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 0.5,
  [theme.breakpoints.up('desktop')]: {
    paddingRight: theme.layout.spacing._110,
    paddingTop: theme.layout.spacing._290,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingRight: theme.layout.spacing._70,
    paddingLeft: theme.layout.spacing._70,
    paddingTop: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('tablet')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: theme.layout.size._150,
  marginLeft: theme.layout.spacing._20,
  [theme.breakpoints.down('tablet')]: {
    width: theme.layout.size._120,
  },
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._290,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
