export enum JobType {
  ARCHIVE = 'ARCHIVE',
  DIGITIZE = 'DIGITIZE',
  DESTRUCT = 'DESTRUCT',
}

export enum InquiryAmountUnit {
  FOLDER = 'FOLDER',
  PORTFOLIO = 'PORTFOLIO',
  PAGE = 'PAGE',
}

export interface ICompanyDeliveryAddress {
  name: string
  street: string
  number: string
  zip: string
  city: string
  isPrivatePerson: boolean
}

export interface IContactData {
  givenname: string
  surname: string
  email: string
  phone: string
}
