import React from 'react'

import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { JOB_TYPE_ICON } from '../../../assets'
import { getType } from '../../../store/common.slice'
import { getAmount, getUnit } from '../../../store/inquiry.slice'
import {
  getDestructionAmount,
  getDestructionAmountUnit,
  getDestructionOrderType,
  getDestructionPlace,
  getDestructionProtection,
  getDestructionSubject,
  getDestructionType,
} from '../../../store/order.slice'
import {
  DestructionAmountUnit,
  DestructionOrderType,
  DestructionPlace,
  DestructionProtection,
  DestructionSubject,
  DestructionType,
  InquiryAmountUnit,
  JobType,
} from '../../../types'

interface IProps {
  type: JobType
  testId: string
}

export const JobTypeCard = ({ type, testId }: IProps) => {
  const { t } = useTranslation()
  const selectedJobType = useSelector(getType)
  const amount = useSelector(getAmount)
  const unit = useSelector(getUnit)
  const destructionType = useSelector(getDestructionType)
  const destructionAmount = useSelector(getDestructionAmount)
  const destructionAmountUnit = useSelector(getDestructionAmountUnit)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionProtection = useSelector(getDestructionProtection)
  const destructionPlace = useSelector(getDestructionPlace)
  const destructionOrderType = useSelector(getDestructionOrderType)

  const getAmountText = () => {
    let amountUnit = ''
    switch (unit) {
      case InquiryAmountUnit.FOLDER:
        amountUnit = t('inquiry.amount_selection.dropdown.folders')
        break

      case InquiryAmountUnit.PORTFOLIO:
        amountUnit = t('inquiry.amount_selection.dropdown.portfolio')
        break

      case InquiryAmountUnit.PAGE:
        amountUnit = t('inquiry.amount_selection.dropdown.pages')
        break
    }
    return `${amount} ${amountUnit}`
  }

  const getDestructionAmountText = () => {
    const unit =
      destructionAmountUnit === DestructionAmountUnit.FOLDER
        ? t('order.order_informations.amount_of_destruction.input.folder_label')
        : t('order.order_informations.amount_of_destruction.input.hdd_label')
    let subject = ''

    switch (destructionSubject) {
      case DestructionSubject.FILES_PAPER:
        subject = t('order.order_informations.subject_of_destruction.files_paper_title')
        break

      case DestructionSubject.HDD:
        subject = t('order.order_informations.subject_of_destruction.hhd_title')
        break

      case DestructionSubject.CD_DVD:
        subject = t('order.order_informations.subject_of_destruction.cd_dvd_title')
        break

      case DestructionSubject.FLOPPY_ID_MAGNETIC:
        subject = t('order.order_informations.subject_of_destruction.floppy_id_magnetic_title')
        break

      case DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR:
        subject = t('order.order_informations.subject_of_destruction.memory_chip_semiconductor_title')
        break
    }
    return `${destructionAmount} ${unit} (${subject})`
  }

  const getPlaceText = () => {
    switch (destructionPlace) {
      case DestructionPlace.EG_BF:
        return t('order.order_informations.place_of_destruction.eg_bf_title')

      case DestructionPlace.OG_LIFT:
        return t('order.order_informations.place_of_destruction.og_lift_title')

      case DestructionPlace.UG_LIFT:
        return t('order.order_informations.place_of_destruction.ug_lift_title')

      case DestructionPlace.OG_NO_LIFT:
        return t('order.order_informations.place_of_destruction.og_no_lift_title')

      case DestructionPlace.UG_NO_LIFT:
        return t('order.order_informations.place_of_destruction.ug_no_lift_title')
    }
  }

  const getProtectionText = () => {
    switch (destructionProtection) {
      case DestructionProtection.PROTECTION_2_4:
        return t('order.order_informations.protection_of_destruction.2_4_title')

      case DestructionProtection.PROTECTION_2_5:
        return t('order.order_informations.protection_of_destruction.2_5_title')

      case DestructionProtection.PROTECTION_3_4:
        return t('order.order_informations.protection_of_destruction.3_4_title')

      case DestructionProtection.PROTECTION_3_5:
        return t('order.order_informations.protection_of_destruction.3_5_title')
    }
  }

  const getDestructionOrderTypeText = () => {
    switch (destructionOrderType) {
      case DestructionOrderType.PERMANENT:
        return t('order.order_informations.order_type_of_destruction.permanent_summary')

      case DestructionOrderType.SHORT_TERM:
        return t('order.order_informations.order_type_of_destruction.short_term_summary')

      case DestructionOrderType.AT_ONCE:
        return t('order.order_informations.order_type_of_destruction.at_once_summary')
    }
  }

  const renderArchiveDigitizeCard = () => (
    <React.Fragment>
      <TopGrid>
        <StyledIcon src={JOB_TYPE_ICON} />
        <StyledJobTypeTitle data-testid="inquiry.summary.job_type_title" variant="tileTitle">
          {selectedJobType === JobType.ARCHIVE
            ? t('inquiry.summary.job_types.archive')
            : t('inquiry.summary.job_types.digitize')}
        </StyledJobTypeTitle>
      </TopGrid>
      <BottomGrid>
        <Typography variant="tileText">{t('inquiry.summary.label_amount')}</Typography>
        <Typography data-testid="inquiry.summary.job_type_amount_unit" variant="pageTitle">
          {getAmountText()}
        </Typography>
      </BottomGrid>
    </React.Fragment>
  )

  const renderDestructCard = () => (
    <React.Fragment>
      <TopGrid>
        <StyledIcon src={JOB_TYPE_ICON} />
        <StyledJobTypeTitle data-testid="inquiry.summary.job_type_title" variant="tileTitle">
          {t('inquiry.summary.destruct.card_title')}
        </StyledJobTypeTitle>
      </TopGrid>
      <DestructBottomGrid>
        <BottomContentGrid>
          <Section>
            <Typography variant="tileText">{t('inquiry.summary.destruct.type_label')}</Typography>
            <Typography data-testid="summary.destruction_type" variant="pageTitle">
              {destructionType === DestructionType.MOBILE_DESTRUCTION
                ? t('order.order_informations.type_of_destruction.mobile_title')
                : t('order.order_informations.type_of_destruction.stationary_title')}
            </Typography>
          </Section>
          <Section>
            <Typography variant="tileText">{t('inquiry.summary.destruct.subject_label')}</Typography>
            {destructionAmount && destructionAmountUnit && (
              <AmountTextGrid>
                <Typography data-testid="summary.destruction_amount" variant="pageTitle">
                  {getDestructionAmountText()}
                </Typography>
              </AmountTextGrid>
            )}
          </Section>
          <Section>
            <Typography variant="tileText">{t('inquiry.summary.destruct.order_type_label')}</Typography>
            <Typography data-testid="summary.destruction_order_type" variant="pageTitle">
              {getDestructionOrderTypeText()}
            </Typography>
          </Section>
        </BottomContentGrid>
        <Separator />
        <BottomContentGrid>
          <Section>
            <Typography variant="tileText">{t('inquiry.summary.destruct.protection_label')}</Typography>
            <Typography data-testid="summary.destruction_protection" variant="pageTitle">
              {getProtectionText()}
            </Typography>
          </Section>
          <Section>
            <Typography variant="tileText">{t('inquiry.summary.destruct.place_of_destruction_label')}</Typography>
            <Typography data-testid="summary.destruction_place_of_data" variant="pageTitle">
              {getPlaceText()}
            </Typography>
          </Section>
        </BottomContentGrid>
      </DestructBottomGrid>
    </React.Fragment>
  )

  return (
    <Grid>
      <StyledCard variant="outlined">
        <StyledCardContent data-testid={testId}>
          {type === JobType.DESTRUCT ? renderDestructCard() : renderArchiveDigitizeCard()}
        </StyledCardContent>
      </StyledCard>
    </Grid>
  )
}

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.layout.spacing._20,
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledIcon = styled('img')(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._10,
  },
}))

const StyledJobTypeTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._30,
  },
}))

const BottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._50 + theme.layout.spacing._30,
  },
}))

const DestructBottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._50 + theme.layout.spacing._30,
  },
}))

const BottomContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundcolor: 'purple',
  marginTop: theme.layout.spacing._20,
}))

const AmountTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}))

const Separator = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.layout.spacing._80,
  marginRight: theme.layout.spacing._80,
  marginTop: theme.layout.spacing._20,
  width: theme.layout.borderWidth._2,
  backgroundColor: theme.color.secondary.softGray,
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}))
