import React from 'react'

import { Grid, Link, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation()

  return (
    <FooterGrid container item>
      <ContentContainer>
        <CopyrightGrid>
          <Copyright variant="footerNotes">{t('footer.copyright', { year: dayjs().year() })}</Copyright>
        </CopyrightGrid>
        <LinkGrid>
          <LegalLink target="_blank" rel="noopener" href="https://documentus-bayern.de/impressum" variant="footerNotes">
            {t('footer.legal_disclousure')}
          </LegalLink>
          <LegalLink
            target="_blank"
            rel="noopener"
            href="https://documentus-bayern.de/datenschutz"
            variant="footerNotes">
            {t('footer.privacy_policy')}
          </LegalLink>
        </LinkGrid>
      </ContentContainer>
    </FooterGrid>
  )
}

const FooterGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size.footer,
  [theme.breakpoints.down('tablet')]: {
    justifyContent: 'center',
  },
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.color.primary.blue,
  zIndex: 2,
}))

const ContentContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
    marginRight: theme.layout.spacing._80,
    marginBottom: theme.layout.spacing._10,
  },
  [theme.breakpoints.down('mobile')]: {
    marginLeft: theme.layout.spacing._10,
    marginRight: theme.layout.spacing._10,
    flexDirection: 'column-reverse',
  },
}))

const CopyrightGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 0.5,
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    justifyContent: 'center',
  },
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._10,
    justifyContent: 'center',
  },
}))

const LinkGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 0.5,
  [theme.breakpoints.up('mobile')]: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    justifyContent: 'center',
  },
}))

const LegalLink = styled(Link)(({ theme }) => ({
  color: theme.color.white,
  textDecoration: 'none',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    marginTop: theme.layout.spacing._10,
    marginLeft: theme.layout.spacing._10,
    marginRight: theme.layout.spacing._10,
    marginBottom: theme.layout.spacing._10,
  },
}))

const Copyright = styled(Typography)(({ theme }) => ({
  textTransform: 'none',
}))
