import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { JobType, RequestType } from '../types'

interface ICommonState {
  type?: JobType
  requestType?: RequestType
  activeStep: number
}

const initialState: ICommonState = {
  activeStep: 0,
}

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setType: (state, { payload: type }: PayloadAction<JobType>) => {
      state.type = type
    },
    clearType: (state) => {
      state.type = undefined
    },
    setRequestType: (state, { payload: requestType }: PayloadAction<RequestType>) => {
      state.requestType = requestType
    },
    setActiveStep: (state, { payload: step }: PayloadAction<number>) => {
      state.activeStep = step
    },
    reset: () => initialState,
  },
})

export const { setType, clearType, setRequestType, setActiveStep, reset } = slice.actions

export const getType = (state: { [slice.name]: ICommonState }) => state[slice.name].type
export const getActiveStep = (state: { [slice.name]: ICommonState }) => state[slice.name].activeStep
export const getRequestType = (state: { [slice.name]: ICommonState }) => state[slice.name].requestType

export default slice.reducer
