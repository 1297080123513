import { useEffect, useState } from 'react'

import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import _ from 'lodash'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { AppContainer, CustomStepper } from '../../components'
import ROUTE from '../../route'
import { getActiveStep, getType } from '../../store/common.slice'

export const InquiryView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const jobType = useSelector(getType)
  const currentStep = useSelector(getActiveStep)
  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    if (_.isUndefined(jobType)) {
      navigate(ROUTE.HOME)
    }

    switch (location.pathname) {
      case `${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.SUMMARY}`:
        setPageTitle(t('inquiry.summary.page_title'))
        break

      case `${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.FURTHER_INFORMATION}`:
        setPageTitle(t('inquiry.further_information.page_title'))
        break

      case `${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.CONTACT_DETAILS}`:
        setPageTitle(t('inquiry.contact_details.page_title'))
        break

      case ROUTE.INQUIRY.MAIN:
        setPageTitle(t('inquiry.amount_selection.page_title'))
        break
    }
  }, [jobType, navigate, location, t])

  return (
    <AppContainer>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <StyledBox>
        {currentStep > 0 && (
          <StepperGrid>
            <CustomStepper activeStep={currentStep} />
          </StepperGrid>
        )}
        <Outlet />
      </StyledBox>
    </AppContainer>
  )
}

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
})

const StepperGrid = styled(Grid)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('laptop')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.layout.spacing._10,
  },
}))
