import React from 'react'

import { createTheme } from '@mui/material/styles'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    pageTitle: true
    cardTitle: true
    tileText: true
    tileTitle: true
    underlinedTileTitle: true
    hintText: true
    hintTitle: true
    title: true
    footerNotes: true
    h1: false
    h2: false
    h3: false
    h4: false
    h5: false
    h6: false
    subtitle1: false
    subtitle2: false
    body2: false
    inherit: false
    button: false
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    pageTitle: React.CSSProperties
    cardTitle: React.CSSProperties
    tileText: React.CSSProperties
    tileTitle: React.CSSProperties
    hintText: React.CSSProperties
    hintTitle: React.CSSProperties
    title: React.CSSProperties
    body1: React.CSSProperties
    underlinedTileTitle: React.CSSProperties
    footerNotes: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    pageTitle: React.CSSProperties
    cardTitle: React.CSSProperties
    tileText: React.CSSProperties
    tileTitle: React.CSSProperties
    hintText: React.CSSProperties
    hintTitle: React.CSSProperties
    title: React.CSSProperties
    body1: React.CSSProperties
    underlinedTileTitle: React.CSSProperties
    footerNotes: React.CSSProperties
  }
  interface CustomColor {
    primary: {
      blue: string
      softBlue: string
    }
    secondary: {
      gray: string
      lightGray: string
      softGray: string
    }
    tertiary: {
      green: string
    }
    error: string
    white: string
    black: string
  }

  interface CustomLayout {
    spacing: {
      _5: number
      _10: number
      _12: number
      _20: number
      _30: number
      _40: number
      _50: number
      _60: number
      _70: number
      _80: number
      _110: number
      _180: number
      _290: number
    }
    fontSize: {
      _14: number
      _18: number
      _24: number
      _30: number
    }
    size: {
      header: number
      header_mobile: number
      footer: number
      _2: number
      _3: number
      _20: number
      _30: number
      _32: number
      _48: number
      _50: number
      _60: number
      _70: number
      _100: number
      _120: number
      _150: number
      _200: number
      _300: number
      _315: number
      _320: number
      _350: number
      _400: number
      _450: number
    }
    borderWidth: {
      _1: number
      _2: number
    }
  }
  interface Theme {
    color: CustomColor
    layout: CustomLayout
  }

  interface ThemeOptions {
    color: CustomColor
    layout: CustomLayout
  }

  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    tablet: true
    laptop: true
    desktop: true
  }
}

const PRIMARY_BLUE = '#102757'
const PRIMARY_SOFT_BLUE = '#1027571A'
const SECONDARY_GRAY = '#4F4F4F'
const SECONDARY_LIGHT_GRAY = '#E0E0E0'
const SECONDARY_SOFT_GRAY = '#F2F2F2'
const TERTIARY_GREEN = '#95C22C'
const WHITE = '#FFFFFF'
const BLACK = '#000000'
const ERROR = '#d32f2f'

const theme = createTheme({
  color: {
    primary: {
      blue: PRIMARY_BLUE,
      softBlue: PRIMARY_SOFT_BLUE,
    },
    secondary: {
      gray: SECONDARY_GRAY,
      lightGray: SECONDARY_LIGHT_GRAY,
      softGray: SECONDARY_SOFT_GRAY,
    },
    tertiary: {
      green: TERTIARY_GREEN,
    },
    error: ERROR,
    white: WHITE,
    black: BLACK,
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    footerNotes: {
      fontSize: 13,
      fontWeight: 700,
      color: WHITE,
      textTransform: 'uppercase',
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    cardTitle: {
      fontSize: 22,
    },
    pageTitle: {
      fontSize: 14,
      fontWeight: 700,
    },
    tileText: {
      fontSize: 14,
      fontWeight: 400,
    },
    tileTitle: {
      fontSize: 18,
      fontWeight: 700,
    },
    underlinedTileTitle: {
      fontSize: 18,
      fontWeight: 700,
      textTransform: 'uppercase',
      color: PRIMARY_BLUE,
      textDecoration: 'underline',
      textDecorationThickness: 3,
      textUnderlineOffset: 5,
      lineHeight: 2,
    },
    hintText: {
      fontSize: 18,
      fontWeight: 400,
    },
    hintTitle: {
      color: PRIMARY_BLUE,
      fontSize: 22,
      fontWeight: 700,
    },
    title: {
      fontSize: 30,
      fontWeight: 700,
    },
    fontFamily: ['Open Sans'].join(','),
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      mobile: 601,
      tablet: 769,
      laptop: 1025,
      desktop: 1201,
    },
  },
  layout: {
    fontSize: {
      _14: 14,
      _18: 18,
      _24: 24,
      _30: 30,
    },
    spacing: {
      _5: 5,
      _10: 10,
      _12: 12,
      _20: 20,
      _30: 30,
      _40: 40,
      _50: 50,
      _60: 60,
      _70: 70,
      _80: 80,
      _110: 110,
      _180: 180,
      _290: 290,
    },
    size: {
      header: 130,
      header_mobile: 100,
      footer: 70,
      _2: 2,
      _3: 3,
      _20: 20,
      _30: 30,
      _32: 32,
      _48: 48,
      _50: 50,
      _60: 60,
      _70: 70,
      _100: 100,
      _120: 120,
      _150: 150,
      _200: 200,
      _300: 300,
      _315: 315,
      _320: 320,
      _350: 350,
      _400: 400,
      _450: 450,
    },
    borderWidth: {
      _1: 1,
      _2: 2,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `1px solid ${SECONDARY_LIGHT_GRAY}`,
          marginTop: 15,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: 65,
        },
        notchedOutline: {
          borderColor: SECONDARY_LIGHT_GRAY,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: TERTIARY_GREEN,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: PRIMARY_BLUE,
          minWidth: 200,
          height: 35,
          color: WHITE,
          ':hover': {
            backgroundColor: PRIMARY_SOFT_BLUE,
            color: PRIMARY_BLUE,
          },
          ':disabled': {
            borderColor: SECONDARY_LIGHT_GRAY,
            backgroundColor: SECONDARY_LIGHT_GRAY,
            color: WHITE,
          },
        },
        outlined: {
          backgroundColor: 'transparent',
          color: PRIMARY_BLUE,
          borderColor: PRIMARY_BLUE,
          ':hover': {
            backgroundColor: PRIMARY_BLUE,
            color: WHITE,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '::placeholder': {
            fontWeight: 700,
            color: BLACK,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: SECONDARY_LIGHT_GRAY,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          backgroundColor: WHITE,
          marginRight: 0,
          marginLeft: 0,
          marginTop: 0,
          paddingTop: 5,
          height: 30,
        },
      },
    },
  },
})

export default theme

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 10
export const CustomMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
    },
  },
}
