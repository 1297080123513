import { ReactElement } from 'react'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { ARCHIVE_ICON, DESTRUCT_ICON, DIGITIZE_ICON } from '../../../assets'
import { JobType } from '../../../types'

interface IProps {
  title: string | ReactElement
  type: JobType
  description: string
  onSelect: (isSelected: boolean) => void
  isSelected: boolean
  testId: string
}

export const Card = ({ title, description, onSelect, isSelected, type, testId }: IProps) => {
  const handleChange = (event: any) => {
    event.preventDefault()
    onSelect(!isSelected)
  }

  const iconSource = () => {
    switch (type) {
      case 'ARCHIVE':
        return ARCHIVE_ICON
      case 'DIGITIZE':
        return DIGITIZE_ICON
      case 'DESTRUCT':
        return DESTRUCT_ICON
    }
  }

  const card = (
    <StyledCardContent onClick={handleChange}>
      <StyledFormGroup>
        <FormControlLabel
          onChange={handleChange}
          control={
            <StyledCheckbox
              data-testid={testId}
              checked={isSelected}
              disableRipple={true}
              icon={<CircleOutlinedIcon />}
              checkedIcon={<CheckCircleRoundedIcon />}
            />
          }
          label={<Typography variant="pageTitle">{title}</Typography>}
        />
      </StyledFormGroup>
      <IconGrid>
        <StyledIcon src={iconSource()} />
      </IconGrid>
      <TextGrid>
        <Typography variant="tileText">{description}</Typography>
      </TextGrid>
    </StyledCardContent>
  )

  return (
    <CardGrid>
      <StyledCard isSelected={isSelected} variant="outlined">
        {card}
      </StyledCard>
    </CardGrid>
  )
}

const CardGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    padding: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    padding: theme.layout.spacing._10,
  },
}))

const StyledCard = styled(MuiCard, { shouldForwardProp: (prop) => prop !== 'isSelected' })<Partial<IProps>>(
  ({ theme, isSelected }) => ({
    borderWidth: theme.layout.borderWidth._2,
    borderColor: isSelected ? theme.color.tertiary.green : theme.color.secondary.softGray,
    [theme.breakpoints.up('laptop')]: {
      maxWidth: theme.layout.size._320,
    },
    [theme.breakpoints.down('laptop')]: {
      marginRight: theme.layout.spacing._110,
      marginLeft: theme.layout.spacing._110,
    },
    [theme.breakpoints.down('tablet')]: {
      marginRight: theme.layout.spacing._10,
      marginLeft: theme.layout.spacing._10,
    },
  }),
)

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
}))

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    paddingRight: theme.layout.spacing._40,
    paddingLeft: theme.layout.spacing._40,
    paddingTop: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    paddingRight: theme.layout.spacing._20,
    paddingLeft: theme.layout.spacing._20,
    paddingTop: theme.layout.spacing._10,
    paddingBottom: theme.layout.spacing._10,
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: theme.layout.spacing._10,
  color: theme.color.secondary.softGray,
  [theme.breakpoints.down('laptop')]: {
    marginLeft: theme.layout.spacing._20,
    marginRight: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('mobile')]: {
    marginRight: theme.layout.spacing._20,
  },
}))

const IconGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
}))

const StyledIcon = styled('img')(({ theme }) => ({
  width: '100%',
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    paddingRight: theme.layout.spacing._40,
    paddingLeft: theme.layout.spacing._40,
    paddingTop: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    paddingRight: theme.layout.spacing._20,
    paddingLeft: theme.layout.spacing._20,
    paddingTop: theme.layout.spacing._10,
    paddingBottom: theme.layout.spacing._10,
  },
}))
