import { combineReducers, createStore } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension'

import api from './api.slice'
import common from './common.slice'
import inquiry from './inquiry.slice'
import order from './order.slice'

export const rootReducer = combineReducers({
  api,
  common,
  inquiry,
  order,
})

const store = createStore(rootReducer, composeWithDevTools())

export default store
