import React from 'react'

import { CircularProgress, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useGetPickupDates } from '../../../hooks'
import { getCompanyDeliveryAddress } from '../../../store/inquiry.slice'
import {
  getAvailablePickupDates,
  getSelectedPickupDate,
  setAvailablePickupDates,
  setSelectedPickupDate,
} from '../../../store/order.slice'
import { CustomMenuProps } from '../../../theme'
import { DEFAULT_PICK_UP_DATES } from '../../../utils'

export const PickupTimeSelection = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { zip } = useSelector(getCompanyDeliveryAddress)
  const selectedPickupDate = useSelector(getSelectedPickupDate)
  const availablePickupDates = useSelector(getAvailablePickupDates)

  const { data: fetchedPickupDates, isLoading: isFetching } = useGetPickupDates({
    params: {
      zip,
    },
    options: {
      onError: () => {
        dispatch(setAvailablePickupDates(DEFAULT_PICK_UP_DATES))
      },
      onSuccess: (data) => {
        const dates = data.map((d) => dayjs(d.date).format('dddd, DD.MM.YYYY'))
        _.isEmpty(fetchedPickupDates)
          ? dispatch(setAvailablePickupDates(DEFAULT_PICK_UP_DATES))
          : dispatch(setAvailablePickupDates(dates))
      },
    },
  })

  const handleDateSelection = (event: SelectChangeEvent) => {
    dispatch(setSelectedPickupDate(event.target.value))
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.meta_information.pick_up_title')}</Typography>
      <StyledFormControl>
        <Select
          value={selectedPickupDate}
          displayEmpty
          onChange={handleDateSelection}
          renderValue={(selected) =>
            selected === '' ? t('order.meta_information.pick_up_placeholder') : selectedPickupDate
          }
          MenuProps={CustomMenuProps}
          inputProps={{ 'data-testid': 'order.meta_information.pick_up.dropdown' }}>
          {isFetching && (
            <MenuItem disabled value={selectedPickupDate}>
              {
                <React.Fragment>
                  <LoadingText>{t('order.meta_information.pick_up_loading')}</LoadingText>
                  <CircularProgress size={16} />
                </React.Fragment>
              }
            </MenuItem>
          )}
          {!isFetching &&
            availablePickupDates.map((date) => (
              <MenuItem key={dayjs(date, 'DD.MM.YYYY').unix()} value={date}>
                {date}
              </MenuItem>
            ))}
        </Select>
      </StyledFormControl>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.layout.spacing._40,
}))

const LoadingText = styled(Typography)(({ theme }) => ({
  marginRight: theme.layout.spacing._10,
}))
