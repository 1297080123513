import React, { useEffect } from 'react'

import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { usePostSendInquiry } from '../../../hooks'
import ROUTE from '../../../route'
import { setHasApiError, setResponseStatus, setStatusText } from '../../../store/api.slice'
import { getRequestType, getType, setActiveStep } from '../../../store/common.slice'
import {
  getAmount,
  getCompanyDeliveryAddress,
  getContactData,
  getFurtherInformation,
  getUnit,
} from '../../../store/inquiry.slice'
import {
  getDestructionAmount,
  getDestructionAmountUnit,
  getDestructionOrderType,
  getDestructionPlace,
  getDestructionProtection,
  getDestructionSubject,
  getDestructionType,
} from '../../../store/order.slice'
import { IInquiryMailData, InquiryAmountUnit, JobType, RequestType } from '../../../types'
import { DataCard, JobTypeCard } from '../components'

export const Summary = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const jobType = useSelector(getType)
  const requestType = useSelector(getRequestType)
  const amount = useSelector(getAmount)
  const unit = useSelector(getUnit)
  const companyData = useSelector(getCompanyDeliveryAddress)
  const contactData = useSelector(getContactData)
  const furtherInformation = useSelector(getFurtherInformation)
  const destructionType = useSelector(getDestructionType)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionAmount = useSelector(getDestructionAmount)
  const destructionAmountUnit = useSelector(getDestructionAmountUnit)
  const destructionProtection = useSelector(getDestructionProtection)
  const destructionPlace = useSelector(getDestructionPlace)
  const destructionOrderType = useSelector(getDestructionOrderType)

  const { mutateAsync: postInquiryMail, isLoading: isSendingMail } = usePostSendInquiry()

  useEffect(() => {
    dispatch(setActiveStep(4))
  }, [dispatch])

  const handleOnPressSendInquiry = async () => {
    if (jobType) {
      let mailData = {}
      if (
        jobType === JobType.DESTRUCT &&
        destructionType &&
        destructionSubject &&
        destructionAmount &&
        destructionAmountUnit &&
        destructionProtection &&
        destructionPlace &&
        destructionOrderType
      ) {
        mailData = {
          companyData,
          contactData,
          furtherInformation,
          jobType,
          amount: destructionAmount,
          unit: destructionAmountUnit,
          orderInformation: {
            destructionType,
            subject: destructionSubject,
            protectionClass: destructionProtection,
            placeOfData: destructionPlace,
            orderType: destructionOrderType,
          },
        }
      }

      if (jobType === JobType.ARCHIVE || jobType === JobType.DIGITIZE) {
        mailData = {
          companyData,
          contactData,
          furtherInformation,
          jobType,
          amount,
          unit: unit as InquiryAmountUnit,
        }
      }
      try {
        await postInquiryMail(
          { mailData: mailData as IInquiryMailData, isPrivatePerson: companyData.isPrivatePerson, type: jobType },
          {
            onSettled: (data, error) => {
              dispatch(setHasApiError(!data?.ok ?? true))
              dispatch(setResponseStatus(data?.status ?? 400))
              dispatch(setStatusText(data?.statusText ?? error?.message ?? ''))
            },
          },
        )
      } catch {
      } finally {
        navigate(`${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.SENT}`)
        window.scrollTo(0, 0)
      }
    }
  }

  const handleOnPressBack = () => {
    if (requestType === RequestType.INQUIRY && jobType === JobType.DESTRUCT) {
      navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.FURTHER_INFORMATION}`)
    } else {
      navigate(`${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.FURTHER_INFORMATION}`)
    }
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <TitleGrid>
          <Typography variant="underlinedTileTitle">{t('inquiry.summary.title_job_type')}</Typography>
        </TitleGrid>
        <DataCardGrid>{jobType && <JobTypeCard type={jobType} testId="inquiry.summary.job_type_card" />}</DataCardGrid>

        <TitleGrid>
          <Typography variant="underlinedTileTitle">{t('inquiry.summary.title_specified_data')}</Typography>
        </TitleGrid>
        <DataCardGrid>
          <DataCard type="LOCATION" testId="inquiry.summary.location_card" />
        </DataCardGrid>
        <DataCardGrid>
          <DataCard type="CONTACT" testId="inquiry.summary.contact_card" />
        </DataCardGrid>
        <DataCardGrid>
          <DataCard type="INFO" testId="inquiry.summary.info_card" />
        </DataCardGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="inquiry.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        {isSendingMail ? (
          <StyledButton disabled data-testid="inquiry.summary.button_sending" variant="contained">
            <StyledProgress size={16} />
            {t('inquiry.summary.button_send_loading')}
          </StyledButton>
        ) : (
          <StyledButton
            onClick={handleOnPressSendInquiry}
            data-testid="inquiry.summary.button_send"
            variant="contained">
            {t('inquiry.summary.button_send')}
          </StyledButton>
        )}
      </ButtonGrid>
    </React.Fragment>
  )
}

const StyledProgress = styled(CircularProgress)(({ theme }) => ({
  marginRight: 10,
}))

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginRight: theme.layout.spacing._110,
    marginLeft: theme.layout.spacing._110,
  },
  [theme.breakpoints.down('laptop')]: {
    marginRight: theme.layout.spacing._50,
    marginLeft: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('tablet')]: {
    marginRight: theme.layout.spacing._30,
    marginLeft: theme.layout.spacing._30,
  },
}))

const TitleGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.layout.spacing._80,
  marginBottom: theme.layout.spacing._50,
}))

const DataCardGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.spacing._30,
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._70,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._70,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
