import {
  DestructionAmountUnit,
  IDestructMailBody,
  IInquiryMailBody,
  IOrderRequestApi,
  InquiryAmountUnit,
  JobType,
  PostInquiryMailData,
} from '../types'

interface RequestData {
  method: 'POST' | 'GET'
  endpoint: string
  searchParams?: any
  body?: any
}
const request = async ({ method, endpoint, searchParams, body }: RequestData) => {
  const fetchData: RequestInit = {
    method,
  }
  if (['POST'].includes(method)) fetchData.body = JSON.stringify(body)
  const url = new URL(`${process.env.REACT_APP_API_URL}/companies/0${endpoint}`)
  if (searchParams) {
    url.search = new URLSearchParams(searchParams).toString()
  }
  const response = await fetch(url.toString(), fetchData)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return method === 'POST' ? response : await response.json()
}

export const getPickupDates = async ({ queryKey }: any): Promise<any> => {
  const [, { zip }] = queryKey
  return await request({
    method: 'GET',
    endpoint: '/dates',
    searchParams: { zip },
  })
}

export const getContainerPrices = async ({ queryKey }: any): Promise<any> => {
  const [, { containerType, orderType, postCode, protectionClass, subject }] = queryKey
  return await request({
    method: 'GET',
    endpoint: '/container-prices',
    searchParams: { containerType, orderType, postCode, protectionClass, subject },
  })
}

export const postOrderRequest = async ({ ...data }: IOrderRequestApi) => {
  return await request({
    method: 'POST',
    endpoint: '/orders',
    body: data,
  })
}

export const postInquiryMail = async ({ ...data }: PostInquiryMailData) => {
  const { isPrivatePerson, mailData, type } = data
  const { name, street, number, zip, city } = mailData.companyData
  const { givenname, surname, email, phone } = mailData.contactData

  let body: IInquiryMailBody | IDestructMailBody
  body = {
    deliveryAddress: {
      name: isPrivatePerson ? `${givenname} ${surname}` : name,
      street,
      streetNumber: number,
      postCode: zip,
      city,
    },
    requestContact: {
      firstname: givenname,
      lastname: surname,
      email,
      phone,
    },
    service: mailData.jobType,
    count: parseInt(mailData.amount, 10),
    unit: mailData.unit as InquiryAmountUnit,
    comment: mailData.furtherInformation ? mailData.furtherInformation : undefined,
  }

  if (type === JobType.DESTRUCT && mailData.orderInformation) {
    const { destructionType, subject, protectionClass, placeOfData, orderType } = mailData.orderInformation
    body = {
      ...body,
      unit: mailData.unit as DestructionAmountUnit,
      destructionType,
      subject,
      protectionClass,
      placeOfData,
      orderType,
    }
  }

  return await request({
    method: 'POST',
    endpoint: '/inquiry',
    body,
  })
}
