import React, { useEffect } from 'react'

import { Button, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ROUTE from '../../../route'
import { getRequestType, getType, setActiveStep } from '../../../store/common.slice'
import { getFurtherInformation, setFurtherInformation } from '../../../store/inquiry.slice'
import { JobType, RequestType } from '../../../types'

export const FurtherInformation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const furtherInformation = useSelector(getFurtherInformation)
  const requestType = useSelector(getRequestType)
  const type = useSelector(getType)

  useEffect(() => {
    dispatch(setActiveStep(3))
  }, [dispatch])

  const formik = useFormik({
    initialValues: { furtherInformation },
    validationSchema: yup.object({
      furtherInformation: yup.string(),
    }),
    onSubmit: () => undefined,
  })

  const persistUserInput = () => {
    dispatch(setFurtherInformation(formik.values.furtherInformation))
  }

  const handleOnPressNext = () => {
    persistUserInput()
    if (requestType === RequestType.INQUIRY && type === JobType.DESTRUCT) {
      navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.SUMMARY}`)
    } else {
      navigate(`${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.SUMMARY}`)
    }
    window.scrollTo(0, 0)
  }

  const handleOnPressBack = () => {
    persistUserInput()
    if (requestType === RequestType.INQUIRY && type === JobType.DESTRUCT) {
      navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.CONTACT_DETAILS}`)
    } else {
      navigate(`${ROUTE.INQUIRY.MAIN}/${ROUTE.INQUIRY.CONTACT_DETAILS}`)
    }
    window.scrollTo(0, 0)
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <TextGrid>
          <TitleGrid>
            <Typography variant="underlinedTileTitle">{t('inquiry.further_information.title')}</Typography>
          </TitleGrid>
        </TextGrid>
        <InputGrid>
          <StyledTextField
            inputProps={{ 'data-testid': 'inquiry.further_information.input' }}
            id="furtherInformation"
            name="furtherInformation"
            value={formik.values.furtherInformation}
            error={formik.touched.furtherInformation && Boolean(formik.errors.furtherInformation)}
            helperText={(formik.touched.furtherInformation && formik.errors.furtherInformation) ?? ' '}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            multiline
            rows={5}
          />
        </InputGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="inquiry.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        <StyledButton onClick={handleOnPressNext} data-testid="inquiry.next_button" variant="contained">
          {t('buttons.next')}
        </StyledButton>
      </ButtonGrid>
    </React.Fragment>
  )
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  flex: 0.5,
  alignItems: 'center',
  [theme.breakpoints.up('desktop')]: {
    paddingLeft: theme.layout.spacing._110,
    paddingTop: theme.layout.spacing._290,
    paddingRight: theme.layout.spacing._70,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingLeft: theme.layout.spacing._70,
    paddingTop: theme.layout.spacing._70,
  },
  [theme.breakpoints.down('tablet')]: {
    paddingLeft: theme.layout.spacing._30,
    paddingTop: theme.layout.spacing._30,
  },
}))

const TitleGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.layout.spacing._40,
  maxWidth: theme.layout.size._350,
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 0.5,
  [theme.breakpoints.up('desktop')]: {
    paddingRight: theme.layout.spacing._110,
    paddingTop: theme.layout.spacing._290,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingRight: theme.layout.spacing._70,
    paddingLeft: theme.layout.spacing._70,
    paddingTop: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('tablet')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._290,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
