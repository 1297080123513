import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearDestructionProtection,
  getDestructionProtection,
  getDestructionSubject,
  getDestructionType,
  setDestructionProtection,
} from '../../../store/order.slice'
import { DestructionProtection, DestructionSubject, DestructionType } from '../../../types'
import { Card } from './Card'

export const ProtectionOfDestruction = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionType = useSelector(getDestructionType)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionProtection = useSelector(getDestructionProtection)

  const SHOW_2_4 =
    _.isUndefined(destructionSubject) ||
    destructionSubject === DestructionSubject.FILES_PAPER ||
    destructionSubject === DestructionSubject.HDD ||
    destructionSubject === DestructionSubject.CD_DVD ||
    destructionSubject === DestructionSubject.FLOPPY_ID_MAGNETIC ||
    destructionSubject === DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR

  const SHOW_2_5 =
    _.isUndefined(destructionSubject) ||
    (destructionType === DestructionType.MOBILE_DESTRUCTION && destructionSubject === DestructionSubject.FILES_PAPER) ||
    destructionSubject === DestructionSubject.HDD

  const SHOW_3_4 =
    _.isUndefined(destructionSubject) ||
    destructionSubject === DestructionSubject.FILES_PAPER ||
    destructionSubject === DestructionSubject.HDD ||
    destructionSubject === DestructionSubject.CD_DVD ||
    destructionSubject === DestructionSubject.FLOPPY_ID_MAGNETIC ||
    destructionSubject === DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR

  const SHOW_3_5 =
    _.isUndefined(destructionSubject) ||
    (destructionType === DestructionType.MOBILE_DESTRUCTION && destructionSubject === DestructionSubject.FILES_PAPER) ||
    destructionSubject === DestructionSubject.HDD

  const handleProtectionSelection = (selectedProtection: DestructionProtection, isSelected: boolean) => {
    dispatch(clearDestructionProtection())
    if (isSelected && destructionProtection !== selectedProtection) {
      dispatch(setDestructionProtection(selectedProtection))
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">
        {t('order.order_informations.protection_of_destruction.title')}
      </Typography>
      <CardGrid>
        {SHOW_2_4 && (
          <Card
            testId="protection_of_destruction.2_4_card"
            title={t('order.order_informations.protection_of_destruction.2_4_title')}
            descriptionList={t('order.order_informations.protection_of_destruction.2_4_description')}
            onSelect={(selected) => handleProtectionSelection(DestructionProtection.PROTECTION_2_4, selected)}
            isSelected={destructionProtection === DestructionProtection.PROTECTION_2_4}
          />
        )}
        {SHOW_2_5 && (
          <Card
            testId="protection_of_destruction.2_5_card"
            title={t('order.order_informations.protection_of_destruction.2_5_title')}
            descriptionList={t('order.order_informations.protection_of_destruction.2_5_description')}
            onSelect={(selected) => handleProtectionSelection(DestructionProtection.PROTECTION_2_5, selected)}
            isSelected={destructionProtection === DestructionProtection.PROTECTION_2_5}
          />
        )}
        {SHOW_3_4 && (
          <Card
            testId="protection_of_destruction.3_4_card"
            title={t('order.order_informations.protection_of_destruction.3_4_title')}
            descriptionList={t('order.order_informations.protection_of_destruction.3_4_description')}
            onSelect={(selected) => handleProtectionSelection(DestructionProtection.PROTECTION_3_4, selected)}
            isSelected={destructionProtection === DestructionProtection.PROTECTION_3_4}
          />
        )}
        {SHOW_3_5 && (
          <Card
            testId="protection_of_destruction.3_5_card"
            title={t('order.order_informations.protection_of_destruction.3_5_title')}
            descriptionList={t('order.order_informations.protection_of_destruction.3_5_description')}
            onSelect={(selected) => handleProtectionSelection(DestructionProtection.PROTECTION_3_5, selected)}
            isSelected={destructionProtection === DestructionProtection.PROTECTION_3_5}
          />
        )}
      </CardGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}))
