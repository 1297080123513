import { useEffect, useState } from 'react'

import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getSelectedApproachTime, setSelectedEndTime, setSelectedStartTime } from '../../../store/order.slice'
import { CustomMenuProps } from '../../../theme'
import { END_TIMES, START_TIMES } from '../../../utils'

interface IProps {
  onError: (hasError: boolean) => void
}

export const ApproachTimeSelection = ({ onError }: IProps) => {
  const dispatch = useDispatch()
  const [approachTimeError, setApproachTimeError] = useState(null)
  const { t } = useTranslation()
  const { start: selectedStartTime, end: selectedEndTime } = useSelector(getSelectedApproachTime)
  const MINIMUM_TIME_FRAME_IN_HOURS = 8

  useEffect(() => {
    if (
      selectedStartTime &&
      selectedEndTime &&
      parseInt(selectedEndTime, 10) - parseInt(selectedStartTime, 10) < MINIMUM_TIME_FRAME_IN_HOURS
    ) {
      setApproachTimeError(t('order.meta_information.approach_time_error'))
      onError(true)
    } else {
      setApproachTimeError(null)
      onError(false)
    }
  }, [dispatch, selectedStartTime, selectedEndTime, t, onError])

  const handleStartTimeSelection = (event: SelectChangeEvent) => {
    dispatch(setSelectedStartTime(event.target.value))
  }

  const handleEndTimeSelection = (event: SelectChangeEvent) => {
    dispatch(setSelectedEndTime(event.target.value))
  }

  const renderStartPicker = () => (
    <StyledFormControl>
      <Select
        error={parseInt(selectedEndTime, 10) - parseInt(selectedStartTime, 10) < MINIMUM_TIME_FRAME_IN_HOURS}
        value={selectedStartTime}
        displayEmpty
        onChange={handleStartTimeSelection}
        renderValue={(selected) =>
          selected === '' ? t('order.meta_information.approach_time_placeholder_start') : selectedStartTime
        }
        MenuProps={CustomMenuProps}
        inputProps={{ 'data-testid': 'order.meta_information.approach_time_start' }}>
        {START_TIMES.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )

  const renderEndPicker = () => (
    <StyledFormControl>
      <Select
        error={parseInt(selectedEndTime, 10) - parseInt(selectedStartTime, 10) < MINIMUM_TIME_FRAME_IN_HOURS}
        value={selectedEndTime}
        displayEmpty
        onChange={handleEndTimeSelection}
        renderValue={(selected) =>
          selected === '' ? t('order.meta_information.approach_time_placeholder_end') : selectedEndTime
        }
        MenuProps={CustomMenuProps}
        inputProps={{ 'data-testid': 'order.meta_information.approach_time_emd' }}>
        {END_TIMES.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  )

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.meta_information.approach_time_title')}</Typography>
      <PickerGrid>
        {renderStartPicker()}
        <Divider>-</Divider>
        {renderEndPicker()}
      </PickerGrid>
      {approachTimeError && <ErrorMessage>{approachTimeError}</ErrorMessage>}
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginTop: theme.layout.spacing._40,
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}))

const PickerGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}))

const Divider = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flex: 0.1,
  marginTop: theme.layout.spacing._40,
  justifyContent: 'center',
  alignItems: 'center',
}))

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.color.error,
  marginTop: theme.layout.spacing._5,
}))
