import InfoIcon from '@mui/icons-material/Info'
import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getFurtherInformation, getIsBecomePermanentCustomerSelected } from '../../../store/order.slice'

export const FurtherInformationSummary = () => {
  const { t } = useTranslation()
  const isBecomePermanentCustomerSelected = useSelector(getIsBecomePermanentCustomerSelected)
  const furtherOrderInformation = useSelector(getFurtherInformation)

  return (
    <Grid>
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.contact_data">
          <TopGrid>
            <StyledInfoIcon />
            <StyledTextGrid>
              <Typography variant="tileText">
                {t('order.summary.specified_data.further_information_card.title')}
              </Typography>
              <StyledTitle data-testid="order.summary.further_information_card.sub_title_label" variant="tileTitle">
                {t('order.summary.specified_data.further_information_card.sub_title_label')}
              </StyledTitle>
            </StyledTextGrid>
          </TopGrid>
          <BottomGrid>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.further_information_card.permanent_customer_label')}
              </Typography>
              <Typography variant="pageTitle">
                {isBecomePermanentCustomerSelected
                  ? t('order.summary.specified_data.further_information_card.yes')
                  : t('order.summary.specified_data.further_information_card.no')}
              </Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.further_information_card.hint_label')}
              </Typography>
              <Typography variant="pageTitle">{furtherOrderInformation}</Typography>
            </Section>
          </BottomGrid>
        </StyledCardContent>
      </StyledCard>
    </Grid>
  )
}

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._50,
  },
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const BottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.layout.spacing._20,
    marginLeft: theme.layout.spacing._80,
  },
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._20,
}))
