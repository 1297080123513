import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  BecomePermanentCustomer,
  DestructionAmountUnit,
  DestructionOrderType,
  DestructionPlace,
  DestructionProtection,
  DestructionSubject,
  DestructionType,
  IBankData,
  IContactPersonData,
  IContainerInfos,
  IContainerPricesResponse,
  IInvoiceData,
  PaymentMethod,
} from '../types'

interface ICommonState {
  destructionType?: DestructionType
  subject?: DestructionSubject
  amount: string
  amountUnit?: DestructionAmountUnit
  protectionClass?: DestructionProtection
  placeOfData?: DestructionPlace
  orderType?: DestructionOrderType
  suggestedContainers?: IContainerInfos[]
  selectedContainer?: IContainerInfos
  containerPrices?: IContainerPricesResponse[]
  availablePickupDates: string[]
  selectedPickupDate: string
  selectedApproachStartTime: string
  selectedApproachEndTime: string
  invoiceData: IInvoiceData
  invoiceIsDeliveryAddress: boolean
  contactPersonData: IContactPersonData
  isContactPersonOnSite: boolean
  selectedPaymentMethod?: PaymentMethod
  bankData: IBankData
  sepaConsent: boolean
  becomePermanentCustomerSelected?: BecomePermanentCustomer
  furtherInformation: string
  agbConsent: boolean
}

const initialState: ICommonState = {
  amount: '',
  availablePickupDates: [],
  selectedPickupDate: '',
  selectedApproachStartTime: '',
  selectedApproachEndTime: '',
  invoiceData: {
    city: '',
    email: '',
    name: '',
    street: '',
    streetNumber: '',
    zip: '',
  },
  invoiceIsDeliveryAddress: false,
  contactPersonData: {
    givenname: '',
    surname: '',
    email: '',
    phone: '',
  },
  isContactPersonOnSite: false,
  bankData: {
    givenname: '',
    surname: '',
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    bankName: '',
    bankBic: '',
    bankIban: '',
  },
  sepaConsent: false,
  furtherInformation: '',
  agbConsent: false,
}

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setDestructionType: (state, { payload: destructionType }: PayloadAction<DestructionType>) => {
      state.destructionType = destructionType
    },
    clearDestructionType: (state) => {
      state.destructionType = undefined
    },
    setDestructionSubject: (state, { payload: subject }: PayloadAction<DestructionSubject>) => {
      state.subject = subject
    },
    clearDestructionSubject: (state) => {
      state.subject = undefined
    },
    setDestructionAmount: (state, { payload: amount }: PayloadAction<string>) => {
      state.amount = amount
    },
    clearDestructionAmount: (state) => {
      state.amount = ''
    },
    setDestructionAmountUnit: (state, { payload: amountUnit }: PayloadAction<DestructionAmountUnit>) => {
      state.amountUnit = amountUnit
    },
    clearDestructionAmountUnit: (state) => {
      state.amountUnit = undefined
    },
    setDestructionProtection: (state, { payload: protectionClass }: PayloadAction<DestructionProtection>) => {
      state.protectionClass = protectionClass
    },
    clearDestructionProtection: (state) => {
      state.protectionClass = undefined
    },
    setDestructionPlace: (state, { payload: placeOfData }: PayloadAction<DestructionPlace>) => {
      state.placeOfData = placeOfData
    },
    clearDestructionPlace: (state) => {
      state.placeOfData = undefined
    },
    setDestructionOrderType: (state, { payload: orderType }: PayloadAction<DestructionOrderType>) => {
      state.orderType = orderType
    },
    clearDestructionOrderType: (state) => {
      state.orderType = undefined
    },
    setSuggestedContainers: (state, { payload: suggestedContainers }: PayloadAction<IContainerInfos[]>) => {
      state.suggestedContainers = suggestedContainers
    },
    clearSuggestedContainers: (state) => {
      state.suggestedContainers = undefined
    },
    setSelectedContainer: (state, { payload: selectedContainer }: PayloadAction<IContainerInfos>) => {
      state.selectedContainer = selectedContainer
    },
    clearSelectedContainer: (state) => {
      state.selectedContainer = undefined
    },
    setContainerPrices: (state, { payload: containerPrices }: PayloadAction<IContainerPricesResponse[]>) => {
      state.containerPrices = containerPrices
    },
    clearContainerPrices: (state) => {
      state.containerPrices = undefined
    },
    setAvailablePickupDates: (state, { payload: availablePickupDates }: PayloadAction<string[]>) => {
      state.availablePickupDates = availablePickupDates
    },
    clearAvailablePickupDates: (state) => {
      state.availablePickupDates = []
    },
    setSelectedPickupDate: (state, { payload: selectedPickupDate }: PayloadAction<string>) => {
      state.selectedPickupDate = selectedPickupDate
    },
    clearSelectedPickupDate: (state) => {
      state.selectedPickupDate = ''
    },
    setSelectedStartTime: (state, { payload: start }: PayloadAction<string>) => {
      state.selectedApproachStartTime = start
    },
    clearSelectedStartTime: (state) => {
      state.selectedApproachStartTime = ''
    },
    setSelectedEndTime: (state, { payload: end }: PayloadAction<string>) => {
      state.selectedApproachEndTime = end
    },
    clearSelectedEndTime: (state) => {
      state.selectedApproachEndTime = ''
    },
    setInvoiceNameData: (state, { payload: name }: PayloadAction<string>) => {
      state.invoiceData.name = name
    },
    clearInvoiceNameData: (state) => {
      state.invoiceData.name = ''
    },
    setInvoiceStreetData: (state, { payload: street }: PayloadAction<string>) => {
      state.invoiceData.street = street
    },
    clearInvoiceStreetData: (state) => {
      state.invoiceData.street = ''
    },
    setInvoiceStreetNumberData: (state, { payload: streetNumber }: PayloadAction<string>) => {
      state.invoiceData.streetNumber = streetNumber
    },
    clearInvoiceStreetNumberData: (state) => {
      state.invoiceData.streetNumber = ''
    },
    setInvoiceZipData: (state, { payload: zip }: PayloadAction<string>) => {
      state.invoiceData.zip = zip
    },
    clearInvoiceZipData: (state) => {
      state.invoiceData.zip = ''
    },
    setInvoiceCityData: (state, { payload: city }: PayloadAction<string>) => {
      state.invoiceData.city = city
    },
    clearInvoiceCityData: (state) => {
      state.invoiceData.city = ''
    },
    setInvoiceEmailData: (state, { payload: email }: PayloadAction<string>) => {
      state.invoiceData.email = email
    },
    clearInvoiceEmailData: (state) => {
      state.invoiceData.email = ''
    },
    setInvoiceIsDeliveryAddress: (state, { payload: invoiceIsDeliveryAddress }: PayloadAction<boolean>) => {
      state.invoiceIsDeliveryAddress = invoiceIsDeliveryAddress
    },
    setContactPersonDataGivenname: (state, { payload: givenname }: PayloadAction<string>) => {
      state.contactPersonData.givenname = givenname
    },
    setContactPersonDataSurname: (state, { payload: surname }: PayloadAction<string>) => {
      state.contactPersonData.surname = surname
    },
    setContactPersonDataEmail: (state, { payload: email }: PayloadAction<string>) => {
      state.contactPersonData.email = email
    },
    setContactPersonDataPhone: (state, { payload: phone }: PayloadAction<string>) => {
      state.contactPersonData.phone = phone
    },
    setIsContactPersonOnSite: (state, { payload: isContactPersonOnSite }: PayloadAction<boolean>) => {
      state.isContactPersonOnSite = isContactPersonOnSite
    },
    setSelectedPaymentMethod: (state, { payload: selectedPaymentMethod }: PayloadAction<PaymentMethod>) => {
      state.selectedPaymentMethod = selectedPaymentMethod
    },
    clearSelectedPaymentMethod: (state) => {
      state.selectedPaymentMethod = undefined
    },
    setBankDataGivenname: (state, { payload: givenname }: PayloadAction<string>) => {
      state.bankData.givenname = givenname
    },
    setBankDataSurname: (state, { payload: surname }: PayloadAction<string>) => {
      state.bankData.surname = surname
    },
    setBankDataStreet: (state, { payload: street }: PayloadAction<string>) => {
      state.bankData.street = street
    },
    setBankDataStreetNumber: (state, { payload: streetNumber }: PayloadAction<string>) => {
      state.bankData.streetNumber = streetNumber
    },
    setBankDataZip: (state, { payload: zip }: PayloadAction<string>) => {
      state.bankData.zip = zip
    },
    setBankDataCity: (state, { payload: city }: PayloadAction<string>) => {
      state.bankData.city = city
    },
    setBankDataBankName: (state, { payload: bankName }: PayloadAction<string>) => {
      state.bankData.bankName = bankName
    },
    setBankDataBankBic: (state, { payload: bankBic }: PayloadAction<string>) => {
      state.bankData.bankBic = bankBic
    },
    setBankDataBankIban: (state, { payload: bankIban }: PayloadAction<string>) => {
      state.bankData.bankIban = bankIban
    },
    setSepaConsent: (state, { payload: sepaConsent }: PayloadAction<boolean>) => {
      state.sepaConsent = sepaConsent
    },
    setIsBecomePermanentCustomerSelected: (
      state,
      { payload: becomePermanentCustomerSelected }: PayloadAction<BecomePermanentCustomer>,
    ) => {
      state.becomePermanentCustomerSelected = becomePermanentCustomerSelected
    },
    clearIsBecomePermanentCustomerSelected: (state) => {
      state.becomePermanentCustomerSelected = undefined
    },
    setFurtherInformation: (state, { payload: furtherInformation }: PayloadAction<string>) => {
      state.furtherInformation = furtherInformation
    },
    setAgbConsent: (state, { payload: agbConsent }: PayloadAction<boolean>) => {
      state.agbConsent = agbConsent
    },
    reset: () => initialState,
  },
})

export const {
  setDestructionType,
  clearDestructionType,
  setDestructionSubject,
  clearDestructionSubject,
  setDestructionAmount,
  clearDestructionAmount,
  setDestructionAmountUnit,
  clearDestructionAmountUnit,
  setDestructionProtection,
  clearDestructionProtection,
  setDestructionPlace,
  clearDestructionPlace,
  setDestructionOrderType,
  clearDestructionOrderType,
  setSuggestedContainers,
  clearSuggestedContainers,
  setSelectedContainer,
  clearSelectedContainer,
  setContainerPrices,
  clearContainerPrices,
  setAvailablePickupDates,
  clearAvailablePickupDates,
  setSelectedPickupDate,
  clearSelectedPickupDate,
  setSelectedStartTime,
  clearSelectedStartTime,
  setSelectedEndTime,
  clearSelectedEndTime,
  setInvoiceNameData,
  clearInvoiceNameData,
  setInvoiceStreetData,
  clearInvoiceStreetData,
  setInvoiceStreetNumberData,
  setInvoiceZipData,
  clearInvoiceZipData,
  setInvoiceCityData,
  clearInvoiceEmailData,
  setInvoiceEmailData,
  clearInvoiceCityData,
  setInvoiceIsDeliveryAddress,
  clearInvoiceStreetNumberData,
  setContactPersonDataGivenname,
  setContactPersonDataSurname,
  setContactPersonDataEmail,
  setContactPersonDataPhone,
  setIsContactPersonOnSite,
  setSelectedPaymentMethod,
  clearSelectedPaymentMethod,
  setBankDataGivenname,
  setBankDataSurname,
  setBankDataStreet,
  setBankDataStreetNumber,
  setBankDataZip,
  setBankDataCity,
  setBankDataBankName,
  setBankDataBankBic,
  setBankDataBankIban,
  setSepaConsent,
  setIsBecomePermanentCustomerSelected,
  clearIsBecomePermanentCustomerSelected,
  setFurtherInformation,
  setAgbConsent,
  reset,
} = slice.actions

export const getDestructionType = (state: { [slice.name]: ICommonState }) => state[slice.name].destructionType
export const getDestructionSubject = (state: { [slice.name]: ICommonState }) => state[slice.name].subject
export const getDestructionAmount = (state: { [slice.name]: ICommonState }) => state[slice.name].amount
export const getDestructionAmountUnit = (state: { [slice.name]: ICommonState }) => state[slice.name].amountUnit
export const getDestructionProtection = (state: { [slice.name]: ICommonState }) => state[slice.name].protectionClass
export const getDestructionPlace = (state: { [slice.name]: ICommonState }) => state[slice.name].placeOfData
export const getDestructionOrderType = (state: { [slice.name]: ICommonState }) => state[slice.name].orderType
export const getSuggestedContainers = (state: { [slice.name]: ICommonState }) => state[slice.name].suggestedContainers
export const getSelectedContainer = (state: { [slice.name]: ICommonState }) => state[slice.name].selectedContainer
export const getContainerPrices = (state: { [slice.name]: ICommonState }) => state[slice.name].containerPrices
export const getAvailablePickupDates = (state: { [slice.name]: ICommonState }) => state[slice.name].availablePickupDates
export const getSelectedPickupDate = (state: { [slice.name]: ICommonState }) => state[slice.name].selectedPickupDate
export const getSelectedApproachTime = (state: { [slice.name]: ICommonState }) => ({
  start: state[slice.name].selectedApproachStartTime,
  end: state[slice.name].selectedApproachEndTime,
})
export const getInvoiceData = (state: { [slice.name]: ICommonState }) => state[slice.name].invoiceData
export const getInvoiceIsDeliveryAddress = (state: { [slice.name]: ICommonState }) =>
  state[slice.name].invoiceIsDeliveryAddress
export const getContactPersonData = (state: { [slice.name]: ICommonState }) => state[slice.name].contactPersonData
export const getIsContactPersonOnSite = (state: { [slice.name]: ICommonState }) =>
  state[slice.name].isContactPersonOnSite
export const getSelectedPaymentMethod = (state: { [slice.name]: ICommonState }) =>
  state[slice.name].selectedPaymentMethod
export const getBankData = (state: { [slice.name]: ICommonState }) => state[slice.name].bankData
export const getSepaConsent = (state: { [slice.name]: ICommonState }) => state[slice.name].sepaConsent
export const getIsBecomePermanentCustomerSelected = (state: { [slice.name]: ICommonState }) =>
  state[slice.name].becomePermanentCustomerSelected
export const getFurtherInformation = (state: { [slice.name]: ICommonState }) => state[slice.name].furtherInformation
export const getAgbConsent = (state: { [slice.name]: ICommonState }) => state[slice.name].agbConsent

export default slice.reducer
