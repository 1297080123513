import _ from 'lodash'

export const containsEmptyString = (values: string[]): boolean => {
  return values.some((v) => v === '')
}

export const currencyNumber = (value?: number) => {
  if (!_.isUndefined(value)) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(value)
  } else {
    return '--- €'
  }
}

export * from './container-helper'
export * from './order-helper'
