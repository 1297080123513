import React, { useEffect } from 'react'

import { Button, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useFormik } from 'formik'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import ROUTE from '../../../route'
import { setActiveStep, setRequestType } from '../../../store/common.slice'
import {
  getDestructionAmount,
  getDestructionOrderType,
  getDestructionPlace,
  getDestructionProtection,
  getDestructionSubject,
  getDestructionType,
  setDestructionAmount,
  setSuggestedContainers,
} from '../../../store/order.slice'
import { DestructionProtection, DestructionSubject, DestructionType, RequestType } from '../../../types'
import { getContainerInfos } from '../../../utils'
import {
  AmountOfDestruction,
  OrderTypeOfDestruction,
  PlaceOfDestruction,
  ProtectionOfDestruction,
  SubjectOfDestruction,
  TypeOfDestruction,
} from '../components'

export const OrderInformation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionType = useSelector(getDestructionType)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionAmount = useSelector(getDestructionAmount)
  const destructionProtection = useSelector(getDestructionProtection)
  const destructionPlace = useSelector(getDestructionPlace)
  const destructionOrderType = useSelector(getDestructionOrderType)

  useEffect(() => {
    dispatch(setActiveStep(1))
  }, [dispatch])

  const formik = useFormik<{ destructionAmount: string }>({
    initialValues: { destructionAmount: destructionAmount ?? '' },
    validationSchema: yup.object({
      destructionAmount:
        destructionSubject === DestructionSubject.FILES_PAPER || destructionSubject === DestructionSubject.HDD
          ? yup.string().required(t('order.order_informations.amount_of_destruction.input.amount_error'))
          : yup.string(),
    }),
    onSubmit: () => undefined,
  })

  const isInquiry = () => {
    const INQUIRY_CONDITION_1 =
      parseInt(formik.values.destructionAmount, 10) > 120 && destructionSubject === DestructionSubject.FILES_PAPER
    const INQUIRY_CONDITION_2 =
      destructionProtection === DestructionProtection.PROTECTION_2_5 ||
      destructionProtection === DestructionProtection.PROTECTION_3_5
    const INQUIRY_CONDITION_3 = destructionType === DestructionType.MOBILE_DESTRUCTION
    const INQUIRY_CONDITION_4 =
      parseInt(formik.values.destructionAmount, 10) > 250 && destructionSubject === DestructionSubject.HDD

    return INQUIRY_CONDITION_1 || INQUIRY_CONDITION_2 || INQUIRY_CONDITION_3 || INQUIRY_CONDITION_4
  }

  const persistUserInput = () => {
    formik.values.destructionAmount && dispatch(setDestructionAmount(formik.values.destructionAmount))
  }

  const handleOnPressNext = () => {
    if (isInquiry()) {
      dispatch(setRequestType(RequestType.INQUIRY))
    } else {
      dispatch(setRequestType(RequestType.ORDER))
      if (
        !_.isUndefined(destructionSubject) &&
        !_.isUndefined(formik.values.destructionAmount) &&
        !_.isUndefined(destructionPlace)
      ) {
        const containerInfos = getContainerInfos(
          destructionSubject,
          parseInt(formik.values.destructionAmount, 10),
          destructionPlace,
        )
        dispatch(setSuggestedContainers(containerInfos))
      }
    }
    navigate(`${ROUTE.ORDER.MAIN}/${ROUTE.ORDER.CONTACT_DETAILS}`)
    persistUserInput()
    window.scrollTo(0, 0)
  }

  const handleOnPressBack = () => {
    persistUserInput()
    navigate(ROUTE.HOME)
    window.scrollTo(0, 0)
  }

  const getIsNextButtonDisabled = () => {
    const needsAmountInput =
      destructionSubject === DestructionSubject.FILES_PAPER || destructionSubject === DestructionSubject.HDD
    const missingAmountInput = needsAmountInput && formik.values.destructionAmount.length < 1

    return (
      missingAmountInput ||
      _.isUndefined(destructionType) ||
      _.isUndefined(destructionSubject) ||
      _.isUndefined(destructionPlace) ||
      _.isUndefined(destructionOrderType)
    )
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <TypeOfDestruction />
        <SubjectOfDestruction formik={formik} />
        <AmountOfDestruction formik={formik} />
        <ProtectionOfDestruction />
        <PlaceOfDestruction />
        <OrderTypeOfDestruction />
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressBack} data-testid="order.back_button" variant="outlined">
          {t('buttons.back')}
        </StyledButton>
        <StyledButton
          onClick={handleOnPressNext}
          data-testid="order.next_button"
          disabled={getIsNextButtonDisabled()}
          variant="contained">
          {t('buttons.next')}
        </StyledButton>
      </ButtonGrid>
    </React.Fragment>
  )
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  paddingLeft: theme.layout.spacing._80,
  paddingTop: theme.layout.spacing._110,
  paddingRight: theme.layout.spacing._80,
  [theme.breakpoints.down('mobile')]: {
    paddingLeft: theme.layout.spacing._20,
    paddingTop: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._20,
  },
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._110,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._60,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
