import { useEffect } from 'react'

import { Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearDestructionAmount,
  clearDestructionAmountUnit,
  getDestructionSubject,
  setDestructionAmountUnit,
} from '../../../store/order.slice'
import { DestructionAmountUnit, DestructionSubject } from '../../../types'

interface IProps {
  formik: FormikProps<{ destructionAmount: string }>
}

export const AmountOfDestruction = ({ formik }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionSubject = useSelector(getDestructionSubject)

  useEffect(() => {
    switch (destructionSubject) {
      case DestructionSubject.FILES_PAPER:
        dispatch(setDestructionAmountUnit(DestructionAmountUnit.FOLDER))
        break

      case DestructionSubject.HDD:
        dispatch(setDestructionAmountUnit(DestructionAmountUnit.HDD))
        break

      default:
        dispatch(clearDestructionAmountUnit())
        dispatch(clearDestructionAmount())
        break
    }
  }, [destructionSubject, dispatch])

  if (destructionSubject === DestructionSubject.FILES_PAPER || destructionSubject === DestructionSubject.HDD) {
    return (
      <SectionGrid>
        <Typography variant="underlinedTileTitle">
          {t('order.order_informations.amount_of_destruction.title')}
        </Typography>
        <InputGrid>
          <StyledTextField
            inputProps={{ 'data-testid': 'amount_of_destruction.input' }}
            placeholder={t('inquiry.amount_selection.input_placeholder').toUpperCase()}
            id="destructionAmount"
            name="destructionAmount"
            value={formik.values.destructionAmount}
            required={
              destructionSubject === DestructionSubject.FILES_PAPER || destructionSubject === DestructionSubject.HDD
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.destructionAmount && Boolean(formik.errors.destructionAmount)}
            helperText={(formik.touched.destructionAmount && formik.errors.destructionAmount) ?? ' '}
            type="number"
          />
          {destructionSubject === DestructionSubject.FILES_PAPER && (
            <StyledAmountLabel variant="hintText" data-testid="amount_of_destruction.input.folder_label">
              {t('order.order_informations.amount_of_destruction.input.folder_label')}
            </StyledAmountLabel>
          )}
          {destructionSubject === DestructionSubject.HDD && (
            <StyledAmountLabel variant="hintText" data-testid="amount_of_destruction.input.hdd_label">
              {t('order.order_informations.amount_of_destruction.input.hdd_label')}
            </StyledAmountLabel>
          )}
        </InputGrid>
      </SectionGrid>
    )
  } else {
    return null
  }
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.up('desktop')]: {
    marginTop: theme.layout.spacing._50,
    marginRight: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._30,
    marginRight: theme.layout.spacing._10,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.6,
  [theme.breakpoints.down('desktop')]: {
    flex: 0.9,
  },
}))

const StyledAmountLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flex: 0.4,
  marginBottom: theme.layout.spacing._20,
  marginLeft: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    flex: 0.1,
  },
}))
