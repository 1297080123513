import { ContainerType, DestructionPlace, DestructionSubject, IContainerInfos } from '../types'

export const getContainerInfos = (
  subject: DestructionSubject,
  amount: number,
  floor: DestructionPlace,
): IContainerInfos[] => {
  const CONTAINER_MAX_FILLING = {
    HDD: {
      DC_70: 90,
      DC_240: 250,
    },
    FILES_PAPER: {
      DC_70: 10,
      DC_240: 30,
      DC_350: 50,
    },
  }
  const CONTAINER_MAX_COUNT = 4
  const containerInfo: IContainerInfos[] = []

  if (subject === DestructionSubject.FILES_PAPER) {
    if (floor === DestructionPlace.OG_NO_LIFT || floor === DestructionPlace.UG_NO_LIFT) {
      const suggestedCountDC70 = Math.ceil(amount / CONTAINER_MAX_FILLING.FILES_PAPER.DC_70)
      if (suggestedCountDC70 <= CONTAINER_MAX_COUNT) {
        containerInfo.push({ containerType: ContainerType.DC_70, amount: suggestedCountDC70 })
      }

      const suggestedCountDC240 = Math.ceil(amount / CONTAINER_MAX_FILLING.FILES_PAPER.DC_240)
      if (suggestedCountDC240 <= CONTAINER_MAX_COUNT) {
        containerInfo.push({ containerType: ContainerType.DC_240, amount: suggestedCountDC240 })
      }

      return containerInfo
    } else {
      const suggestedCountDC70 = Math.ceil(amount / CONTAINER_MAX_FILLING.FILES_PAPER.DC_70)
      if (suggestedCountDC70 <= CONTAINER_MAX_COUNT) {
        containerInfo.push({ containerType: ContainerType.DC_70, amount: suggestedCountDC70 })
      }

      const suggestedCountDC240 = Math.ceil(amount / CONTAINER_MAX_FILLING.FILES_PAPER.DC_240)
      if (suggestedCountDC240 <= CONTAINER_MAX_COUNT) {
        containerInfo.push({ containerType: ContainerType.DC_240, amount: suggestedCountDC240 })
      }

      const suggestedCountDC350 = Math.ceil(amount / CONTAINER_MAX_FILLING.FILES_PAPER.DC_350)
      containerInfo.push({ containerType: ContainerType.DC_350, amount: suggestedCountDC350 })

      return containerInfo
    }
  } else if (subject === DestructionSubject.HDD) {
    const suggestedCountDC70 = Math.ceil(amount / CONTAINER_MAX_FILLING.HDD.DC_70)
    if (suggestedCountDC70 <= CONTAINER_MAX_COUNT) {
      containerInfo.push({ containerType: ContainerType.DC_70, amount: suggestedCountDC70 })
    }

    const suggestedCountDC240 = Math.ceil(amount / CONTAINER_MAX_FILLING.HDD.DC_240)
    if (suggestedCountDC240 <= CONTAINER_MAX_COUNT) {
      containerInfo.push({ containerType: ContainerType.DC_240, amount: suggestedCountDC240 })
    }

    return containerInfo
  } else {
    containerInfo.push({ containerType: ContainerType.DC_70, amount: 1 })
    containerInfo.push({ containerType: ContainerType.DC_240, amount: 1 })

    return containerInfo
  }
}
