import React from 'react'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCompanyDeliveryAddress, getContactData } from '../../../store/inquiry.slice'
import { getInvoiceData, getSelectedApproachTime } from '../../../store/order.slice'

export const DeliveryInvoiceSummary = () => {
  const { t } = useTranslation()
  const {
    name: deliveryName,
    street: deliveryStreetName,
    number: deliveryStreetNumber,
    zip: deliveryZip,
    city: deliveryCity,
    isPrivatePerson,
  } = useSelector(getCompanyDeliveryAddress)
  const { givenname, surname } = useSelector(getContactData)
  const selectedApproachTime = useSelector(getSelectedApproachTime)
  const {
    name: invoiceName,
    street: invoiceStreetName,
    streetNumber: invoiceStreetNumber,
    zip: invoiceZip,
    city: invoiceCity,
  } = useSelector(getInvoiceData)

  const renderTopGrid = (title: string, name: string) => {
    return (
      <TopGrid>
        <StyledLocationIcon />
        <StyledTextGrid>
          <Typography variant="tileText">{title}</Typography>
          <Typography variant="tileTitle">{name}</Typography>
        </StyledTextGrid>
      </TopGrid>
    )
  }

  const getDeliveryAddressText = () => {
    return (
      <DeliveryStreetNumberGrid>
        <Typography variant="pageTitle">{`${deliveryStreetName} ${deliveryStreetNumber}`}</Typography>
        <Typography variant="pageTitle">{`${deliveryZip} ${deliveryCity}`}</Typography>
      </DeliveryStreetNumberGrid>
    )
  }
  const getInvoiceAddressText = () => {
    return (
      <DeliveryStreetNumberGrid>
        <Typography variant="pageTitle">{`${invoiceStreetName} ${invoiceStreetNumber}`}</Typography>
        <Typography variant="pageTitle">{`${invoiceZip} ${invoiceCity}`}</Typography>
      </DeliveryStreetNumberGrid>
    )
  }

  const getApproachText = () => {
    return `${selectedApproachTime.start} - ${selectedApproachTime.end} ${t(
      'order.summary.specified_data.delivery_address_card.clock_label',
    )}`
  }

  const renderDeliveryAddressCard = () => {
    return (
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.delivery_invoice_address.delivery_card">
          {renderTopGrid(
            t('order.summary.specified_data.delivery_address_card.title'),
            isPrivatePerson ? `${givenname} ${surname}` : deliveryName,
          )}
          <VerticalDataGrid>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.delivery_address_card.address_label')}
              </Typography>
              {getDeliveryAddressText()}
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.delivery_address_card.approach_time_label')}
              </Typography>
              <Typography variant="pageTitle">{getApproachText()}</Typography>
            </Section>
          </VerticalDataGrid>
        </StyledCardContent>
      </StyledCard>
    )
  }

  const renderInvoiceAddressCard = () => {
    return (
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.delivery_invoice_address.invoice_card">
          {renderTopGrid(t('order.summary.specified_data.invoice_address_card.title'), invoiceName)}
          <VerticalDataGrid>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.specified_data.invoice_address_card.address_label')}
              </Typography>
              {getInvoiceAddressText()}
            </Section>
          </VerticalDataGrid>
        </StyledCardContent>
      </StyledCard>
    )
  }

  return (
    <SectionGrid>
      <Section>
        <CardGrid>
          {renderDeliveryAddressCard()}
          {renderInvoiceAddressCard()}
        </CardGrid>
      </Section>
    </SectionGrid>
  )
}
const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._40,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  flex: 1,
  [theme.breakpoints.up('desktop')]: {
    '&:last-child': {
      marginLeft: 40,
    },
  },
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
    '&:last-child': {
      paddingBottom: theme.layout.spacing._40,
    },
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.layout.spacing._20,
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledLocationIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._30,
  },
}))

const DeliveryStreetNumberGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._20,
}))

const VerticalDataGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
  },
}))
