export enum DestructionType {
  MOBILE_DESTRUCTION = 'MOBILE_DESTRUCTION',
  STATIONARY_DESTRUCTION = 'STATIONARY_DESTRUCTION',
}

export enum DestructionSubject {
  FILES_PAPER = 'FILES_PAPER',
  HDD = 'HDD',
  CD_DVD = 'CD_DVD',
  FLOPPY_ID_MAGNETIC = 'FLOPPY_ID_MAGNETIC',
  MEMORY_CHIP_SEMICONDUCTOR = 'MEMORY_CHIP_SEMICONDUCTOR',
}

export enum DestructionAmountUnit {
  FOLDER = 'FOLDER',
  HDD = 'HDD',
}

export enum DestructionProtection {
  PROTECTION_2_4 = '2_4',
  PROTECTION_2_5 = '2_5',
  PROTECTION_3_4 = '3_4',
  PROTECTION_3_5 = '3_5',
}

export enum DestructionPlace {
  EG_BF = 'EG_BF',
  UG_LIFT = 'UG_LIFT',
  OG_LIFT = 'OG_LIFT',
  UG_NO_LIFT = 'UG_NO_LIFT',
  OG_NO_LIFT = 'OG_NO_LIFT',
}

export enum DestructionOrderType {
  PERMANENT = 'PERMANENT',
  SHORT_TERM = 'SHORT_TERM',
  AT_ONCE = 'AT_ONCE',
}

export enum ContainerType {
  DC_70 = 'DC_70',
  DC_240 = 'DC_240',
  DC_350 = 'DC_350',
}

export interface IContainerInfos {
  containerType: ContainerType
  amount: number
}

export interface IContactPersonData {
  givenname: string
  surname: string
  email: string
  phone: string
}

export interface IInvoiceData {
  name: string
  street: string
  streetNumber: string
  zip: string
  city: string
  email: string
}
export interface IBankData {
  givenname: string
  surname: string
  street: string
  streetNumber: string
  zip: string
  city: string
  bankName: string
  bankBic: string
  bankIban: string
}

export enum PaymentMethod {
  SEPA = 'DEBITNOTE',
  INVOICE = 'INVOICE',
}

export enum BecomePermanentCustomer {
  YES = 1,
  NO = 0,
}
