import React, { useEffect } from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ERROR_ICON, SUCCESS_ICON } from '../../../assets'
import ROUTE from '../../../route'
import { getHasApiError, reset as resetApiStore } from '../../../store/api.slice'
import { reset as resetCommonStore, setActiveStep } from '../../../store/common.slice'
import { reset as resetInquiryStore } from '../../../store/inquiry.slice'
import { reset as resetOrderStore } from '../../../store/order.slice'

export const OrderSent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const hasApiError = useSelector(getHasApiError)

  useEffect(() => {
    dispatch(setActiveStep(4)) // stay at step 4
  }, [dispatch])

  const handleOnPressHome = async () => {
    dispatch(resetApiStore())
    dispatch(resetCommonStore())
    dispatch(resetInquiryStore())
    dispatch(resetOrderStore())
    navigate(ROUTE.HOME)
  }

  const handleOnPressRestart = () => {
    navigate(ROUTE.HOME)
  }

  const iconSource = () => {
    return hasApiError ? ERROR_ICON : SUCCESS_ICON
  }

  return (
    <React.Fragment>
      <ContentGrid>
        <IconGrid>
          <StyledIcon src={iconSource()} />
        </IconGrid>
        <TextGrid>
          {hasApiError ? (
            <StyledTitle data-testid="order.sent_result.error.title" variant="hintTitle">
              {t('order.sent_result.error.title')}
            </StyledTitle>
          ) : (
            <StyledTitle data-testid="order.sent_result.success.title" variant="hintTitle">
              {t('order.sent_result.success.title')}
            </StyledTitle>
          )}
          {hasApiError ? (
            <Typography>{t('order.sent_result.error.description')}</Typography>
          ) : (
            <Typography>{t('order.sent_result.success.description')}</Typography>
          )}
        </TextGrid>
      </ContentGrid>
      <ButtonGrid>
        <StyledButton onClick={handleOnPressHome} data-testid="order.back_home_button" variant="contained">
          {t('buttons.back_home')}
        </StyledButton>
        {!hasApiError && (
          <StyledButton onClick={handleOnPressRestart} data-testid="order.restart_btn" variant="outlined">
            {t('order.sent_result.restart_btn')}
          </StyledButton>
        )}
      </ButtonGrid>
    </React.Fragment>
  )
}

const ContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  marginTop: theme.layout.spacing._110,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

const IconGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const StyledIcon = styled('img')(({ theme }) => ({
  width: theme.layout.size._350,
  [theme.breakpoints.down('mobile')]: {
    width: theme.layout.size._200,
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up('tablet')]: {
    marginLeft: theme.layout.spacing._110,
    marginRight: theme.layout.spacing._110,
  },
  [theme.breakpoints.down('tablet')]: {
    marginLeft: theme.layout.spacing._60,
    marginRight: theme.layout.spacing._60,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._290,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._110,
    paddingLeft: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    margin: theme.layout.spacing._20,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))
