import { Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { LOGO } from '../assets/images'
import ROUTE from '../route'
import { getActiveStep, getType } from '../store/common.slice'
import { CustomStepper } from './CustomStepper'
import { ServiceType } from './ServiceType'

interface IProps {
  showStepper?: boolean
}

export const Header = ({ showStepper }: IProps) => {
  const navigate = useNavigate()
  const currentStep = useSelector(getActiveStep)
  const serviceType = useSelector(getType)

  const onPressLogo = () => {
    navigate(ROUTE.HOME)
  }

  return (
    <HeaderGrid container item>
      <Logo src={LOGO} onClick={onPressLogo} />
      {serviceType && showStepper && currentStep > 0 && (
        <FloatingHeader>
          <ServiceType serviceType={serviceType} />
          <StepperGrid>
            <CustomStepper activeStep={currentStep} />
          </StepperGrid>
        </FloatingHeader>
      )}
    </HeaderGrid>
  )
}

const HeaderGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    height: theme.layout.size.header_mobile,
  },
  [theme.breakpoints.up('tablet')]: {
    height: theme.layout.size.header,
  },
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.color.white,
  borderBottom: `1px solid ${theme.color.secondary.lightGray}`,
  zIndex: 2,
}))

const Logo = styled('img')(({ theme }) => ({
  display: 'flex',
  ':hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('mobile')]: {
    marginLeft: theme.layout.spacing._20,
    width: theme.layout.size.header_mobile,
  },
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
    width: theme.layout.size.header,
  },
  [theme.breakpoints.up('tablet')]: {
    marginLeft: theme.layout.spacing._80,
    width: theme.layout.size.header,
  },
}))

const FloatingHeader = styled(Grid)(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  right: theme.layout.spacing._10,
  [theme.breakpoints.up('mobile')]: {
    right: theme.layout.spacing._80,
  },
}))

const StepperGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}))
