import React, { useEffect } from 'react'

import { Button, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { Helmet } from 'react-helmet-async'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { AppContainer } from '../../components'
import ROUTE from '../../route'
import { clearType, getType, setActiveStep, setType } from '../../store/common.slice'
import { JobType } from '../../types'
import { Card } from './components'

export const HomeView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const type = useSelector(getType)
  const dispatch = useDispatch()

  useEffect(() => {
    navigate(ROUTE.HOME)
    dispatch(setActiveStep(0))
  }, [dispatch, navigate])

  const handleSelection = (selectedType: JobType, isSelected: boolean) => {
    dispatch(clearType())
    if (isSelected && type !== selectedType) dispatch(setType(selectedType))
  }

  const handleOnPressNext = () => {
    type === JobType.DESTRUCT ? navigate(ROUTE.ORDER.MAIN) : navigate(ROUTE.INQUIRY.MAIN)
    window.scrollTo(0, 0)
  }

  return (
    <AppContainer>
      <Helmet>
        <title>{t('type_selection.page_title')}</title>
      </Helmet>
      <StyledBox>
        <TitleGrid>
          <Typography variant="title">{t('type_selection.header_title')}</Typography>
        </TitleGrid>
        <TileGrid>
          <Card
            testId="type_selection.archive_card"
            title={
              <Trans
                i18nKey="type_selection.archive.title"
                components={{
                  span: <StyledSpan />,
                }}
              />
            }
            type={JobType.ARCHIVE}
            description={t('type_selection.archive.description')}
            onSelect={(selected) => handleSelection(JobType.ARCHIVE, selected)}
            isSelected={type === JobType.ARCHIVE}
          />
          <Card
            testId="type_selection.digitize_card"
            title={
              <Trans
                i18nKey="type_selection.digitize.title"
                components={{
                  span: <StyledSpan />,
                }}
              />
            }
            type={JobType.DIGITIZE}
            description={t('type_selection.digitize.description')}
            onSelect={(selected) => handleSelection(JobType.DIGITIZE, selected)}
            isSelected={type === JobType.DIGITIZE}
          />
          <Card
            testId="type_selection.destruct_card"
            title={
              <Trans
                i18nKey="type_selection.destruct.title"
                components={{
                  span: <StyledSpan />,
                }}
              />
            }
            type={JobType.DESTRUCT}
            description={t('type_selection.destruct.description')}
            onSelect={(selected) => handleSelection(JobType.DESTRUCT, selected)}
            isSelected={type === JobType.DESTRUCT}
          />
        </TileGrid>
        <ButtonGrid>
          <Button
            onClick={handleOnPressNext}
            data-testid="type_selection.next_button"
            disabled={!type}
            variant="contained">
            {t('buttons.next')}
          </Button>
        </ButtonGrid>
      </StyledBox>
    </AppContainer>
  )
}

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
})

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.color.tertiary.green,
}))

const TitleGrid = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('tablet')]: {
    marginTop: theme.layout.spacing._70,
    marginBottom: theme.layout.spacing._70,
  },
  [theme.breakpoints.down('tablet')]: {
    marginTop: theme.layout.spacing._50,
    marginBottom: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('mobile')]: {
    marginTop: theme.layout.spacing._40,
    marginBottom: theme.layout.spacing._40,
  },
}))

const TileGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'center',
})

const ButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.layout.spacing._80,
  marginBottom: theme.layout.spacing._40,
}))
