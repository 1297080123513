import { UseQueryOptions, useQuery } from 'react-query'

import { getPickupDates } from '../../api'
import { IPickupDatesRequest, IPickupDatesResponse } from '../../types'

export const useGetPickupDates = ({
  params,
  options,
}: {
  params: IPickupDatesRequest
  options?: UseQueryOptions<IPickupDatesResponse[], Error>
}) => {
  const { data, error, isLoading, isError } = useQuery<IPickupDatesResponse[], Error>(
    ['pickup-dates', params],
    getPickupDates,
    {
      staleTime: Infinity,
      refetchOnMount: false,
      ...options,
    },
  )

  return { data, error, isLoading, isError }
}
