import React from 'react'

import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getCompanyDeliveryAddress } from '../../../store/inquiry.slice'
import {
  clearSelectedPaymentMethod,
  getInvoiceIsDeliveryAddress,
  getIsContactPersonOnSite,
  getSelectedPaymentMethod,
  getSepaConsent,
  setSelectedPaymentMethod,
  setSepaConsent,
} from '../../../store/order.slice'
import { PaymentMethod } from '../../../types'
import { IFormikValues } from '../pages/MetaInformation'
import { Card } from './Card'

interface IProps {
  formik: FormikProps<IFormikValues>
}

export const PaymentSection = ({ formik }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const sepaConsent = useSelector(getSepaConsent)
  const { isPrivatePerson } = useSelector(getCompanyDeliveryAddress)
  const isContactPersonOnSite = useSelector(getIsContactPersonOnSite)
  const invoiceIsDeliveryAddress = useSelector(getInvoiceIsDeliveryAddress)

  const handlePaymentMethodSelection = async (paymentMethod: PaymentMethod, isSelected: boolean) => {
    dispatch(clearSelectedPaymentMethod())
    if (isSelected && selectedPaymentMethod !== paymentMethod) {
      dispatch(setSelectedPaymentMethod(paymentMethod))
      if (paymentMethod === PaymentMethod.INVOICE) {
        delete formik.errors.bank?.givenname
        delete formik.errors.bank?.surname
        delete formik.errors.bank?.street
        delete formik.errors.bank?.streetNumber
        delete formik.errors.bank?.zip
        delete formik.errors.bank?.city
        delete formik.errors.bank?.bankName
        delete formik.errors.bank?.bankBic
        delete formik.errors.bank?.bankIban
      } else {
        await formik.validateForm()
        if (isContactPersonOnSite) {
          delete formik.errors.contactPerson?.givenname
          delete formik.errors.contactPerson?.surname
          delete formik.errors.contactPerson?.email
          delete formik.errors.contactPerson?.phone
        }
        if (invoiceIsDeliveryAddress) {
          delete formik.errors.invoice?.name
          delete formik.errors.invoice?.street
          delete formik.errors.invoice?.streetNumber
          delete formik.errors.invoice?.zip
          delete formik.errors.invoice?.city
          delete formik.errors.invoice?.email
        }
      }
    }
  }

  const handleSepaConsent = async (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    dispatch(setSepaConsent(checked))
  }

  const renderSepaSection = () => {
    if (selectedPaymentMethod === PaymentMethod.SEPA) {
      return (
        <InputGrid>
          <SepaDataGrid>
            <SepaPersonDataGrid>
              <HorizontalInputGrid>
                <GivennameTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.givenname' }}
                  placeholder={t('order.meta_information.payment_information.inputs.givenname').toUpperCase()}
                  id="bank.givenname"
                  name="bank.givenname"
                  value={formik.values.bank.givenname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.givenname && Boolean(formik.errors.bank?.givenname)}
                  helperText={(formik.touched.bank?.givenname && formik.errors.bank?.givenname) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <SurnameTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.surname' }}
                  placeholder={t('order.meta_information.payment_information.inputs.surname').toUpperCase()}
                  id="bank.surname"
                  name="bank.surname"
                  value={formik.values.bank.surname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.surname && Boolean(formik.errors.bank?.surname)}
                  helperText={(formik.touched.bank?.surname && formik.errors.bank?.surname) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
              <HorizontalInputGrid>
                <StreetTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.street' }}
                  placeholder={t('order.meta_information.payment_information.inputs.street').toUpperCase()}
                  id="bank.street"
                  name="bank.street"
                  value={formik.values.bank.street}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.street && Boolean(formik.errors.bank?.street)}
                  helperText={(formik.touched.bank?.street && formik.errors.bank?.street) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <StreetNumberTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.street_number' }}
                  placeholder={t('order.meta_information.payment_information.inputs.street_number').toUpperCase()}
                  id="bank.streetNumber"
                  name="bank.streetNumber"
                  value={formik.values.bank.streetNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.streetNumber && Boolean(formik.errors.bank?.streetNumber)}
                  helperText={(formik.touched.bank?.streetNumber && formik.errors.bank?.streetNumber) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
              <HorizontalInputGrid>
                <ZipTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.zip' }}
                  placeholder={t('order.meta_information.payment_information.inputs.zip').toUpperCase()}
                  id="bank.zip"
                  name="bank.zip"
                  value={formik.values.bank.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.zip && Boolean(formik.errors.bank?.zip)}
                  helperText={(formik.touched.bank?.zip && formik.errors.bank?.zip) ?? ' '}
                  type="text"
                  margin="dense"
                />
                <CityTextField
                  inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.city' }}
                  placeholder={t('order.meta_information.payment_information.inputs.city').toUpperCase()}
                  id="bank.city"
                  name="bank.city"
                  value={formik.values.bank.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.bank?.city && Boolean(formik.errors.bank?.city)}
                  helperText={(formik.touched.bank?.city && formik.errors.bank?.city) ?? ' '}
                  type="text"
                  margin="dense"
                />
              </HorizontalInputGrid>
            </SepaPersonDataGrid>
            <SepaBankDataGrid>
              <StyledTextField
                inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.bank' }}
                placeholder={t('order.meta_information.payment_information.inputs.bank').toUpperCase()}
                id="bank.bankName"
                name="bank.bankName"
                value={formik.values.bank.bankName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bank?.bankName && Boolean(formik.errors.bank?.bankName)}
                helperText={(formik.touched.bank?.bankName && formik.errors.bank?.bankName) ?? ' '}
                type="text"
                margin="dense"
              />
              <StyledTextField
                inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.bic' }}
                placeholder={t('order.meta_information.payment_information.inputs.bic').toUpperCase()}
                id="bank.bankBic"
                name="bank.bankBic"
                value={formik.values.bank.bankBic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bank?.bankBic && Boolean(formik.errors.bank?.bankBic)}
                helperText={(formik.touched.bank?.bankBic && formik.errors.bank?.bankBic) ?? ' '}
                type="text"
                margin="dense"
              />
              <StyledTextField
                inputProps={{ 'data-testid': 'order.meta_information.bank_data_inputs.iban' }}
                placeholder={t('order.meta_information.payment_information.inputs.iban').toUpperCase()}
                id="bank.bankIban"
                name="bank.bankIban"
                value={formik.values.bank.bankIban}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.bank?.bankIban && Boolean(formik.errors.bank?.bankIban)}
                helperText={(formik.touched.bank?.bankIban && formik.errors.bank?.bankIban) ?? ' '}
                type="text"
                margin="dense"
              />
            </SepaBankDataGrid>
          </SepaDataGrid>
          {isPrivatePerson && (
            <TextGrid>
              <SepaConsentText>
                {t('order.meta_information.payment_information.sepa_consent_private_person.text')}
              </SepaConsentText>
              <SepaConsentText>
                {t('order.meta_information.payment_information.sepa_consent_private_person.hint')}
              </SepaConsentText>
            </TextGrid>
          )}
          {!isPrivatePerson && (
            <TextGrid>
              <SepaConsentText data-testid="order.meta_information.payment_information.sepa_consent.text">
                {t('order.meta_information.payment_information.sepa_consent_company.text')}
              </SepaConsentText>
              <SepaConsentText data-testid="order.meta_information.payment_information.sepa_consent.hint">
                {t('order.meta_information.payment_information.sepa_consent_company.hint')}
              </SepaConsentText>
            </TextGrid>
          )}
          <CheckboxGrid>
            <FormControlLabel
              onChange={handleSepaConsent}
              control={<Checkbox checked={sepaConsent} />}
              label={
                <Typography>{t('order.meta_information.payment_information.sepa_consent_checkbox_label')}</Typography>
              }
            />
          </CheckboxGrid>
        </InputGrid>
      )
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.meta_information.payment_information.title')}</Typography>
      <CardGrid>
        <Card
          testId="payment_information.sepa_card"
          title={t('order.meta_information.payment_information.cards.sepa_title')}
          onSelect={async (selected) => await handlePaymentMethodSelection(PaymentMethod.SEPA, selected)}
          isSelected={selectedPaymentMethod === PaymentMethod.SEPA}
        />
        <Card
          testId="payment_information.invoice_card"
          title={t('order.meta_information.payment_information.cards.invoice_title')}
          onSelect={async (selected) => await handlePaymentMethodSelection(PaymentMethod.INVOICE, selected)}
          isSelected={selectedPaymentMethod === PaymentMethod.INVOICE}
        />
      </CardGrid>
      {renderSepaSection()}
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const SepaDataGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}))

const SepaPersonDataGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 0.5,
  marginRight: theme.layout.spacing._10,
}))

const SepaBankDataGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 0.5,
  marginLeft: theme.layout.spacing._10,
}))

const CheckboxGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size._50,
}))

const InputGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [theme.breakpoints.up('desktop')]: {
    marginBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    marginBottom: theme.layout.spacing._10,
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._10,
  marginBottom: theme.layout.spacing._10,
}))

const SepaConsentText = styled(Typography)(({ theme }) => ({
  marginTop: theme.layout.spacing._10,
}))

const HorizontalInputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const GivennameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginRight: theme.layout.spacing._10,
}))

const SurnameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginLeft: theme.layout.spacing._10,
}))

const StreetTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.6,
  marginRight: theme.layout.spacing._10,
}))

const StreetNumberTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.4,
  marginLeft: theme.layout.spacing._10,
}))

const ZipTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.3,
  marginRight: theme.layout.spacing._10,
}))

const CityTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.7,
  marginLeft: theme.layout.spacing._10,
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))
