import { ThemeProvider } from '@mui/system'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'

import { AppRoot } from './components'
import store from './store'
import theme from './theme'

const App = () => {
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AppRoot />
          </ThemeProvider>
        </Provider>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App
