import { Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { IFormikValues } from '../pages'

interface IProps {
  formik: FormikProps<IFormikValues>
}

export const FurtherInformationSection = ({ formik }: IProps) => {
  const { t } = useTranslation()

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.meta_information.further_information.title')}</Typography>
      <InputGrid>
        <StyledTextField
          inputProps={{ 'data-testid': 'order.meta_information.further_information.input' }}
          id="furtherInformation"
          name="furtherInformation"
          value={formik.values.furtherInformation}
          error={formik.touched.furtherInformation && Boolean(formik.errors.furtherInformation)}
          helperText={(formik.touched.furtherInformation && formik.errors.furtherInformation) ?? ' '}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="text"
          multiline
          rows={5}
        />
      </InputGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('desktop')]: {
    marginTop: theme.layout.spacing._50,
  },
  [theme.breakpoints.down('desktop')]: {
    marginTop: theme.layout.spacing._30,
  },
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))
