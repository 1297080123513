import { ICompanyDeliveryAddress, IContactData, InquiryAmountUnit, JobType } from './inquiry'
import {
  ContainerType,
  DestructionAmountUnit,
  DestructionOrderType,
  DestructionPlace,
  DestructionProtection,
  DestructionSubject,
  DestructionType,
  PaymentMethod,
} from './order'

export interface IInquiryMailBody {
  deliveryAddress: {
    name: string
    street: string
    streetNumber: string
    postCode: string
    city: string
  }
  requestContact: {
    firstname: string
    lastname: string
    email: string
    phone: string
  }
  service: JobType
  count: number
  unit: InquiryAmountUnit
  comment?: string
}

export interface IDestructMailBody extends IOrderInformation, Omit<IInquiryMailBody, 'unit'> {
  unit: DestructionAmountUnit
}

export interface IOrderInformation {
  destructionType: DestructionType
  subject: DestructionSubject
  protectionClass: DestructionProtection
  placeOfData: DestructionPlace
  orderType: DestructionOrderType
}

export interface PostInquiryMailData {
  mailData: IInquiryMailData
  isPrivatePerson: boolean
  type: JobType
}

export interface IInquiryMailData {
  companyData: ICompanyDeliveryAddress
  contactData: IContactData
  furtherInformation: string
  jobType: JobType
  amount: string
  unit: InquiryAmountUnit | DestructionAmountUnit
  orderInformation?: IOrderInformation
}

export interface IContainerPricesRequest {
  postCode: string
  containerType: ContainerType
  protectionClass: DestructionProtection
  orderType: DestructionOrderType
  subject: DestructionSubject
}
export interface IContainerPricesResponse {
  name: string
  code: ContainerPriceCode
  price: number
  interval: ContainerPriceInterval
  isUnitPrice: boolean
}

export enum ContainerPriceCode {
  SUBMISSION_PRICE = 'SUBMISSION_PRICE',
  EXCHANGE_PRICE = 'EXCHANGE_PRICE',
  RENTAL_PRICE = 'RENTAL_PRICE',
  CERTIFICATE_PRICE = 'CERTIFICATE_PRICE',
  ENERGY_PRICE = 'ENERGY_PRICE',
  PICK_UP_PRICE = 'PICK-UP_PRICE',
}

export enum ContainerPriceInterval {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  NO_INTERVAL = '',
}

export interface IPickupDatesRequest {
  zip: string
}

export interface IPickupDatesResponse {
  date: string
  available: boolean
  type: PickupDateType
  orderNo: string
}

export enum PickupDateType {
  STANDARD = 'standard',
  ORDER = 'order',
  CYCLE = 'cycle',
}

export interface IOrderRequestApi {
  deliveryAddress: {
    name: string
    street: string
    streetNumber: string
    postCode: string
    city: string
  }
  requestContact: {
    firstname: string
    lastname: string
    email: string
    phone: string
  }
  comment?: string
  subject: DestructionSubject
  protectionClass: DestructionProtection
  orderType: DestructionOrderType
  containerType: ContainerType
  count?: number
  deliveryDate: string
  deliveryStartingTime: string
  deliveryFinishingTime: string
  deliveryContact?: {
    firstname: string
    lastname: string
    email: string
    phone: string
  }
  invoiceAddress: {
    name: string
    street: string
    streetNumber: string
    postCode: string
    city: string
    invoiceEmail: string
  }
  paymentType: PaymentMethod
  debitNoteInfo?: {
    accountOwner: {
      name: string
      street: string
      streetNumber: string
      postCode: string
      city: string
    }
    bankName: string
    bankBIC: string
    iban: string
    consent: boolean
  }
  permanentCustomer: boolean
  agbConsent: boolean
}
