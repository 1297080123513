import { ReactElement } from 'react'

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import _ from 'lodash'

interface IProps {
  icon?: string
  title: string | ReactElement
  subTitle?: string
  description?: string
  descriptionList?: string
  onSelect: (isSelected: boolean) => void
  isSelected: boolean
  testId: string
}

export const Card = ({ icon, title, subTitle, description, descriptionList, onSelect, isSelected, testId }: IProps) => {
  const handleChange = (event: any) => {
    event.preventDefault()
    onSelect(!isSelected)
  }

  const card = (
    <StyledCardContent onClick={handleChange}>
      <StyledFormGroup>
        {subTitle && <StyledTypo variant="cardTitle">{title}</StyledTypo>}
        <FormControlLabel
          onChange={handleChange}
          control={
            <StyledCheckbox
              data-testid={testId}
              checked={isSelected}
              disableRipple={true}
              icon={<CircleOutlinedIcon />}
              checkedIcon={<CheckCircleRoundedIcon />}
            />
          }
          label={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {icon && <StyledIcon src={icon} />}
              <Typography variant="pageTitle">{subTitle ?? title}</Typography>
            </Box>
          }
        />
      </StyledFormGroup>
      {description && (
        <TextGrid>
          <Typography variant="tileText">{description}</Typography>
        </TextGrid>
      )}
      {descriptionList && (
        <TextGrid>
          {descriptionList.split(';').map((description, index) => (
            <DescriptionListGrid key={`${description}-${index}`}>
              <Typography>•</Typography>
              <DescriptionListItem>{description}</DescriptionListItem>
            </DescriptionListGrid>
          ))}
        </TextGrid>
      )}
    </StyledCardContent>
  )

  return (
    <CardGrid>
      <StyledCard isSelected={isSelected} hasMinHeight={!_.isUndefined(descriptionList)} variant="outlined">
        {card}
      </StyledCard>
    </CardGrid>
  )
}

const StyledIcon = styled('img')(({ theme }) => ({
  width: '50px',
  marginRight: theme.layout.spacing._20,
}))

const StyledTypo = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.layout.spacing._10,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    paddingTop: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingTop: theme.layout.spacing._10,
    paddingRight: theme.layout.spacing._10,
  },
}))

const StyledCard = styled(MuiCard, { shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'hasMinHeight' })<{
  isSelected: boolean
  hasMinHeight: boolean
}>(({ theme, isSelected, hasMinHeight }) => ({
  minHeight: hasMinHeight ? theme.layout.size._320 : undefined,
  borderWidth: theme.layout.borderWidth._2,
  borderColor: isSelected ? theme.color.tertiary.green : theme.color.secondary.softGray,
  [theme.breakpoints.up('laptop')]: {
    maxWidth: theme.layout.size._350,
  },
  [theme.breakpoints.down('laptop')]: {
    maxWidth: theme.layout.size._350,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  '&:last-child': {
    paddingBottom: 0,
  },
  [theme.breakpoints.down('laptop')]: {
    padding: theme.layout.spacing._20,
    '&:last-child': {
      paddingBottom: theme.layout.spacing._20,
    },
  },
}))

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    paddingRight: theme.layout.spacing._40,
    paddingLeft: theme.layout.spacing._40,
    paddingTop: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    paddingRight: theme.layout.spacing._20,
    paddingLeft: theme.layout.spacing._20,
    paddingTop: theme.layout.spacing._10,
    paddingBottom: theme.layout.spacing._10,
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: theme.layout.spacing._10,
  color: theme.color.secondary.softGray,
  [theme.breakpoints.down('laptop')]: {},
  [theme.breakpoints.down('mobile')]: {
    marginRight: theme.layout.spacing._20,
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('laptop')]: {
    paddingRight: theme.layout.spacing._40,
    paddingLeft: theme.layout.spacing._40,
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    paddingRight: theme.layout.spacing._20,
    paddingLeft: theme.layout.spacing._20,
    paddingBottom: theme.layout.spacing._10,
  },
}))

const DescriptionListGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
}))

const DescriptionListItem = styled(Typography)(({ theme }) => ({
  marginLeft: theme.layout.spacing._10,
  marginBottom: theme.layout.spacing._10,
  '&:last-child': {
    marginBottom: 0,
  },
}))
