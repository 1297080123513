import React from 'react'

import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getCompanyDeliveryAddress, getContactData } from '../../../store/inquiry.slice'
import {
  getInvoiceIsDeliveryAddress,
  getIsContactPersonOnSite,
  getSelectedPaymentMethod,
  setInvoiceCityData,
  setInvoiceEmailData,
  setInvoiceIsDeliveryAddress,
  setInvoiceNameData,
  setInvoiceStreetData,
  setInvoiceStreetNumberData,
  setInvoiceZipData,
} from '../../../store/order.slice'
import { PaymentMethod } from '../../../types'
import { IFormikValues } from '../pages/MetaInformation'

interface IProps {
  formik: FormikProps<IFormikValues>
}

export const InvoiceAddressSpecification = ({ formik }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { email: contactMail, givenname, surname } = useSelector(getContactData)
  const {
    street: companyStreet,
    number: companyStreetNumber,
    zip: companyZip,
    city: companyCity,
    isPrivatePerson,
    name: companyName,
  } = useSelector(getCompanyDeliveryAddress)
  const invoiceIsDeliveryAddress = useSelector(getInvoiceIsDeliveryAddress)
  const isContactPersonOnSite = useSelector(getIsContactPersonOnSite)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)

  const handleInvoiceIsDeliveryAddress = async (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked) {
      dispatch(setInvoiceNameData(isPrivatePerson ? `${givenname} ${surname}` : companyName))
      dispatch(setInvoiceStreetData(companyStreet))
      dispatch(setInvoiceStreetNumberData(companyStreetNumber))
      dispatch(setInvoiceEmailData(contactMail))
      dispatch(setInvoiceZipData(companyZip))
      dispatch(setInvoiceCityData(companyCity))
      delete formik.errors.invoice?.name
      delete formik.errors.invoice?.street
      delete formik.errors.invoice?.streetNumber
      delete formik.errors.invoice?.zip
      delete formik.errors.invoice?.city
      delete formik.errors.invoice?.email
    } else {
      await formik.validateForm()
      if (isContactPersonOnSite) {
        delete formik.errors.contactPerson?.givenname
        delete formik.errors.contactPerson?.surname
        delete formik.errors.contactPerson?.email
        delete formik.errors.contactPerson?.phone
      }
      if (selectedPaymentMethod === PaymentMethod.INVOICE) {
        delete formik.errors.bank?.givenname
        delete formik.errors.bank?.surname
        delete formik.errors.bank?.street
        delete formik.errors.bank?.streetNumber
        delete formik.errors.bank?.zip
        delete formik.errors.bank?.city
        delete formik.errors.bank?.bankName
        delete formik.errors.bank?.bankBic
        delete formik.errors.bank?.bankIban
      }
    }
    dispatch(setInvoiceIsDeliveryAddress(checked))
  }

  return (
    <SectionGrid>
      <StyledTitle variant="underlinedTileTitle">{t('order.meta_information.invoice_title')}</StyledTitle>
      <InputGrid>
        <CheckboxGrid>
          <FormControlLabel
            data-testid="order.meta_information.invoice_checkbox"
            onChange={handleInvoiceIsDeliveryAddress}
            control={<Checkbox checked={invoiceIsDeliveryAddress} />}
            label={<Typography>{t('order.meta_information.invoice_checkbox_label')}</Typography>}
          />
        </CheckboxGrid>
        {!invoiceIsDeliveryAddress && (
          <React.Fragment>
            <StyledTextField
              inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.name' }}
              placeholder={t('order.meta_information.invoice_inputs.name').toUpperCase()}
              id="invoice.name"
              name="invoice.name"
              value={formik.values.invoice.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.invoice?.name && Boolean(formik.errors.invoice?.name)}
              helperText={(formik.touched.invoice?.name && formik.errors.invoice?.name) ?? ' '}
              type="text"
              margin="dense"
            />
            <HorizontalInputGrid>
              <StreetTextField
                inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.street' }}
                placeholder={t('order.meta_information.invoice_inputs.street').toUpperCase()}
                id="invoice.street"
                name="invoice.street"
                value={formik.values.invoice.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.invoice?.street && Boolean(formik.errors.invoice?.street)}
                helperText={(formik.touched.invoice?.street && formik.errors.invoice?.street) ?? ' '}
                type="text"
                margin="dense"
              />
              <StreetNumberTextField
                inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.street_number' }}
                placeholder={t('order.meta_information.invoice_inputs.street_number').toUpperCase()}
                id="invoice.streetNumber"
                name="invoice.streetNumber"
                value={formik.values.invoice.streetNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.invoice?.streetNumber && Boolean(formik.errors.invoice?.streetNumber)}
                helperText={(formik.touched.invoice?.streetNumber && formik.errors.invoice?.streetNumber) ?? ' '}
                type="text"
                margin="dense"
              />
            </HorizontalInputGrid>
            <HorizontalInputGrid>
              <ZipTextField
                inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.zip' }}
                placeholder={t('order.meta_information.invoice_inputs.zip').toUpperCase()}
                id="invoice.zip"
                name="invoice.zip"
                value={formik.values.invoice.zip}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.invoice?.zip && Boolean(formik.errors.invoice?.zip)}
                helperText={(formik.touched.invoice?.zip && formik.errors.invoice?.zip) ?? ' '}
                type="text"
                margin="dense"
              />
              <CityTextField
                inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.city' }}
                placeholder={t('order.meta_information.invoice_inputs.city').toUpperCase()}
                id="invoice.city"
                name="invoice.city"
                value={formik.values.invoice.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.invoice?.city && Boolean(formik.errors.invoice?.city)}
                helperText={(formik.touched.invoice?.city && formik.errors.invoice?.city) ?? ' '}
                type="text"
                margin="dense"
              />
            </HorizontalInputGrid>
            <StyledTextField
              inputProps={{ 'data-testid': 'order.meta_information.invoice_inputs.email' }}
              placeholder={t('order.meta_information.invoice_inputs.email').toUpperCase()}
              id="invoice.email"
              name="invoice.email"
              value={formik.values.invoice.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.invoice?.email && Boolean(formik.errors.invoice?.email)}
              helperText={(formik.touched.invoice?.email && formik.errors.invoice?.email) ?? ' '}
              type="email"
              margin="dense"
            />
          </React.Fragment>
        )}
      </InputGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const CheckboxGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size._50,
}))

const HorizontalInputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const StreetTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.6,
  marginRight: theme.layout.spacing._10,
}))

const StreetNumberTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.4,
  marginLeft: theme.layout.spacing._10,
}))

const ZipTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.3,
  marginRight: theme.layout.spacing._10,
}))

const CityTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 0.7,
  marginLeft: theme.layout.spacing._10,
}))
