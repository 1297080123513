import React from 'react'

import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'

import { JobType } from '../types'

interface IProps {
  serviceType: JobType
}

export const ServiceType = ({ serviceType }: IProps) => {
  const { t } = useTranslation()

  const getServiceTypeText = () => {
    switch (serviceType) {
      case JobType.ARCHIVE:
        return t('service_type.archive')
      case JobType.DIGITIZE:
        return t('service_type.digitize')
      case JobType.DESTRUCT:
        return t('service_type.destruct')
    }
  }

  return (
    <StyledBox>
      <StyledServiceType>
        <StyledServiceTypeText>{getServiceTypeText()}</StyledServiceTypeText>
      </StyledServiceType>
    </StyledBox>
  )
}

const StyledServiceType = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.color.tertiary.green,
  paddingLeft: theme.layout.spacing._20,
  paddingRight: theme.layout.spacing._20,
  [theme.breakpoints.up('laptop')]: {
    marginRight: theme.layout.spacing._30 + 2 * theme.layout.spacing._12,
  },
  [theme.breakpoints.up('tablet')]: {
    height: theme.layout.size._48,
  },
  [theme.breakpoints.between('mobile', 'tablet')]: {
    height: theme.layout.size._48,
    flex: 1,
    alignItems: 'center',
  },
  [theme.breakpoints.down('mobile')]: {
    height: theme.layout.size._48,
    flex: 1,
    alignItems: 'center',
  },
}))

const StyledServiceTypeText = styled(Typography)(({ theme }) => ({
  color: theme.color.white,
}))

const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1,
})
