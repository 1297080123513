const ROUTE = {
  NOT_FOUND: '*',
  HOME: '/',
  INQUIRY: {
    MAIN: '/archivieren-digitalisieren',
    CONTACT_DETAILS: 'kontakt',
    FURTHER_INFORMATION: 'weitere-informationen',
    SUMMARY: 'zusammenfassung',
    SENT: 'anfrage-gesendet',
  },
  ORDER: {
    MAIN: '/vernichten',
    CONTACT_DETAILS: 'kontakt',
    FURTHER_INFORMATION: 'weitere-informationen',
    META_INFORMATION: 'auftragsdetails',
    SUMMARY: 'zusammenfassung',
    SENT: 'auftrag-gesendet',
  },
}

export default ROUTE
