import { useMutation } from 'react-query'

import { postInquiryMail } from '../../api'
import { PostInquiryMailData } from '../../types'

export const usePostSendInquiry = () => {
  const { mutateAsync, isLoading } = useMutation<Response, Error, PostInquiryMailData>(postInquiryMail)

  return { mutateAsync, isLoading }
}
