import React from 'react'

import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { CardContent, Divider, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getContainerPrices, getDestructionOrderType, getSelectedContainer } from '../../../store/order.slice'
import { ContainerPriceCode, DestructionOrderType } from '../../../types'
import { currencyNumber } from '../../../utils'

export const PriceSummarySection = () => {
  const { t } = useTranslation()
  const containerPrices = useSelector(getContainerPrices)
  const selectedContainer = useSelector(getSelectedContainer)
  const orderType = useSelector(getDestructionOrderType)
  const amount = selectedContainer?.amount ?? 1
  const containerPrice =
    orderType === DestructionOrderType.PERMANENT
      ? containerPrices?.find((d) => d.code === ContainerPriceCode.EXCHANGE_PRICE)?.price
      : containerPrices?.find((d) => d.code === ContainerPriceCode.PICK_UP_PRICE)?.price
  const deliverPrice = containerPrices?.find((d) => d.code === ContainerPriceCode.SUBMISSION_PRICE)?.price
  const energyPrice = containerPrices?.find((d) => d.code === ContainerPriceCode.ENERGY_PRICE)?.price
  const certificatePrice = containerPrices?.find((d) => d.code === ContainerPriceCode.CERTIFICATE_PRICE)?.price
  const rentalPrice = containerPrices?.find((d) => d.code === ContainerPriceCode.RENTAL_PRICE)?.price

  const getTotalNettoPrice = () => {
    if (
      !_.isUndefined(containerPrice) &&
      !_.isUndefined(deliverPrice) &&
      !_.isUndefined(energyPrice) &&
      !_.isUndefined(certificatePrice)
    ) {
      const sum = containerPrice * amount + deliverPrice * amount + energyPrice + certificatePrice
      return currencyNumber(sum)
    }
  }

  const getBruttoRentalPrice = () => {
    if (!_.isUndefined(rentalPrice)) {
      const nettoSum = rentalPrice * amount
      const taxRate = 0.19
      const taxes = nettoSum * taxRate
      const bruttosum = nettoSum + taxes
      return currencyNumber(Number(bruttosum.toFixed(2)))
    }
  }

  const getTotalBruttoPrice = () => {
    if (
      !_.isUndefined(containerPrice) &&
      !_.isUndefined(deliverPrice) &&
      !_.isUndefined(energyPrice) &&
      !_.isUndefined(certificatePrice)
    ) {
      const nettoSum = containerPrice * amount + deliverPrice * amount + energyPrice + certificatePrice
      const taxRate = 0.19
      const taxes = nettoSum * taxRate
      const bruttosum = nettoSum + taxes
      return currencyNumber(Number(bruttosum.toFixed(2)))
    }
  }

  const getRentalTaxPrice = () => {
    if (!_.isUndefined(rentalPrice)) {
      const nettoSum = rentalPrice * amount
      const taxRate = 0.19
      const taxes = nettoSum * taxRate
      return currencyNumber(Number(taxes.toFixed(2)))
    }
  }

  const getTaxPrice = () => {
    if (
      !_.isUndefined(containerPrice) &&
      !_.isUndefined(deliverPrice) &&
      !_.isUndefined(energyPrice) &&
      !_.isUndefined(certificatePrice)
    ) {
      const nettoSum = containerPrice * amount + deliverPrice * amount + energyPrice + certificatePrice
      const taxRate = 0.19
      const taxes = nettoSum * taxRate
      return currencyNumber(Number(taxes.toFixed(2)))
    }
  }

  const renderTopGrid = (title: string) => {
    return (
      <TopGrid>
        <StyledPriceIcon />
        <StyledTextGrid>
          <Typography variant="tileTitle">{title}</Typography>
        </StyledTextGrid>
      </TopGrid>
    )
  }

  const renderOneTimeCostCard = () => {
    return (
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.price_information_cards.otc_card">
          {renderTopGrid(t('order.summary.price_information.one_time_cost_card.title'))}
          <PricePositionGrid>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.price_information.one_time_cost_card.container_price_label')}
              </Typography>
              <Typography variant="pageTitle">{containerPrice && currencyNumber(containerPrice * amount)}</Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.price_information.one_time_cost_card.delivery_price_label')}
              </Typography>
              <Typography variant="pageTitle">{deliverPrice && currencyNumber(deliverPrice * amount)}</Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.price_information.one_time_cost_card.energy_cost_label')}
              </Typography>
              <Typography variant="pageTitle">{currencyNumber(energyPrice)}</Typography>
            </Section>
            <Section>
              <Typography variant="tileText">
                {t('order.summary.price_information.one_time_cost_card.certificate_fee_label')}
              </Typography>
              <Typography variant="pageTitle">{currencyNumber(certificatePrice)}</Typography>
            </Section>
          </PricePositionGrid>
          <HorizontalDivider variant="middle" />
          <PricePositionGrid>
            <NettoSumTextGrid>
              <SumText variant="tileTitle">{getTotalNettoPrice()}</SumText>
              <Typography>{`(${t('order.summary.price_information.one_time_cost_card.netto_label')})`}</Typography>
            </NettoSumTextGrid>
            <BruttoSumTextGrid>
              <SumText variant="tileTitle">{getTotalBruttoPrice()}</SumText>
              <Typography>{`(${t('order.summary.price_information.one_time_cost_card.brutto_label')})`}</Typography>
            </BruttoSumTextGrid>
            <Typography>
              {t('order.summary.price_information.one_time_cost_card.inclusive_taxes_label', {
                taxValue: getTaxPrice(),
              })}
            </Typography>
          </PricePositionGrid>
        </StyledCardContent>
      </StyledCard>
    )
  }

  const renderRentalCostCard = () => {
    return (
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.price_information_cards.rental_costs_card">
          {renderTopGrid(t('order.summary.price_information.rental_cost_card.title'))}
          <PricePositionGrid>
            <NettoSumTextGrid>
              <SumText variant="tileTitle">{rentalPrice && currencyNumber(rentalPrice * amount)}</SumText>
              <Typography>{`(${t('order.summary.price_information.one_time_cost_card.netto_label')})`}</Typography>
              <PerMonthText>{`/ ${
                orderType === DestructionOrderType.PERMANENT
                  ? t('order.summary.price_information.rental_cost_card.month_label')
                  : t('order.summary.price_information.rental_cost_card.day_label')
              }`}</PerMonthText>
            </NettoSumTextGrid>
            <BruttoSumTextGrid>
              <SumText variant="tileTitle">{getBruttoRentalPrice()}</SumText>
              <Typography>{`(${t('order.summary.price_information.one_time_cost_card.brutto_label')})`}</Typography>
              <PerMonthText>{`/ ${
                orderType === DestructionOrderType.PERMANENT
                  ? t('order.summary.price_information.rental_cost_card.month_label')
                  : t('order.summary.price_information.rental_cost_card.day_label')
              }`}</PerMonthText>
            </BruttoSumTextGrid>
            <Typography>
              {t('order.summary.price_information.rental_cost_card.inclusive_taxes_label', {
                taxValue: getRentalTaxPrice(),
              })}
            </Typography>
          </PricePositionGrid>
        </StyledCardContent>
      </StyledCard>
    )
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.summary.price_information.title')}</Typography>
      <Section>
        <CardGrid>
          {renderOneTimeCostCard()}
          {renderRentalCostCard()}
        </CardGrid>
      </Section>
    </SectionGrid>
  )
}
const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('desktop')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('desktop')]: {
    flexDirection: 'column',
  },
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  flex: 1,
  [theme.breakpoints.up('desktop')]: {
    '&:last-child': {
      marginLeft: theme.layout.spacing._40,
    },
  },
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
    '&:last-child': {
      paddingBottom: theme.layout.spacing._40,
    },
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.layout.spacing._20,
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledPriceIcon = styled(MonetizationOnIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._30,
  },
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._20,
}))

const PricePositionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
  },
}))

const HorizontalDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.layout.spacing._40,
  marginBottom: theme.layout.spacing._40,
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._80,
    marginRight: theme.layout.spacing._80,
  },
}))

const SumText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  marginRight: theme.layout.spacing._10,
  [theme.breakpoints.up('mobile')]: {
    fontSize: theme.layout.fontSize._24,
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: theme.layout.fontSize._18,
  },
}))

const NettoSumTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

const BruttoSumTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: theme.layout.spacing._40,
}))

const PerMonthText = styled(Typography)(({ theme }) => ({
  marginLeft: theme.layout.spacing._10,
  [theme.breakpoints.up('mobile')]: {
    fontSize: theme.layout.fontSize._24,
  },
  [theme.breakpoints.down('mobile')]: {
    fontSize: theme.layout.fontSize._18,
  },
}))
