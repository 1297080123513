import PaymentIcon from '@mui/icons-material/Payment'
import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getBankData, getSelectedPaymentMethod } from '../../../store/order.slice'
import { PaymentMethod } from '../../../types'

export const PaymentDataSummary = () => {
  const { t } = useTranslation()
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const { givenname, surname, bankName, bankBic, bankIban } = useSelector(getBankData)

  const getPaymentTitle = () => {
    switch (selectedPaymentMethod) {
      case PaymentMethod.SEPA:
        return t('order.summary.specified_data.payment_data_card.sepa_title')

      case PaymentMethod.INVOICE:
        return t('order.summary.specified_data.payment_data_card.invoice_title')
    }
  }

  return (
    <SectionGrid>
      <StyledCard variant="outlined">
        <StyledCardContent data-testid="order.summary.contact_data">
          <TopGrid>
            <StyledPaymentIcon />
            <StyledTextGrid>
              <Typography variant="tileText">{t('order.summary.specified_data.payment_data_card.title')}</Typography>
              <StyledTitle data-testid="order.summary.payment_data_card.title" variant="tileTitle">
                {getPaymentTitle()}
              </StyledTitle>
            </StyledTextGrid>
          </TopGrid>
          {selectedPaymentMethod === PaymentMethod.SEPA && (
            <BottomGrid>
              <Section>
                <Typography variant="tileText">
                  {t('order.summary.specified_data.payment_data_card.bank_owner')}
                </Typography>
                <Typography variant="pageTitle">{`${givenname} ${surname}`}</Typography>
              </Section>
              <Section>
                <Typography variant="tileText">
                  {t('order.summary.specified_data.payment_data_card.bank_label')}
                </Typography>
                <Typography variant="pageTitle">{bankName}</Typography>
              </Section>
              <Section>
                <Typography variant="tileText">
                  {t('order.summary.specified_data.payment_data_card.bic_label')}
                </Typography>
                <Typography variant="pageTitle">{bankBic}</Typography>
              </Section>
              <Section>
                <Typography variant="tileText">
                  {t('order.summary.specified_data.payment_data_card.iban_label')}
                </Typography>
                <Typography variant="pageTitle">{bankIban}</Typography>
              </Section>
            </BottomGrid>
          )}
        </StyledCardContent>
      </StyledCard>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._40,
}))

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))

const StyledPaymentIcon = styled(PaymentIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._50,
  },
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const BottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.layout.spacing._20,
    marginLeft: theme.layout.spacing._80,
  },
}))

const Section = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.layout.spacing._20,
}))
