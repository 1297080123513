import React, { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card as MuiCard,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { useGetContainerPrices } from '../../../hooks'
import { setHasApiError, setResponseStatus, setStatusText } from '../../../store/api.slice'
import { getCompanyDeliveryAddress } from '../../../store/inquiry.slice'
import {
  clearSelectedContainer,
  getDestructionOrderType,
  getDestructionProtection,
  getDestructionSubject,
  setContainerPrices,
  setSelectedContainer,
} from '../../../store/order.slice'
import {
  ContainerPriceCode,
  ContainerType,
  DestructionOrderType,
  DestructionProtection,
  DestructionSubject,
  IContainerPricesResponse,
} from '../../../types'
import { currencyNumber } from '../../../utils'

interface IProps {
  type: ContainerType
  onSelect: (isSelected: boolean, amount: number, containerPrices?: IContainerPricesResponse[]) => void
  isSelected: boolean
  testId: string
  suggestedAmount: number
  onApiError: () => void
}

export const ContainerCard = ({ type, onSelect, isSelected, testId, suggestedAmount, onApiError }: IProps) => {
  const [containerAmount, setContainerAmount] = useState(suggestedAmount)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionOrderType = useSelector(getDestructionOrderType)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionProtection = useSelector(getDestructionProtection)
  const { zip } = useSelector(getCompanyDeliveryAddress)

  const { data: containerPrices, isLoading: isFetching } = useGetContainerPrices({
    params: {
      containerType: type,
      orderType: destructionOrderType ?? DestructionOrderType.PERMANENT,
      postCode: zip,
      protectionClass: destructionProtection ?? DestructionProtection.PROTECTION_3_4,
      subject: destructionSubject ?? DestructionSubject.FLOPPY_ID_MAGNETIC,
    },
    options: {
      onError: (error) => {
        dispatch(setHasApiError(true))
        dispatch(setResponseStatus(400))
        dispatch(setStatusText(error?.message ?? ''))
        onApiError()
      },
      onSuccess: (data) => {
        dispatch(setHasApiError(false))
        dispatch(setResponseStatus(200))
        dispatch(setContainerPrices(data))
      },
    },
  })

  const onChangeContainerAmount = (operator: 'DECREASE' | 'INCREASE') => {
    dispatch(clearSelectedContainer())
    dispatch(setSelectedContainer({ containerType: type, amount: containerAmount }))
    if (operator === 'DECREASE' && containerAmount > 1) {
      dispatch(clearSelectedContainer())
      dispatch(setSelectedContainer({ containerType: type, amount: containerAmount - 1 }))
      setContainerAmount((prev) => prev - 1)
    }
    if (operator === 'INCREASE' && containerAmount < 4) {
      dispatch(clearSelectedContainer())
      dispatch(setSelectedContainer({ containerType: type, amount: containerAmount + 1 }))
      setContainerAmount((prev) => prev + 1)
    }
    containerPrices && dispatch(setContainerPrices(containerPrices))
  }

  const handleChange = (event: any) => {
    event.preventDefault()
    onSelect(!isSelected, containerAmount, containerPrices)
  }

  const getCardTitle = () => {
    switch (type) {
      case ContainerType.DC_70:
        return t('order.meta_information.type_of_container.container_card.dc_70.title')

      case ContainerType.DC_240:
        return t('order.meta_information.type_of_container.container_card.dc_240.title')

      case ContainerType.DC_350:
        return t('order.meta_information.type_of_container.container_card.dc_350.title')
    }
  }

  const getContainerInfoItems = () => {
    switch (type) {
      case ContainerType.DC_70:
        return [
          t('order.meta_information.type_of_container.container_card.dc_70.dimensions'),
          t('order.meta_information.type_of_container.container_card.dc_70.liters'),
          t('order.meta_information.type_of_container.container_card.dc_70.weight'),
        ]

      case ContainerType.DC_240:
        return [
          t('order.meta_information.type_of_container.container_card.dc_240.dimensions'),
          t('order.meta_information.type_of_container.container_card.dc_240.liters'),
          t('order.meta_information.type_of_container.container_card.dc_240.weight'),
        ]

      case ContainerType.DC_350:
        return [
          t('order.meta_information.type_of_container.container_card.dc_350.dimensions'),
          t('order.meta_information.type_of_container.container_card.dc_350.liters'),
          t('order.meta_information.type_of_container.container_card.dc_350.weight'),
        ]
    }
  }

  const getDescription = () => {
    if (destructionSubject === DestructionSubject.FILES_PAPER || destructionSubject === DestructionSubject.HDD) {
      return (
        <StyledText data-testid="order.meta_information.container_card.description" variant="tileText">
          {t('order.meta_information.type_of_container.container_card.count_description', {
            containerCount: suggestedAmount,
          })}
        </StyledText>
      )
    }
  }

  const getPriceText = () => {
    if (destructionOrderType === DestructionOrderType.PERMANENT) {
      return currencyNumber(containerPrices?.find((d) => d.code === ContainerPriceCode.EXCHANGE_PRICE)?.price)
    } else {
      return currencyNumber(containerPrices?.find((d) => d.code === ContainerPriceCode.PICK_UP_PRICE)?.price)
    }
  }

  const amountStepper = () => (
    <StepperGrid>
      <StepperButtonGrid
        data-testid="order.meta_information.container_card.decrease"
        onClick={() => onChangeContainerAmount('DECREASE')}>
        <MinusIcon active={containerAmount > 1} />
      </StepperButtonGrid>
      <StepperTextGrid data-testid="order.meta_information.container_card.amount">{containerAmount}</StepperTextGrid>
      <StepperButtonGrid
        data-testid="order.meta_information.container_card.increase"
        onClick={() => onChangeContainerAmount('INCREASE')}>
        <PlusIcon active={containerAmount < 4} />
      </StepperButtonGrid>
    </StepperGrid>
  )

  const card = (
    <StyledCardContent>
      <TopGrid>
        <StyledFormGroup>
          <FormControlLabel
            onChange={handleChange}
            control={
              <StyledCheckbox
                data-testid={testId}
                checked={isSelected}
                disableRipple={true}
                icon={<CircleOutlinedIcon />}
                checkedIcon={<CheckCircleRoundedIcon />}
              />
            }
            label={
              <Typography data-testid="order.meta_information.container_card.title" variant="pageTitle">
                {getCardTitle()}
              </Typography>
            }
          />
        </StyledFormGroup>
      </TopGrid>
      <TextGrid>{getDescription()}</TextGrid>
      <StyledList>
        {getContainerInfoItems().map((infoItem) => (
          <ListItem key={infoItem} sx={{ p: 0 }}>
            <ListItemText primary={`• ${infoItem}`} />
          </ListItem>
        ))}
      </StyledList>
      {amountStepper()}
      <PriceGrid>
        <Typography variant="tileText">
          {t('order.meta_information.type_of_container.container_card.price_per_unit_label')}
        </Typography>
        {isFetching ? (
          <CircularProgress size={16} />
        ) : (
          <Typography data-testid="order.meta_information.container_card.container_price" variant="tileTitle">
            {getPriceText()}
          </Typography>
        )}
      </PriceGrid>
    </StyledCardContent>
  )

  return (
    <CardGrid>
      <StyledCard isSelected={isSelected} variant="outlined">
        {card}
      </StyledCard>
    </CardGrid>
  )
}

const CardGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('desktop')]: {
    paddingTop: theme.layout.spacing._30,
    paddingRight: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('desktop')]: {
    paddingTop: theme.layout.spacing._10,
    paddingRight: theme.layout.spacing._10,
  },
}))

const StyledCard = styled(MuiCard, { shouldForwardProp: (prop) => prop !== 'isSelected' })<Partial<IProps>>(
  ({ theme, isSelected }) => ({
    borderWidth: theme.layout.borderWidth._2,
    borderColor: isSelected ? theme.color.tertiary.green : theme.color.secondary.softGray,
    [theme.breakpoints.up('laptop')]: {
      width: theme.layout.size._350,
    },
    [theme.breakpoints.down('laptop')]: {
      maxWidth: theme.layout.size._350,
    },
  }),
)

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.layout.spacing._30,
  '&:last-child': {
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    padding: theme.layout.spacing._20,
    '&:last-child': {
      paddingBottom: theme.layout.spacing._20,
    },
  },
}))

const TopGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
}))

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
  display: 'flex',
  flex: 0.8,
  [theme.breakpoints.up('laptop')]: {
    paddingBottom: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('laptop')]: {
    paddingBottom: theme.layout.spacing._10,
  },
}))

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  marginRight: theme.layout.spacing._10,
  color: theme.color.secondary.softGray,
  [theme.breakpoints.down('laptop')]: {},
  [theme.breakpoints.down('mobile')]: {
    marginRight: theme.layout.spacing._20,
  },
}))

const TextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledList = styled(List)(({ theme }) => ({
  listStyleType: 'disc',
  listStylePosition: 'inside',
  paddingLeft: theme.layout.spacing._20,
  paddingTop: 0,
  paddingBottom: theme.layout.spacing._20,
}))

const StyledText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const StepperGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.layout.spacing._40,
  height: theme.layout.size._60,
  justifyContent: 'center',
  [theme.breakpoints.up('laptop')]: {},
  [theme.breakpoints.down('laptop')]: {},
}))

const StepperButtonGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: `2px solid ${theme.color.secondary.lightGray}`,
  width: theme.layout.size._60,
  height: theme.layout.size._60,
  ':hover': {
    cursor: 'pointer',
  },
}))

const StepperTextGrid = styled(Grid)(() => ({
  displa: 'flex',
  flex: 1,
  alignSelf: 'center',
  textAlign: 'center',
}))

const MinusIcon = styled(RemoveIcon, { shouldForwardProp: (prop) => prop !== 'active' })<{ active: boolean }>(
  ({ theme, active }) => ({
    color: active ? theme.color.primary.blue : theme.color.secondary.lightGray,
    height: theme.layout.size._30,
    width: theme.layout.size._30,
  }),
)

const PlusIcon = styled(AddIcon, { shouldForwardProp: (prop) => prop !== 'active' })<{ active: boolean }>(
  ({ theme, active }) => ({
    color: active ? theme.color.primary.blue : theme.color.secondary.lightGray,
    height: theme.layout.size._30,
    width: theme.layout.size._30,
  }),
)

const PriceGrid = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
}))
