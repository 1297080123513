import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  SUBJECT_CD_DVD_ICON,
  SUBJECT_FILES_PAPER_ICON,
  SUBJECT_FLOPPY_ID_MAGNETIC_ICON,
  SUBJECT_HDD_ICON,
  SUBJECT_MEMORY_CHIP_SEMICONDUCTOR_ICON,
} from '../../../assets'
import {
  clearDestructionProtection,
  clearDestructionSubject,
  getDestructionProtection,
  getDestructionSubject,
  getDestructionType,
  setDestructionSubject,
  setDestructionType,
} from '../../../store/order.slice'
import { DestructionProtection, DestructionSubject, DestructionType } from '../../../types'
import { Card } from './Card'

interface IProps {
  formik: FormikProps<{ destructionAmount: string }>
}

export const SubjectOfDestruction = ({ formik }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionType = useSelector(getDestructionType)
  const destructionSubject = useSelector(getDestructionSubject)
  const destructionProtection = useSelector(getDestructionProtection)

  /**
   * Clears the selected protection class, if the selected subject doesn't support the currently choosen protection class
   */
  const clearProtectionIfUnsupported = (selectedSubject: DestructionSubject) => {
    if (
      selectedSubject === DestructionSubject.CD_DVD ||
      selectedSubject === DestructionSubject.FLOPPY_ID_MAGNETIC ||
      selectedSubject === DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR
    ) {
      if (destructionProtection === DestructionProtection.PROTECTION_2_5) dispatch(clearDestructionProtection())
      if (destructionProtection === DestructionProtection.PROTECTION_3_5) dispatch(clearDestructionProtection())
    }
  }

  const handleSubjectSelection = (selectedSubject: DestructionSubject, isSelected: boolean) => {
    dispatch(clearDestructionSubject())
    if (isSelected && destructionSubject !== selectedSubject) {
      dispatch(setDestructionSubject(selectedSubject))
      if (selectedSubject !== DestructionSubject.FILES_PAPER) {
        dispatch(setDestructionType(DestructionType.STATIONARY_DESTRUCTION))
        clearProtectionIfUnsupported(selectedSubject)
        formik.values.destructionAmount = ''
      }
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">
        {t('order.order_informations.subject_of_destruction.title')}
      </Typography>
      {_.isUndefined(destructionType) && (
        <CardGrid>
          <Card
            icon={SUBJECT_FILES_PAPER_ICON}
            testId="subject_of_destruction.files_paper_card"
            title={t('order.order_informations.subject_of_destruction.files_paper_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.FILES_PAPER, selected)}
            isSelected={destructionSubject === DestructionSubject.FILES_PAPER}
          />
          <Card
            icon={SUBJECT_HDD_ICON}
            testId="subject_of_destruction.hdd_card"
            title={t('order.order_informations.subject_of_destruction.hhd_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.HDD, selected)}
            isSelected={destructionSubject === DestructionSubject.HDD}
          />
          <Card
            icon={SUBJECT_CD_DVD_ICON}
            testId="subject_of_destruction.cd_dvd_card"
            title={t('order.order_informations.subject_of_destruction.cd_dvd_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.CD_DVD, selected)}
            isSelected={destructionSubject === DestructionSubject.CD_DVD}
          />
          <Card
            icon={SUBJECT_FLOPPY_ID_MAGNETIC_ICON}
            testId="subject_of_destruction.floppy_id_magnetic_card"
            title={t('order.order_informations.subject_of_destruction.floppy_id_magnetic_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.FLOPPY_ID_MAGNETIC, selected)}
            isSelected={destructionSubject === DestructionSubject.FLOPPY_ID_MAGNETIC}
          />
          <Card
            icon={SUBJECT_MEMORY_CHIP_SEMICONDUCTOR_ICON}
            testId="subject_of_destruction.memory_chip_semiconductor_card"
            title={t('order.order_informations.subject_of_destruction.memory_chip_semiconductor_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR, selected)}
            isSelected={destructionSubject === DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR}
          />
        </CardGrid>
      )}
      {destructionType === DestructionType.MOBILE_DESTRUCTION && (
        <Card
          icon={SUBJECT_FILES_PAPER_ICON}
          testId="subject_of_destruction.files_paper_card"
          title={t('order.order_informations.subject_of_destruction.files_paper_title')}
          onSelect={(selected) => handleSubjectSelection(DestructionSubject.FILES_PAPER, selected)}
          isSelected={destructionSubject === DestructionSubject.FILES_PAPER}
        />
      )}
      {destructionType === DestructionType.STATIONARY_DESTRUCTION && (
        <CardGrid>
          <Card
            icon={SUBJECT_FILES_PAPER_ICON}
            testId="subject_of_destruction.files_paper_card"
            title={t('order.order_informations.subject_of_destruction.files_paper_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.FILES_PAPER, selected)}
            isSelected={destructionSubject === DestructionSubject.FILES_PAPER}
          />
          <Card
            icon={SUBJECT_HDD_ICON}
            testId="subject_of_destruction.hdd_card"
            title={t('order.order_informations.subject_of_destruction.hhd_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.HDD, selected)}
            isSelected={destructionSubject === DestructionSubject.HDD}
          />
          <Card
            icon={SUBJECT_CD_DVD_ICON}
            testId="subject_of_destruction.cd_dvd_card"
            title={t('order.order_informations.subject_of_destruction.cd_dvd_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.CD_DVD, selected)}
            isSelected={destructionSubject === DestructionSubject.CD_DVD}
          />
          <Card
            icon={SUBJECT_FLOPPY_ID_MAGNETIC_ICON}
            testId="subject_of_destruction.floppy_id_magnetic_card"
            title={t('order.order_informations.subject_of_destruction.floppy_id_magnetic_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.FLOPPY_ID_MAGNETIC, selected)}
            isSelected={destructionSubject === DestructionSubject.FLOPPY_ID_MAGNETIC}
          />
          <Card
            icon={SUBJECT_MEMORY_CHIP_SEMICONDUCTOR_ICON}
            testId="subject_of_destruction.memory_chip_semiconductor_card"
            title={t('order.order_informations.subject_of_destruction.memory_chip_semiconductor_title')}
            onSelect={(selected) => handleSubjectSelection(DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR, selected)}
            isSelected={destructionSubject === DestructionSubject.MEMORY_CHIP_SEMICONDUCTOR}
          />
        </CardGrid>
      )}
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
}))
