import React from 'react'

import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { getContactData } from '../../../store/inquiry.slice'
import {
  getInvoiceIsDeliveryAddress,
  getIsContactPersonOnSite,
  getSelectedPaymentMethod,
  setContactPersonDataEmail,
  setContactPersonDataGivenname,
  setContactPersonDataPhone,
  setContactPersonDataSurname,
  setIsContactPersonOnSite,
} from '../../../store/order.slice'
import { PaymentMethod } from '../../../types'
import { IFormikValues } from '../pages/MetaInformation'

interface IProps {
  formik: FormikProps<IFormikValues>
}

export const ContactPersonSection = ({ formik }: IProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { email, givenname, surname, phone } = useSelector(getContactData)
  const invoiceIsDeliveryAddress = useSelector(getInvoiceIsDeliveryAddress)
  const isContactPersonOnSite = useSelector(getIsContactPersonOnSite)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)

  const handleInvoiceIsDeliveryAddress = async (_e: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    if (checked) {
      dispatch(setContactPersonDataGivenname(givenname))
      dispatch(setContactPersonDataSurname(surname))
      dispatch(setContactPersonDataEmail(email))
      dispatch(setContactPersonDataPhone(phone))
      delete formik.errors.contactPerson?.givenname
      delete formik.errors.contactPerson?.surname
      delete formik.errors.contactPerson?.email
      delete formik.errors.contactPerson?.phone
    } else {
      await formik.validateForm()
      if (selectedPaymentMethod === PaymentMethod.INVOICE) {
        delete formik.errors.bank?.givenname
        delete formik.errors.bank?.surname
        delete formik.errors.bank?.street
        delete formik.errors.bank?.streetNumber
        delete formik.errors.bank?.zip
        delete formik.errors.bank?.city
        delete formik.errors.bank?.bankName
        delete formik.errors.bank?.bankBic
        delete formik.errors.bank?.bankIban
      }
      if (invoiceIsDeliveryAddress) {
        delete formik.errors.invoice?.name
        delete formik.errors.invoice?.street
        delete formik.errors.invoice?.streetNumber
        delete formik.errors.invoice?.zip
        delete formik.errors.invoice?.city
        delete formik.errors.invoice?.email
      }
    }
    dispatch(setIsContactPersonOnSite(checked))
  }

  return (
    <SectionGrid>
      <StyledTitle variant="underlinedTileTitle">{t('order.meta_information.contact_data_title')}</StyledTitle>
      <InputGrid>
        <CheckboxGrid>
          <FormControlLabel
            data-testid="order.meta_information.contact_data_checkbox"
            onChange={handleInvoiceIsDeliveryAddress}
            control={<Checkbox checked={isContactPersonOnSite} />}
            label={<Typography>{t('order.meta_information.contact_data_checkbox_label')}</Typography>}
          />
        </CheckboxGrid>
        {!isContactPersonOnSite && (
          <React.Fragment>
            <HorizontalInputGrid>
              <GivennameTextField
                inputProps={{ 'data-testid': 'order.meta_information.contact_data_inputs.givenname' }}
                placeholder={t('order.meta_information.contact_data_inputs.givenname').toUpperCase()}
                id="contactPerson.givenname"
                name="contactPerson.givenname"
                value={formik.values.contactPerson.givenname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactPerson?.givenname && Boolean(formik.errors.contactPerson?.givenname)}
                helperText={(formik.touched.contactPerson?.givenname && formik.errors.contactPerson?.givenname) ?? ' '}
                type="text"
                margin="dense"
              />
              <SurnameTextField
                inputProps={{ 'data-testid': 'order.meta_information.contact_data_inputs.surname' }}
                placeholder={t('order.meta_information.contact_data_inputs.surname').toUpperCase()}
                id="contactPerson.surname"
                name="contactPerson.surname"
                value={formik.values.contactPerson.surname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.contactPerson?.surname && Boolean(formik.errors.contactPerson?.surname)}
                helperText={(formik.touched.contactPerson?.surname && formik.errors.contactPerson?.surname) ?? ' '}
                type="text"
                margin="dense"
              />
            </HorizontalInputGrid>
            <StyledTextField
              inputProps={{ 'data-testid': 'order.meta_information.contact_data_inputs.email' }}
              placeholder={t('order.meta_information.contact_data_inputs.email').toUpperCase()}
              id="contactPerson.email"
              name="contactPerson.email"
              value={formik.values.contactPerson.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactPerson?.email && Boolean(formik.errors.contactPerson?.email)}
              helperText={(formik.touched.contactPerson?.email && formik.errors.contactPerson?.email) ?? ' '}
              type="email"
              margin="dense"
            />
            <StyledTextField
              inputProps={{ 'data-testid': 'order.meta_information.contact_data_inputs.phone' }}
              placeholder={t('order.meta_information.contact_data_inputs.phone').toUpperCase()}
              id="contactPerson.phone"
              name="contactPerson.phone"
              value={formik.values.contactPerson.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.contactPerson?.phone && Boolean(formik.errors.contactPerson?.phone)}
              helperText={(formik.touched.contactPerson?.phone && formik.errors.contactPerson?.phone) ?? ' '}
              type="tel"
              margin="dense"
            />
          </React.Fragment>
        )}
      </InputGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._60,
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.layout.spacing._20,
}))

const CheckboxGrid = styled(Grid)(({ theme }) => ({
  height: theme.layout.size._50,
}))

const HorizontalInputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
}))

const InputGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const StyledTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
}))

const GivennameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginRight: theme.layout.spacing._10,
}))

const SurnameTextField = styled(TextField)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  marginLeft: theme.layout.spacing._10,
}))
