import dayjs from 'dayjs'

export const DEFAULT_PICK_UP_DATES = [
  dayjs().weekday(7).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(8).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(9).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(10).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(11).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(14).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(15).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(16).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(17).format('dddd, DD.MM.YYYY'),
  dayjs().weekday(18).format('dddd, DD.MM.YYYY'),
]

export const START_TIMES = ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00']
export const END_TIMES = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00']
