import InfoIcon from '@mui/icons-material/Info'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { CardContent, Grid, Card as MuiCard, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getCompanyDeliveryAddress, getContactData, getFurtherInformation } from '../../../store/inquiry.slice'

interface IProps {
  type: 'LOCATION' | 'CONTACT' | 'INFO'
  testId: string
}

export const DataCard = ({ type, testId }: IProps) => {
  const { t } = useTranslation()
  const { name, street, number, zip, city } = useSelector(getCompanyDeliveryAddress)
  const { givenname, surname, email, phone } = useSelector(getContactData)
  const furtherInformation = useSelector(getFurtherInformation)

  const renderTopGrid = () => {
    return (
      <TopGrid>
        {type === 'LOCATION' && <StyledLocationIcon />}
        {type === 'CONTACT' && <StyledContactIcon />}
        {type === 'INFO' && <StyledInfoIcon />}
        <StyledTextGrid>
          {type === 'LOCATION' && (
            <Typography variant="tileText">{t('inquiry.summary.label_delivery_address_company')}</Typography>
          )}
          {type === 'CONTACT' && <Typography variant="tileText">{t('inquiry.summary.label_contact_data')}</Typography>}
          {type === 'INFO' && <Typography variant="tileText">{t('inquiry.summary.label_further_details')}</Typography>}
          {type === 'LOCATION' && (
            <StyledTitle data-testid="inquiry.summary.company_name" variant="tileTitle">
              {name}
            </StyledTitle>
          )}
          {type === 'CONTACT' && (
            <StyledTitle
              data-testid="inquiry.summary.contact_name"
              variant="tileTitle">{`${givenname} ${surname}`}</StyledTitle>
          )}
          {type === 'INFO' && (
            <StyledTitle variant="tileTitle">{t('inquiry.summary.label_further_information')}</StyledTitle>
          )}
        </StyledTextGrid>
      </TopGrid>
    )
  }

  const renderBottomGrid = () => {
    return (
      <BottomGrid>
        <StyledTextGrid>
          {type === 'LOCATION' && <Typography variant="tileText">{t('inquiry.summary.label_address')}</Typography>}
          {type === 'CONTACT' && <Typography variant="tileText">{t('inquiry.summary.label_email')}</Typography>}
          {type === 'LOCATION' && (
            <CompanyStreetNumberGrid>
              <Typography data-testid="inquiry.summary.company_street" variant="pageTitle">
                {street}
              </Typography>
              <StyledStreetNumberTitle data-testid="inquiry.summary.company_street_number" variant="pageTitle">
                {number}
              </StyledStreetNumberTitle>
            </CompanyStreetNumberGrid>
          )}
          {type === 'CONTACT' && (
            <StyledTitle data-testid="inquiry.summary.contact_email" variant="pageTitle">
              {email}
            </StyledTitle>
          )}
          {type === 'INFO' && (
            <StyledTitle data-testid="inquiry.summary.further_info" variant="pageTitle">
              {furtherInformation}
            </StyledTitle>
          )}
          {type === 'LOCATION' && (
            <StyledTitle
              data-testid="inquiry.summary.company_zip_city"
              variant="pageTitle">{`${zip}, ${city}`}</StyledTitle>
          )}
        </StyledTextGrid>
        {type === 'CONTACT' && <Separator />}
        {type === 'CONTACT' && (
          <StyledPhoneTextGrid>
            <Typography variant="tileText">{t('inquiry.summary.label_phone')}</Typography>
            <StyledTitle data-testid="inquiry.summary.contact_phone" variant="pageTitle">
              {phone}
            </StyledTitle>
          </StyledPhoneTextGrid>
        )}
      </BottomGrid>
    )
  }

  return (
    <Grid>
      <StyledCard variant="outlined">
        <StyledCardContent data-testid={testId}>
          {renderTopGrid()}
          {renderBottomGrid()}
        </StyledCardContent>
      </StyledCard>
    </Grid>
  )
}

const StyledCard = styled(MuiCard)(({ theme }) => ({
  borderWidth: theme.layout.borderWidth._2,
  borderColor: theme.color.secondary.softGray,
  [theme.breakpoints.down('mobile')]: {
    paddingRight: theme.layout.spacing._30,
    paddingLeft: theme.layout.spacing._30,
    paddingBottom: theme.layout.spacing._30,
  },
}))

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    padding: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('mobile')]: {
    flexDirection: 'column',
  },
}))

const TopGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: theme.layout.spacing._20,
  },
}))

const StyledLocationIcon = styled(LocationOnIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._30,
  },
}))

const StyledContactIcon = styled(SupervisedUserCircleIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._50,
  },
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.color.primary.blue,
  width: theme.layout.size._50,
  height: theme.layout.size._50,
  [theme.breakpoints.down('mobile')]: {
    alignSelf: 'center',
    marginBottom: theme.layout.spacing._50,
  },
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('mobile')]: {
    marginBottom: theme.layout.spacing._20,
  },
}))

const StyledStreetNumberTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.layout.spacing._5,
}))

const StyledTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const CompanyStreetNumberGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('mobile')]: {
    flex: 1,
    alignItems: 'flex-start',
  },
}))

const StyledPhoneTextGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('mobile')]: {
    marginLeft: theme.layout.spacing._30,
  },
}))

const BottomGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  [theme.breakpoints.up('laptop')]: {
    marginBottom: theme.layout.spacing._40,
  },
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('mobile')]: {
    marginTop: theme.layout.spacing._20,
    marginLeft: theme.layout.spacing._50,
  },
}))

const Separator = styled('div')(({ theme }) => ({
  display: 'flex',
  marginLeft: theme.layout.spacing._180,
  marginRight: theme.layout.spacing._30,
  width: theme.layout.borderWidth._2,
  backgroundColor: theme.color.secondary.softGray,
  flexDirection: 'column',
  [theme.breakpoints.down('laptop')]: {
    display: 'none',
  },
}))
