import { Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearDestructionType,
  getDestructionType,
  setDestructionSubject,
  setDestructionType,
} from '../../../store/order.slice'
import { DestructionSubject, DestructionType } from '../../../types'
import { Card } from './Card'

export const TypeOfDestruction = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const destructionType = useSelector(getDestructionType)

  const handleTypeSelection = (selectedType: DestructionType, isSelected: boolean) => {
    dispatch(clearDestructionType())
    if (isSelected && destructionType !== selectedType) {
      dispatch(setDestructionType(selectedType))
      if (selectedType === DestructionType.MOBILE_DESTRUCTION) {
        dispatch(setDestructionSubject(DestructionSubject.FILES_PAPER))
      }
    }
  }

  return (
    <SectionGrid>
      <Typography variant="underlinedTileTitle">{t('order.order_informations.type_of_destruction.title')}</Typography>
      <CardGrid>
        <Card
          testId="type_of_destruction.mobile_card"
          title={t('order.order_informations.type_of_destruction.mobile_title')}
          description={t('order.order_informations.type_of_destruction.mobile_description')}
          onSelect={(selected) => handleTypeSelection(DestructionType.MOBILE_DESTRUCTION, selected)}
          isSelected={destructionType === DestructionType.MOBILE_DESTRUCTION}
        />
        <Card
          testId="type_of_destruction.stationary_card"
          title={t('order.order_informations.type_of_destruction.stationary_title')}
          description={t('order.order_informations.type_of_destruction.stationary_description')}
          onSelect={(selected) => handleTypeSelection(DestructionType.STATIONARY_DESTRUCTION, selected)}
          isSelected={destructionType === DestructionType.STATIONARY_DESTRUCTION}
        />
      </CardGrid>
    </SectionGrid>
  )
}

const SectionGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  marginBottom: theme.layout.spacing._80,
}))

const CardGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  [theme.breakpoints.down('laptop')]: {
    flexDirection: 'column',
  },
}))
